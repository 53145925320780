import { useElementVisibility } from '@vueuse/core';
import { nextTick, watch } from 'vue';

export function useLazyLoading(markerRef, activeTabIdRef, isLoadingRef, loadMore) {
  const isVisible = useElementVisibility(markerRef);

  const loadMoreIfNeeded = () => {
    if (isVisible.value) {
      loadMore();
    }
  };

  // load more if needed on scroll to bottom
  watch(isVisible, () => loadMoreIfNeeded());

  // load more if needed on active tab change
  watch(activeTabIdRef, async () => {
    await nextTick();
    loadMoreIfNeeded();
  });

  // load more if needed on loading finished
  watch(isLoadingRef, async (_, wasLoading) => {
    if (wasLoading) {
      await nextTick();
      loadMoreIfNeeded();
    }
  });
}
