import Observable from './observable';
import TaskCalendarState from './state';
import TaskCalendarData from './modules/data';
import TaskCalendarUrl from './modules/url';
import TaskCalendarHeader from './modules/header';
import TaskCalendarDates from './modules/dates';
import TaskCalendarEvents from './modules/events';
import TaskCalendarSalespeople from './modules/salespeople';
import TaskCalendarEventsOverdue from './modules/overdue';

const SELECTOR_HEADER = '.rp-task-calendar-header';
const SELECTOR_DATES = '.rp-task-calendar-dates';
const SELECTOR_EVENTS_CONTAINER = '.rp-task-calendar-events-container';
const SELECTOR_SALESPEOPLE = '.rp-task-calendar-salespeople';
const SELECTOR_EVENTS_OVERDUE = '.rp-task-calendar-events-overdue';

export default class TaskCalendar {
  constructor($element) {
    this.observable = new Observable();
    this.state = new TaskCalendarState(this.observable);

    new TaskCalendarData(this.observable, this.state);
    new TaskCalendarUrl(this.observable, this.state);

    new TaskCalendarHeader($element.find(SELECTOR_HEADER), this.observable, this.state);
    new TaskCalendarDates($element.find(SELECTOR_DATES), this.observable, this.state);

    new TaskCalendarEvents($element.find(SELECTOR_EVENTS_CONTAINER), this.observable, this.state);
    new TaskCalendarSalespeople($element.find(SELECTOR_SALESPEOPLE), this.observable, this.state);
    new TaskCalendarEventsOverdue($element.find(SELECTOR_EVENTS_OVERDUE), this.observable, this.state);

    this.observable.trigger('ready', this.state);
  }
}
