import Fetch from 'core/fetch';
import Log from 'core/log';
import Numbers from 'core/numbers';
import Router from 'core/router';
import $ from 'jquery';
import { formatAttrSelector, updateUrl } from 'utils/functions';
import FormUpdate from '../../components/formUpdate';

const ATTR_KEY = 'data-attribute-key';

class AreaEquivalentFormUpdate extends FormUpdate {
  constructor(unitForm) {
    super(unitForm.$areaEquivalent, unitForm.getEquivalentAreaCalculateUrl());

    this.unitForm = unitForm;
  }

  handleUpdate(values) {
    super.handleUpdate(values);

    this.unitForm.removeValueMismatchWarning(this.unitForm.$areaEquivalentWarning);
  }
}

class AreaEquivalentWarningFormUpdate extends FormUpdate {
  constructor(unitForm) {
    super(unitForm.$areaEquivalent, unitForm.getEquivalentAreaCalculateUrl());

    this.unitForm = unitForm;
  }

  handleUpdate(values) {
    if (Numbers.parse(values['area-equivalent']) !== this.unitForm.areaEquivalent) {
      this.unitForm.createValueMismatchWarning(this.unitForm.$areaEquivalentWarning,
        () => this.unitForm.areaEquivalentUpdate.handle());
    }
  }
}

class UnitForm {
  constructor($unitForm) {
    this.$form = $unitForm;

    this.floorId = this.$form.attr('data-floor-id');
    this.unitId = this.$form.attr('data-unit-id');
    this.checkCalculatedValues = this.$form.attr('data-check-calculated-value') === 'true';
    this.effectiveVatRate = Numbers.parse(this.$form.attr('data-effective-vat-rate'));

    this.$areaNetCtrAttribute = this.getAttributeByName('data-area-net-ctr');

    this.$unitType = $unitForm.find('#type');

    this.$priceOriginal = $unitForm.find('#price-original');
    this.$priceOriginalVat = $unitForm.find('#price-original-vat');
    this.$priceOriginalVatRate = $unitForm.find('#price-original-vat-rate');
    this.$calculatePriceOriginal = $unitForm.find('#calculate-price-original');
    this.$calculatePriceOriginalVat = $unitForm.find('#calculate-price-original-vat');
    this.$calculatePriceOriginalVatRate = $unitForm.find('#calculate-price-original-vat-rate');
    this.$fixPriceOriginalVatRate = $unitForm.find('#fix-price-original-vat-rate');

    this.$priceOriginalSqm = $unitForm.find('#price-original-sqm');
    this.$priceOriginalSqmWarning = $unitForm.find('#price-original-sqm-warning');
    this.$priceOriginalSqmVat = $unitForm.find('#price-original-sqm-vat');
    this.$priceOriginalSqmVatWarning = $unitForm.find('#price-original-sqm-vat-warning');

    this.$price = $unitForm.find('#price');
    this.$priceVat = $unitForm.find('#price-vat');
    this.$priceVatRate = $unitForm.find("#price-vat-rate");
    this.$calculatePrice = $unitForm.find('#calculate-price');
    this.$calculatePriceVat = $unitForm.find('#calculate-price-vat');
    this.$calculatePriceVatRate = $unitForm.find('#calculate-price-vat-rate');
    this.$fixPriceVatRate = $unitForm.find('#fix-price-vat-rate');

    this.$priceDiscount = $unitForm.find('#price-discount');
    this.$priceDiscountVat = $unitForm.find('#price-discount-vat');
    this.$priceDiscountVatRate = $unitForm.find("#price-discount-vat-rate");
    this.$calculatePriceDiscount = $unitForm.find('#calculate-price-discount');
    this.$calculatePriceDiscountVat = $unitForm.find('#calculate-price-discount-vat');
    this.$calculatePriceDiscountVatRate = $unitForm.find('#calculate-price-discount-vat-rate');
    this.$fixPriceDiscountVatRate = $unitForm.find('#fix-price-discount-vat-rate');

    this.$priceDiscounted = $unitForm.find('#price-discounted');
    this.$priceDiscountedVat = $unitForm.find('#price-discounted-vat');

    this.$priceSqm = $unitForm.find('#price-sqm');
    this.$priceSqmWarning = $unitForm.find('#price-sqm-warning');
    this.$priceSqmVat = $unitForm.find('#price-sqm-vat');
    this.$priceSqmVatWarning = $unitForm.find('#price-sqm-vat-warning');
    this.$priceSqmDiscounted = $unitForm.find('#price-sqm-discounted');
    this.$priceSqmDiscountedWarning = $unitForm.find('#price-sqm-discounted-warning');

    this.$priceSqmCtr = $unitForm.find('#price-sqm-ctr');
    this.$priceSqmDiscountedCtr = $unitForm.find('#price-sqm-discounted-ctr');

    this.$dealDiscount = $unitForm.find('#deal-discount');
    this.$dealDiscountVat = $unitForm.find('#deal-discount-vat');

    this.$priceFinal = $unitForm.find('#price-final');
    this.$priceFinalVat = $unitForm.find('#price-final-vat');

    this.$priceFinalSqm = $unitForm.find('#price-final-sqm');
    this.$priceFinalSqmVat = $unitForm.find('#price-final-sqm-vat');

    this.$priceIncrease = $unitForm.find('#price-increase');
    this.$priceIncreaseVat = $unitForm.find('#price-increase-vat');

    this.$priceFinalIncrease = $unitForm.find('#price-final-increase');
    this.$priceFinalIncreaseVat = $unitForm.find('#price-final-increase-vat');

    this.$dealDiscountWarnings = $unitForm.find('.deal-discount-warning').closest('.input-group-text');

    this.$upcMode = $unitForm.find('#upc-mode');
    this.$upcOverview = $unitForm.find('#upc-overview');

    this.$area = $unitForm.find('#area');
    this.$areaEquivalent = $unitForm.find('#area-equivalent');
    this.$areaEquivalentWarning = $unitForm.find('#area-equivalent-warning');

    this.areaEquivalentUpdate = new AreaEquivalentFormUpdate(this);
    this.areaEquivalentWarning = new AreaEquivalentWarningFormUpdate(this);

    this.bindEvents();
    this.init();
  }

  init() {
    this.hideWarnings();
    this.updatePriceDiscounted();
    this.updatePriceFinal();
    this.updatePriceIncreases();
    this.updatePriceFinalSqm();

    this.setupVatRateCalculatorPriceOriginal();
    this.setupVatRateCalculatorPrice();
    this.setupVatRateCalculatorDiscount();

    if (this.checkCalculatedValues) {
      this.areaEquivalentWarning.handle();
      this.checkSqmPrices();
    }

    if (this.$areaNetCtrAttribute.exists()) {
      this.updatePricesSqmNet(false);
    }

    if (this.$upcOverview.exists()) {
      this.updateUpcOverview();
    }
  }

  bindEvents() {
    this.$unitType.on('change', () => this.areaEquivalentUpdate.handle());

    this.$price.add(this.$priceVat)
      .add(this.$priceDiscount).add(this.$priceDiscountVat)
      .on('change', () => {
        this.updatePriceDiscounted(true);
        this.updatePriceFinal(true);
        this.updatePriceIncreases(true);
        this.updateSqmPrices();
        this.updatePriceFinalSqm(true);
        this.showDealDiscountWarnings(true);
      });

    this.$priceOriginal.add(this.$priceOriginalVat)
      .on('change', () => {
        this.updatePriceIncreases(true);
        this.updateSqmPrices();
        this.updatePriceFinalSqm(true);
      });

    this.$area.add(this.$areaEquivalent)
      .on('change', () => {
        this.updateSqmPrices();
        this.updatePriceFinalSqm(true);
      });

    this.$form.find('.update-equivalent-area')
      .on('change', () => {
        this.areaEquivalentUpdate.handle();
      });

    if (this.$areaNetCtrAttribute.exists()) {
      this.$price.add(this.$priceDiscounted).add(this.$areaNetCtrAttribute)
        .on('change', () => this.updatePricesSqmNet());
    }

    if (this.$upcOverview.exists()) {
      this.$upcMode.add(this.$price).on('change', () => this.updateUpcOverview());
    }
  }

  get areaNetCtrAttribute() {
    return Numbers.parse(this.$areaNetCtrAttribute.val());
  }

  get priceOriginal() {
    return Numbers.parse(this.$priceOriginal.val());
  }

  get priceOriginalVat() {
    return Numbers.parse(this.$priceOriginalVat.val());
  }

  get priceOriginalVatRate() {
    return Numbers.parse(this.$priceOriginalVatRate.val());
  }

  get price() {
    return Numbers.parse(this.$price.val());
  }

  get priceVat() {
    return Numbers.parse(this.$priceVat.val());
  }

  get priceVatRate() {
    return Numbers.parse(this.$priceVatRate.val());
  }

  get priceDiscount() {
    return Numbers.parse(this.$priceDiscount.val());
  }

  get priceDiscountVat() {
    return Numbers.parse(this.$priceDiscountVat.val());
  }

  get priceDiscountVatRate() {
    return Numbers.parse(this.$priceDiscountVatRate.val());
  }

  get priceDiscounted() {
    return Numbers.parse(this.$priceDiscounted.val());
  }

  get priceDiscountedVat() {
    return Numbers.parse(this.$priceDiscountedVat.val());
  }

  get priceSqm() {
    return Numbers.parse(this.$priceSqm.val());
  }

  get priceSqmVat() {
    return Numbers.parse(this.$priceSqmVat.val());
  }

  get priceSqmDiscounted() {
    return Numbers.parse(this.$priceSqmDiscounted.val());
  }

  get priceOriginalSqm() {
    return Numbers.parse(this.$priceOriginalSqm.val());
  }

  get priceOriginalSqmVat() {
    return Numbers.parse(this.$priceOriginalSqmVat.val());
  }

  get dealDiscount() {
    return Numbers.parse(this.$dealDiscount.val());
  }

  get dealDiscountVat() {
    return Numbers.parse(this.$dealDiscountVat.val());
  }

  get sqmArea() {
    if (this.$areaEquivalent.val()) {
      return this.areaEquivalent;
    }
    return Numbers.parse(this.$area.val());
  }

  get areaEquivalent() {
    return Numbers.parse(this.$areaEquivalent.val());
  }

  get params() {
    var params = {};

    this.$form.serializeArray().map(function(param) {
      params[param.name] = param.value;
    });

    return params;
  }

  getUnitOrFloorIdParam() {
    const idParam = {};
    if (this.floorId) {
      idParam['floor-id'] = this.floorId;
    } else {
      idParam['product-id'] = this.unitId;
    }

    return idParam;
  }

  getEquivalentAreaCalculateUrl() {
    return updateUrl(Router.get('API_FORM_PRODUCT_EQUIVALENT_AREA_CALCULATE'), this.getUnitOrFloorIdParam());
  }

  hideWarnings() {
    this.$form.find('.hide-warning').each(function() {
      $(this).hidden(true);
    });
    this.showDealDiscountWarnings(false);
  }

  updatePriceDiscounted(triggerChange) {
    this.$priceDiscounted
      .val(Numbers.format(this.price - this.priceDiscount))
      .highlight();

    this.$priceDiscountedVat
      .val(Numbers.format(this.priceVat - this.priceDiscountVat))
      .highlight();

    if (triggerChange) {
      this.$priceDiscounted.trigger('change');
      this.$priceDiscountedVat.trigger('change');
    }
  }

  updatePriceFinal(triggerChange) {
    if (this.price > 0) {
      var priceFinal = this.price - this.priceDiscount - this.dealDiscount;

      this.$priceFinal.val(Numbers.format(priceFinal)).highlight();
    } else {
      this.$priceFinal.val('').highlight();
    }

    if (this.priceVat > 0) {
      var priceFinalVat = this.priceVat - this.priceDiscountVat - this.dealDiscountVat;

      this.$priceFinalVat.val(Numbers.format(priceFinalVat)).highlight();
    } else {
      this.$priceFinalVat.val('').highlight();
    }

    if (triggerChange) {
      this.$priceFinal.trigger('change');
      this.$priceFinalVat.trigger('change');
    }
  }

  updatePriceIncreases(triggerChange) {
    var price = this.price;
    var priceVat = this.priceVat;
    var priceOriginal = this.priceOriginal;
    var priceOriginalVat = this.priceOriginalVat;

    if (price > 0 && priceOriginal > 0) {
      var priceIncrease = price - priceOriginal;
      var priceFinalIncrease = price - priceOriginal - this.priceDiscount - this.dealDiscount;

      var togglePriceIncreaseClass = priceIncrease < 0;
      var togglePriceFinalIncreaseClass = priceFinalIncrease < 0;

      this.$priceIncrease.val(Numbers.format(priceIncrease))
        .toggleClass('bg-danger', togglePriceIncreaseClass)
        .toggleClass('text-white', togglePriceIncreaseClass)
        .highlight();
      this.$priceFinalIncrease.val(Numbers.format(priceFinalIncrease))
        .toggleClass('bg-danger', togglePriceFinalIncreaseClass)
        .toggleClass('text-white', togglePriceFinalIncreaseClass)
        .highlight();
    } else {
      this.$priceIncrease.val('').removeClass('bg-danger', 'text-white').highlight();
      this.$priceFinalIncrease.val('').removeClass('bg-danger', 'text-white').highlight();
    }

    if (priceVat > 0 && priceOriginalVat > 0) {
      var priceIncreaseVat = priceVat - priceOriginalVat;
      var priceFinalIncreaseVat = priceVat - priceOriginalVat - this.priceDiscountVat - this.dealDiscountVat;

      var togglePriceIncreaseVatClass = priceIncreaseVat < 0;
      var togglePriceFinalIncreaseVatClass = priceFinalIncreaseVat < 0;

      this.$priceIncreaseVat.val(Numbers.format(priceIncreaseVat))
        .toggleClass('bg-danger', togglePriceIncreaseVatClass)
        .toggleClass('text-white', togglePriceIncreaseVatClass)
        .highlight();
      this.$priceFinalIncreaseVat.val(Numbers.format(priceFinalIncreaseVat))
        .toggleClass('bg-danger', togglePriceFinalIncreaseVatClass)
        .toggleClass('text-white', togglePriceFinalIncreaseVatClass)
        .highlight();
    } else {
      this.$priceIncreaseVat.val('').removeClass('bg-danger', 'text-white').highlight();
      this.$priceFinalIncreaseVat.val('').removeClass('bg-danger', 'text-white').highlight();
    }

    if (triggerChange) {
      this.$priceIncrease.trigger('change');
      this.$priceIncreaseVat.trigger('change');
      this.$priceFinalIncrease.trigger('change');
      this.$priceFinalIncreaseVat.trigger('change');
    }
  }

  checkSqmPrices() {
    var sqmArea = this.sqmArea;
    if (sqmArea <= 0) {
      return;
    }

    var params = this.params;
    params['new-design'] = true;
    params['unit-id'] = this.unitId;
    params['area-for-calculation'] = Numbers.format(sqmArea);
    params['discounted-price'] = this.$priceDiscounted.val();

    $.post(Router.get('API_GET_SQM_PRICES'), params).done(response => {
      var parsed = JSON.parse(response);
      if (parsed['Result'] !== 'OK') {
        return;
      }

      if (Numbers.parse(parsed['PriceSqm']) !== this.priceSqm) {
        this.createValueMismatchWarning(this.$priceSqmWarning,
          () => this.updateSqmPrices());
      }
      if (Numbers.parse(parsed['PriceSqmVat']) !== this.priceSqmVat) {
        this.createValueMismatchWarning(this.$priceSqmVatWarning,
          () => this.updateSqmPrices());
      }
      if (Numbers.parse(parsed['PriceSqmDiscounted']) !== this.priceSqmDiscounted) {
        this.createValueMismatchWarning(this.$priceSqmDiscountedWarning,
          () => this.updateSqmPrices());
      }
      if (Numbers.parse(parsed['PriceOriginalSqm']) !== this.priceOriginalSqm) {
        this.createValueMismatchWarning(this.$priceOriginalSqmWarning,
          () => this.updateSqmPrices());
      }
      if (Numbers.parse(parsed['PriceOriginalSqmVat']) !== this.priceOriginalSqmVat) {
        this.createValueMismatchWarning(this.$priceOriginalSqmVatWarning,
          () => this.updateSqmPrices());
      }
    });
  }

  updateSqmPrices() {
    var sqmArea = this.sqmArea;
    if (sqmArea <= 0) {
      return;
    }

    var params = this.params;
    params['new-design'] = true;
    params['unit-id'] = this.unitId;
    params['area-for-calculation'] = Numbers.format(sqmArea);
    params['discounted-price'] = this.$priceDiscounted.val();


    $.post(Router.get('API_GET_SQM_PRICES'), params).done(response => {
      var parsed = JSON.parse(response);
      if (parsed['Result'] !== 'OK') {
        return;
      }

      this.$priceSqm.val(parsed['PriceSqm'])
        .trigger('change').highlight();
      this.$priceSqmVat.val(parsed['PriceSqmVat'])
        .trigger('change').highlight();
      this.$priceSqmDiscounted.val(parsed['PriceSqmDiscounted'])
        .trigger('change').highlight();
      this.$priceOriginalSqm.val(parsed['PriceOriginalSqm'])
        .trigger('change').highlight();
      this.$priceOriginalSqmVat.val(parsed['PriceOriginalSqmVat'])
        .trigger('change').highlight();

      this.removeValueMismatchWarning(this.$priceSqmWarning);
      this.removeValueMismatchWarning(this.$priceSqmVatWarning);
      this.removeValueMismatchWarning(this.$priceSqmDiscountedWarning);
      this.removeValueMismatchWarning(this.$priceOriginalSqmWarning);
      this.removeValueMismatchWarning(this.$priceOriginalSqmVatWarning);
    });
  }

  updatePriceFinalSqm(triggerChange) {
    var sqmArea = this.sqmArea;
    var price = this.price;
    var priceVat = this.priceVat;

    if (price > 0 && sqmArea > 0) {
      var priceFinalSqm = (price - this.priceDiscount - this.dealDiscount) / sqmArea;
      this.$priceFinalSqm.val(Numbers.format(priceFinalSqm)).highlight();
    } else {
      this.$priceFinalSqm.val('').highlight();
    }

    if (priceVat > 0 && sqmArea > 0) {
      var priceFinalSqmVat = (priceVat - this.priceDiscountVat - this.dealDiscountVat) / sqmArea;
      this.$priceFinalSqmVat.val(Numbers.format(priceFinalSqmVat)).highlight();
    } else {
      this.$priceFinalSqmVat.val('').highlight();
    }

    if (triggerChange) {
      this.$priceFinalSqm.trigger('change');
      this.$priceFinalSqm.trigger('change');
    }
  }

  calculateVatRate($vatRate, price, priceVat) {
    if (price !== null && priceVat !== null) {
      var vatRate = (priceVat - price) * 100 / price;
      $vatRate.val(Numbers.format(vatRate)).trigger('change').highlight();
    }

    return false;
  }

  calculatePriceOriginal() {
    const priceOriginalOld = this.priceOriginal;
    const priceOriginalVat = this.priceOriginalVat;
    const priceOriginalVatRate = this.priceOriginalVatRate;

    if (priceOriginalVat !== null && priceOriginalVatRate !== null) {
      const priceOriginalNew = Numbers.round((priceOriginalVat * 100) / (priceOriginalVatRate + 100));
      this.$priceOriginal.val(Numbers.format(priceOriginalNew));

      if (priceOriginalOld !== priceOriginalNew) {
        this.$priceOriginal.trigger('change').highlight();
      }
    }

    return false;
  }

  calculatePriceOriginalVat() {
    const priceOriginalVatOld = this.priceOriginalVat;
    const priceOriginal = this.priceOriginal;
    const priceOriginalVatRate = this.priceOriginalVatRate;

    if (priceOriginal !== null && priceOriginalVatRate !== null) {
      const priceOriginalVatNew = Numbers.round((priceOriginal * priceOriginalVatRate) / 100 + priceOriginal);
      this.$priceOriginalVat.val(Numbers.format(priceOriginalVatNew));

      if (priceOriginalVatOld !== priceOriginalVatNew) {
        this.$priceOriginalVat.trigger('change').highlight();
      }
    }

    return false;
  }

  calculatePrice() {
    const priceOld = this.price;
    const priceVat = this.priceVat;
    const priceVatRate = this.priceVatRate;

    if (priceVat !== null && priceVatRate !== null) {
      const priceNew = Numbers.round((priceVat * 100) / (priceVatRate + 100));
      this.$price.val(Numbers.format(priceNew));

      if (priceOld !== priceNew) {
        this.$price.trigger('change').highlight();
      }
    }

    return false;
  }

  calculatePriceVat() {
    const priceVatOld = this.priceVat;
    const price = this.price;
    const priceVatRate = this.priceVatRate;

    if (price !== null && priceVatRate !== null) {
      const priceVatNew = Numbers.round((price * priceVatRate) / 100 + price);
      this.$priceVat.val(Numbers.format(priceVatNew));

      if (priceVatOld !== priceVatNew) {
        this.$priceVat.trigger('change').highlight();
      }
    }

    return false;
  }

  calculatePriceDiscount() {
    const priceDiscountOld = this.priceDiscount;
    const priceDiscountVat = this.priceDiscountVat;
    const priceDiscountVatRate = this.priceDiscountVatRate;

    if (priceDiscountVat !== null && priceDiscountVatRate !== null) {
      const priceDiscountNew = Numbers.round((priceDiscountVat * 100) / (priceDiscountVatRate + 100));
      this.$priceDiscount.val(Numbers.format(priceDiscountNew));

      if (priceDiscountOld !== priceDiscountNew) {
        this.$priceDiscount.trigger('change').highlight();
      }
    }

    return false;
  }

  calculatePriceDiscountVat() {
    const priceDiscountVatOld = this.priceDiscountVat;
    const priceDiscount = this.priceDiscount;
    const priceDiscountVatRate = this.priceDiscountVatRate;

    if (priceDiscount !== null && priceDiscountVatRate !== null) {
      const priceDiscountVatNew = Numbers.round((priceDiscount * priceDiscountVatRate) / 100 + priceDiscount);
      this.$priceDiscountVat.val(Numbers.format(priceDiscountVatNew));

      if (priceDiscountVatOld !== priceDiscountVatNew) {
        this.$priceDiscountVat.trigger('change').highlight();
      }
    }

    return false;
  }

  checkEfficientVatRate($fixVatRate, price, priceVat, vatRate) {
    if (!this.effectiveVatRate) {
      $fixVatRate.hidden(true);
      return;
    }
    const vatRateMismatch = price > 0 && priceVat > 0 && this.effectiveVatRate !== vatRate;
    $fixVatRate.hidden(!vatRateMismatch);
  }

  setupVatRateCalculatorPriceOriginal() {
    this.$calculatePriceOriginalVatRate.on('click', () => {
      this.calculateVatRate(this.$priceOriginalVatRate, this.priceOriginal, this.priceOriginalVat);
    });

    this.$priceOriginal.on('change', () => {
      this.calculatePriceOriginalVat();
    });

    this.$priceOriginalVat.on('change', () => {
      this.calculatePriceOriginal();
    });

    this.$priceOriginalVatRate.on('change', () => {
      this.checkEfficientVatRate(this.$fixPriceOriginalVatRate, this.priceOriginal,
        this.priceOriginalVat, this.priceOriginalVatRate);
    });

    this.checkEfficientVatRate(this.$fixPriceOriginalVatRate, this.priceOriginal,
      this.priceOriginalVat, this.priceOriginalVatRate);

    this.$fixPriceOriginalVatRate.on('click', () => {
      this.$priceOriginalVatRate.val(Numbers.format(this.effectiveVatRate)).trigger('change').highlight();
      this.$calculatePriceOriginal.add(this.$calculatePriceOriginalVat).highlight();

      return false;
    });

    this.$calculatePriceOriginal.on('click', () => {
      this.calculatePriceOriginal();
    });

    this.$calculatePriceOriginalVat.on('click', () => {
      this.calculatePriceOriginalVat();
    });
  }

  setupVatRateCalculatorPrice() {
    this.$calculatePriceVatRate.on('click', () => {
      this.calculateVatRate(this.$priceVatRate, this.price, this.priceVat);
    });

    this.$price.on('change', () => {
      this.calculatePriceVat();
    });

    this.$priceVat.on('change', () => {
      this.calculatePrice();
    });

    this.$priceVatRate.on('change', () => {
      this.checkEfficientVatRate(this.$fixPriceVatRate, this.price, this.priceVat, this.priceVatRate);
    });

    this.checkEfficientVatRate(this.$fixPriceVatRate, this.price, this.priceVat, this.priceVatRate);

    this.$fixPriceVatRate.on('click', () => {
      this.$priceVatRate.val(Numbers.format(this.effectiveVatRate)).trigger('change').highlight();
      this.$calculatePrice.add(this.$calculatePriceVat).highlight();

      return false;
    });

    this.$calculatePrice.on('click', () => {
      this.calculatePrice();
    });

    this.$calculatePriceVat.on('click', () => {
      this.calculatePriceVat();
    });
  }

  setupVatRateCalculatorDiscount() {
    this.$calculatePriceDiscountVatRate.on('click', () => {
      this.calculateVatRate(this.$priceDiscountVatRate, this.priceDiscount, this.priceDiscountVat);
    });

    this.$priceDiscount.on('change', () => {
      this.calculatePriceDiscountVat();
    });

    this.$priceDiscountVat.on('change', () => {
      this.calculatePriceDiscount();
    });

    this.$priceDiscountVatRate.on('change', () => {
      this.checkEfficientVatRate(this.$fixPriceDiscountVatRate, this.priceDiscount,
        this.priceDiscountVat, this.priceDiscountVatRate);
    });

    this.checkEfficientVatRate(this.$fixPriceDiscountVatRate, this.priceDiscount,
      this.priceDiscountVat, this.priceDiscountVatRate);

    this.$fixPriceDiscountVatRate.on('click', () => {
      this.$priceDiscountVatRate.val(Numbers.format(this.effectiveVatRate)).trigger('change').highlight();
      this.$calculatePriceDiscount.add(this.$calculatePriceDiscountVat).highlight();

      return false;
    });

    this.$calculatePriceDiscount.on('click', () => {
      this.calculatePriceDiscount();
    });

    this.$calculatePriceDiscountVat.on('click', () => {
      this.calculatePriceDiscountVat();
    });
  }

  showDealDiscountWarnings(show) {
    this.$dealDiscountWarnings.each(function() {
      $(this).hidden(!show);
    });
  }

  createValueMismatchWarning($mismatchWarning, callback) {
    $mismatchWarning.hidden(false);
    $mismatchWarning.on('click', callback);
  }

  removeValueMismatchWarning($mismatchWarning) {
    $mismatchWarning.hidden(true);
  }

  /**
   * To avoid NPE when calling {@code exists()} on a result of this method,
   * it returns empty jQuery object {@code $()} instead of {@code null} when attribute does not exist
   */
  getAttributeByName(attributeName) {
    const attributeKey = this.$form.attr(attributeName);
    if (!attributeKey) {
      return $();
    }

    const $attribute = this.$form.find(formatAttrSelector(ATTR_KEY, attributeKey));
    if ($attribute.exists()) {
      return $attribute;
    }

    return $();
  }

  updatePricesSqmNet(highlight = true) {
    if (this.areaNetCtrAttribute !== null) {
      const priceSqm = this.areaNetCtrAttribute !== 0 ? this.price / this.areaNetCtrAttribute : this.price;
      const priceSqmDiscounted = this.areaNetCtrAttribute !== 0
        ? this.priceDiscounted / this.areaNetCtrAttribute
        : this.priceDiscounted;

      this.$priceSqmCtr.val(Numbers.format(priceSqm));
      this.$priceSqmDiscountedCtr.val(Numbers.format(priceSqmDiscounted));

      if (highlight) {
        this.$priceSqmCtr.highlight();
        this.$priceSqmDiscountedCtr.highlight();
      }
    }
  }

  updateUpcOverview() {
    const url = updateUrl(Router.get('PRODUCT_DETAIL_PRICE_COMPONENTS_OVERVIEW', { id: this.unitId }), {
      // we read field names to avoid using constant field names that might be changed later
      [this.$upcMode.attr('name')]: this.$upcMode.val(),
      [this.$price.attr('name')]: this.$price.val(),
      [this.$priceDiscount.attr('name')]: this.$priceDiscount.val()
    });

    Fetch.getHtml(url)
      .then(response => {
        if (response.ok) {
          this.$upcOverview.html(response.body);
        }
      }).catch(error => Log.error(error));
  }
}

$(() => {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $unitForm = data.$root.find('#unit-form');
    if ($unitForm.exists()) {
      new UnitForm($unitForm);
    }
  });

  // This part is for new Unit form
  const $unitForm = $('#unit-form');
  if ($unitForm.exists()) {
    new UnitForm($unitForm);
  }
});
