const MONDAY = 1;
const SUNDAY = 7;

export default class TaskCalendarDates {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.days = [];
    for (let i = MONDAY; i <= SUNDAY; i++) {
      this.days.push(this.findDay($element, i));
    }

    this.bindEvents();
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.updateDates();

      this.observable.on('week-change', () => this.updateDates());
    });
  }

  findDay($element, day) {
    const $day = $element.find('.rp-task-calendar-day-' + day);
    const $name = $day.find('.rp-task-calendar-day-name');
    const $date = $day.find('.rp-task-calendar-day-date');

    return {
      day: day,
      $name: $name,
      $date: $date
    };
  }

  updateDates() {
    for (const day of this.days) {
      this.updateDay(day);
    }
  }

  updateDay(day) {
    day.$name.text(this.state.getWeekdayName(day.day));
    day.$date.text(this.state.getWeekdayDate(day.day));
    day.$date.toggleClass('today', this.state.isWeekdayToday(day.day));
  }
}
