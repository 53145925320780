import $ from 'jquery';
import { updateUrl } from '../../utils/functions';

class ChooseFloorModal {
  constructor($modal) {
    this.$projects = $modal.find('#project');
    this.$buildings = $modal.find('#building');
    this.$floors = $modal.find('#floor');

    this.init();
    this.bindEvents();
  }

  init() {
    this.$projects.attr('data-select-url-original', this.$projects.attr('data-select-url'));
    this.$buildings.attr('data-select-url-original', this.$buildings.attr('data-select-url'));
    this.$floors.attr('data-select-url-original', this.$floors.attr('data-select-url'));
  }

  bindEvents() {
    this.$projects.on('change', () => {
      if (this.$projects.val()) {
        this.$buildings.attr('data-select-url', updateUrl(this.$buildings.attr('data-select-url-original'),
          { 'IncludeProjects': this.$projects.val() }));
        const value = this.$buildings.val();
        this.$buildings.disabled(false).val('');

        if (value) {
          this.$buildings.trigger('change');
        }
      }
    });

    this.$buildings.on('change', () => {
      if (this.$buildings.val()) {
        this.$floors.attr('data-select-url', updateUrl(this.$floors.attr('data-select-url-original'),
          { 'IncludeBuildings': this.$buildings.val() }));
        const value = this.$floors.val();
        this.$floors.disabled(false).val('');

        if (value) {
          this.$floors.trigger('change');
        }
      } else {
        this.$floors.disabled().val('').trigger('change');
      }
    });
  }
}

class GenerateFromTemplateModal {
  constructor($table, $modal) {
    this.$modalForm = $modal.find('.modal-content');
    this.params = {};

    this.init();
    this.handleSelected($table);
    this.handleFilterParams();
    this.handleUrl();
  }

  init() {
    this.$modalForm.attr('action-original', this.$modalForm.attr('action'));
  }

  handleSelected($table) {
    const units = $table.data('rp-table').selection.toString();

    if (units) {
      this.params['unitIds'] = units;
    }
  }

  handleFilterParams() {
    if (!this.params.hasOwnProperty('unitIds')) {
      this.params = $('.rp-filter').data('rp-filter').getValues();
    }
  }

  handleUrl() {
    if (this.params && this.$modalForm.attr('action-original')) {
      this.$modalForm.attr('action', updateUrl(this.$modalForm.attr('action-original'),
        this.params));
    }
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#choose-floor-modal-form")) {
      new ChooseFloorModal($('#choose-floor-modal-form'));
    }
    if (data.$root.is("#generate-from-template-modal-form")) {
      new GenerateFromTemplateModal($('#ProductsTableCZ'), $('#generate-from-template-modal-form'));
    }
  });
});
