import $ from 'jquery';
import Numbers from '../../core/numbers';

class UpdateDealUnitsVatModal {
  constructor($modal) {
    this.$modal = $modal;

    this.bindEvents();
  }

  bindEvents() {
    this.$modal.on('change', 'div[data-unit-vat-rate]', event => {
      const $vatRate = $(event.target);
      const $price = $vatRate.closest('[data-unit]').find('[data-unit-price]');
      const $priceVat = $vatRate.closest('[data-unit]').find('[data-unit-price-vat]');

      const vatRate = Numbers.parse($vatRate.val());
      const price = Numbers.parse($price.val());

      if (price != null && vatRate != null) {
        const priceVat = Numbers.round((price * vatRate) / 100 + price);
        $priceVat.val(Numbers.format(priceVat));
      }
    });
  }
}

$(()=> {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#update-deal-units-vat')) {
      new UpdateDealUnitsVatModal(data.$root);
    }
  });
});
