import $ from 'jquery';

const SELECTOR = 'input[data-password-input-with-visibility-toggle]';

class PasswordVisibilityToggle {
  constructor($passwordInput, $passwordAddonMask, $passwordAddonUnmask) {
    this.$passwordInput = $passwordInput;
    this.$passwordAddonMask = $passwordAddonMask;
    this.$passwordAddonUnmask = $passwordAddonUnmask;

    this.bindEvents();
  }

  bindEvents() {
    this.$passwordAddonMask.on('click', () => {
      this.$passwordInput.attr('type', 'password');
      this.toggleVisibility(false);
    });

    this.$passwordAddonUnmask.on('click', () => {
      this.$passwordInput.attr('type', 'text');
      this.toggleVisibility(true);
    });
  }

  toggleVisibility(unmask) {
    this.$passwordAddonMask.hidden(!unmask);
    this.$passwordAddonUnmask.hidden(unmask);
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $passwordInput = $(this);
    const $passwordAddonMask = $passwordInput.closest('.input-group').find('button[data-password-mask]');
    const $passwordAddonUnmask = $passwordInput.closest('.input-group').find('button[data-password-unmask]');

    if ($passwordAddonMask.exists() && $passwordAddonUnmask.exists()) {
      const instance = new PasswordVisibilityToggle($passwordInput, $passwordAddonMask, $passwordAddonUnmask);
      $passwordInput.data('rp-password-visibility-toggle', instance);
    }
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
