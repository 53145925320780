// based on https://stackoverflow.com/a/53222638

const EVENT_START = 0;
const EVENT_STOP = 1;

const WIDTH = 100;

const comparator = (e1, e2) => {
  if (e1.top !== e2.top) {
    return e1.top < e2.top ? -1 : 1;
  }

  if (e1.type !== e2.type) {
    return e1.type > e2.type ? -1 : 1;
  }

  return e1.index < e2.index ? -1 : 1;
};

export default function calculateShift(events) {
  if (events.length === 0) {
    return;
  }

  const eventQueue = [];
  const regionQueue = [];

  events.forEach((event, index) => {
    eventQueue.push({
      top: event.cssTop,
      type: EVENT_START,
      index: index
    });

    eventQueue.push({
      top: event.cssTop + event.cssHeight,
      type: EVENT_STOP,
      index: index
    });
  });

  eventQueue.sort(comparator);

  while (eventQueue.length > 0) {
    let overlap = 0;
    let maxOverlap = 0;

    while (eventQueue.length > 0) {
      const event = eventQueue.shift();

      regionQueue.push(event);

      if (event.type === EVENT_START) {
        overlap++;
      } else {
        overlap--;
      }

      if (overlap === 0) {
        break;
      }

      if (overlap > maxOverlap) {
        maxOverlap = overlap;
      }
    }

    const shift = WIDTH / maxOverlap;
    const usedColumns = new Array(maxOverlap).fill(-1);

    while (regionQueue.length > 0) {
      const event = regionQueue.shift();

      if (event.type === EVENT_START) {
        for (let column = 0; column < maxOverlap; column++) {
          if (usedColumns[column] < 0) {
            usedColumns[column] = event.index;
            events[event.index].cssLeft = column * shift;
            events[event.index].cssIndex = column + 1;
            break;
          }
        }
      } else {
        for (let column = 0; column < maxOverlap; column++) {
          if (usedColumns[column] === event.index) {
            usedColumns[column] = -1;
            break;
          }
        }
      }
    }
  }
}
