import I18N from '../../core/i18n';

export default class Status {
  constructor(table) {
    this.table = table;

    this.$status = table.$element.find('.rp-table-status');
    this.$filterActive = table.$element.find('.rp-table-filter-active');
    this.$searchActive = table.$element.find('.rp-table-search-active');

    this.bindEvents();
  }

  update() {
    if (this.table.pagination) {
      const offset = this.table.pagination.offset;
      const limit = this.table.pagination.limit;
      const totalCount = this.table.pagination.totalCount;
      const firstRecord = Math.min(offset + 1, totalCount);
      const lastRecord = Math.min(offset + limit, totalCount);

      this.$status.text(I18N.t('table_status_pagination', firstRecord, lastRecord, totalCount));
    } else {
      this.$status.text(I18N.t('table_status_simple', this.table.rowCount));
    }

    if (this.table.filter?.nonDefault) {
      this.$filterActive.hidden(false);
      this.$searchActive.hidden(true);
    } else if (this.table.search?.value) {
      this.$filterActive.hidden(true);
      this.$searchActive.hidden(false);
    } else {
      this.$filterActive.hidden(true);
      this.$searchActive.hidden(true);
    }
  }

  bindEvents() {
    this.$filterActive.on('click', 'a', event => {
      event.preventDefault();

      this.table.filter.reset();
      this.table.load(true);
    });

    this.$searchActive.on('click', 'a', event => {
      event.preventDefault();

      this.table.search.clear();
      this.table.load(true);
    });
  }

}
