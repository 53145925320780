import $ from 'jquery';
import { formatAttrSelector, updateUrl } from '../utils/functions';

const ATTR_CHAINED_SELECT_PARENT = 'data-chained-select-parent';
const ATTR_CHAINED_SELECT_CHILD = 'data-chained-select-child';
const ATTR_CHAINED_SELECT_PARAM = 'data-chained-select-param';

/**
 * Show remote select child when value is selected in parent field.
 */
class ChainedSelect {
  constructor($parent, param, $child) {
    this.$parent = $parent;
    this.param = param;
    this.$child = $child;
    this.$childGroup = this.$child.closest('.form-group');
    this.childUrl = $child.attr('data-select-url');

    this.bindEvents();
    this.update(this.$parent.val());
  }

  bindEvents() {
    this.$parent.on('change', event => {
      this.update();
    });
  }

  update() {
    const parentValue = this.$parent.val();
    if (parentValue) {
      const url = updateUrl(this.childUrl, { [this.param]: parentValue });
      this.$child.attr('data-select-url', url);

      if (this.$child.val()) {
        this.$child.val('').trigger('change');
      }

      this.$childGroup.hidden(false);
      this.$childGroup.disabled(false);
    } else {
      this.$childGroup.hidden(true);
      this.$childGroup.disabled(true); // disable inputs when hiding them, so they aren’t submitted
    }
  }
}

function init($root) {
  $root.find(formatAttrSelector(ATTR_CHAINED_SELECT_PARENT)).each(function() {
    const group = $(this).attr(ATTR_CHAINED_SELECT_PARENT);
    const $child = $root.find(formatAttrSelector(ATTR_CHAINED_SELECT_CHILD, group));

    if ($child.exists() && $child.is('[data-select-url]')) {
      const param = $child.attr(ATTR_CHAINED_SELECT_PARAM);
      if (param) {
        new ChainedSelect($(this), param, $child);
      }
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
