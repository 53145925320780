export class ActiveProfile {
  constructor(activeProfile) {
    this.name = activeProfile.name;
    this.email = activeProfile.email;
    this.developerLogoSrc = activeProfile.developerLogoSrc;
    this.ssoProvider = activeProfile.ssoProvider;
  }
}

export class MenuItem {
  constructor(item) {
    this.title = item.title;
    this.subtitle = item.subtitle;
    this.logo = item.logo;
    this.logoName = item.logoName;
  }
}

export class ProfileDetails {
  constructor(profileDetails) {
    this.details = profileDetails.details;
    this.switchUrl = profileDetails.switchUrl;
  }
}

export class ProfilesHeader {
  constructor(header) {
    this.text = header.text;
    this.logoSrc = header.logoSrc;
  }
}

export class Profiles {
  constructor(data) {
    this.header = data.header;
    this.list = data.list;
  }
}

export class UserMenuDetails {
  constructor(details) {
    this.developerDetails = details.developerDetails;
    this.profiles = details.profiles;
    this.userDetailsUrl = details.userDetailsUrl;
    this.logoutUrl = details.logoutUrl;
  }
}