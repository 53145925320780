import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';
import { createError, randomId } from 'utils/functions';
import Confirmation from './confirmation';
import FormSubmit from './formSubmit';

export default class Modal {
  constructor(url) {
    this.url = url;
    this.instanceId = randomId();
    this.$window = $(window);
    this.$body = $(document.body);
  }

  open() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      Fetch.getHtml(this.url)
        .then(response => {
          if (response.ok) {
            this.create(response.body);
          } else {
            this.reject(createError('Failed to load modal content', {
              source: 'Modal',
              response: response
            }));
          }
        })
        .catch(reason => this.reject(reason));
    });
  }

  create(content) {
    this.$modal = $(content);
    this.$body.append(this.$modal);

    const $form = this.$modal.find('form');
    const formId = $form.attr('id') || 'modal-form-' + randomId();
    $form.attr('id', formId);

    const $menuContainer = this.$modal.find('.modal-menu-container');
    this.$modal.find('.modal-footer .btn-group').each(function() {
      const $group = $(this);
      const $toggle = $group.find('.dropdown-toggle');
      const $menu = $group.find('.dropdown-menu');

      if ($toggle.exists() && $menu.exists()) {
        const menuId = 'modal-menu-' + randomId();

        $toggle.attr('data-target', '#' + menuId);
        $menuContainer.append($('<div>', { id: menuId }).append($menu));

        $menu.find(':submit').attr('form', formId);

        $toggle.dropdown({
          reference: $group
        });
      }
    });

    this.bindEvents();

    this.$modal.modal({
      focus: false
    });

    this.$modal.trigger('rp-partial:loaded', {
      $root: this.$modal
    });

    this.$modal.trigger('rp-modal:loaded', {
      $root: this.$modal
    });
  }

  dispose() {
    this.unbindEvents();
    this.resolve({
      ok: false,
      body: {}
    });

    this.$modal.trigger('rp-modal:closed', {
      $root: this.$modal
    });

    this.$modal.modal('dispose');
    this.$modal.remove();
  }

  bindEvents() {
    this.$modal.on('submit', 'form', event => {
      new FormSubmit(event).handle()
        .then(response => {
          this.$modal.modal('hide');
          this.resolve(response);
        })
        .catch(reason => {
          if (reason.invalid) {
            this.$modal.trigger('rp-submit:invalid', {
              response: reason.response
            });
          } else {
            Log.error(reason);
          }
        });
    });

    this.$modal.on('click', ':submit', event => {
      event.preventDefault();

      const $button = $(event.currentTarget);
      Confirmation.create($button).confirm().then(() => {
        $button.attr('data-clicked', 'true');
        $button.form().trigger('submit');
      });
    });

    this.$modal.on('hidden.bs.modal', () => this.dispose());
    this.$window.on(`hashchange.${this.instanceId}`, () => this.$modal.modal('hide'));
  }

  unbindEvents() {
    this.$modal.off('submit');
    this.$modal.off('hidden.bs.modal');
    this.$window.off(`hashchange.${this.instanceId}`);
  }
}
