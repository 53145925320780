<script setup>
import { Avatar, AvatarSize } from '@realpadsoftware/realpad-crm-frontend-lib';
import { ActiveProfile } from './userMenuTypes';

const props = defineProps({
  activeProfile: {
    type: ActiveProfile,
    required: true
  }
});

const emit = defineEmits(['click'])

const onClick = (e) => {
  emit('click', {
    element: (e.currentTarget ?? e.target)
  })
}

</script>

<template>
  <button class="rpvue-UserMenuActiveProfile rpvue-u-UserMenu-button" @click="onClick">
    <div class="rpvue-UserMenuActiveProfile-avatar">
      <Avatar :name="activeProfile.name" :ssoProvider="activeProfile.ssoProvider" :size="AvatarSize.MD"/>
    </div>

    <div class="rpvue-UserMenuActiveProfile-info">
      <span class="rpvue-u-TextTruncate rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
        {{activeProfile.name}}
      </span>
      <span class="rpvue-u-TextTruncate rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--small">
        {{activeProfile.email}}
      </span>
    </div>

    <div class="rpvue-UserMenuActiveProfile-developerLogo" v-if="activeProfile.developerLogoSrc">
      <img class="rpvue-u-ImgFluid rpvue-u-ImgObjectFitScaleDown" :src="activeProfile.developerLogoSrc" alt="Logo">
    </div>
  </button>
</template>