import { useUrlSearchParams } from '@vueuse/core';
import I18N from 'core/i18n';
import Log from 'core/log';
import { computed, toRef, toValue } from 'vue';

const TAB_PARAM = 'customer-activities-tabs';
export const TAB_ID_ALL = 'all';

function getTabName(type) {
  switch (type) {
    case 'email':
      return I18N.t('email_plural');
    case 'task':
      return I18N.t('menu_crm_tasks');
    case 'interaction':
      return I18N.t('business_case_interactions');
    case 'event':
      return I18N.t('events');
    default:
      Log.error('Unknown type: ' + type);
      return type;
  }
}

export function useTabs(configRef) {
  const tabs = computed(() => {
    const config = toValue(configRef);

    const list = [{
      id: TAB_ID_ALL,
      name: I18N.t('customer_activity_stream_tab_all')
    }];

    for (const type of config.filterableTypes) {
      list.push({
        id: type,
        name: getTabName(type)
      });
    }

    return list;
  });

  const hashParams = useUrlSearchParams('hash-params');
  const activeTabId = toRef(hashParams, TAB_PARAM);

  activeTabId.value = activeTabId.value ?? TAB_ID_ALL;

  return { tabs, activeTabId };
}
