import { TaskCalendarUpdatePopover } from './popovers';

function template(event) {
  return `
    <div class="rp-task-calendar-event-overdue" data-event-id="${event.id}">
      <div class="rp-task-calendar-event-overdue-header">
        <div class="rp-task-calendar-event-overdue-type">${event.typeName}</div>
        <div class="rp-task-calendar-event-overdue-customer">${event.customerNameAbbr}</div>
      </div>
      <div class="rp-task-calendar-event-overdue-text">${event.text}</div>
      <div class="rp-task-calendar-event-overdue-footer">
        <div class="rp-task-calendar-event-overdue-salesman">
          <div class="rp-avatar rp-avatar-md" data-avatar-value="${event.salesmanId}">${event.salesmanLetter}</div>
          <span class="rp-task-calendar-event-overdue-salesman-name">${event.salesmanNameAbbr}</span>
        </div>
        <div class="rp-task-calendar-event-overdue-deadline">${event.deadline}</div>
      </div>
    </div>
  `;
}

const SELECTOR_LIST = '.rp-task-calendar-events-overdue-list';
const SELECTOR_EVENT = '.rp-task-calendar-event-overdue';

export default class TaskCalendarEventsOverdue {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.$list = $element.find(SELECTOR_LIST);

    this.bindEvents();
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.observable.on('overdue-events-change', () => this.updateList());

      this.observable.on('popover-loaded', () => {
        this.$list.addClass('stop-scroll');
      });

      this.observable.on('popover-closed', () => {
        this.$list.removeClass('stop-scroll');
      });

      this.$list.on('click', SELECTOR_EVENT, event => {
        new TaskCalendarUpdatePopover($(event.currentTarget), this.observable, this.state);
      });
    });
  }

  updateList() {
    this.$list.empty();

    for (const event of this.state.getOverdueEvents()) {
      this.$list.append($(template(event)));
    }

    this.$list.trigger('rp-content:loaded');
  }
}
