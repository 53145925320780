import { ATTR_CLICK_ACTION_URL } from '../clickAction';
import { updateUrl } from '../../utils/functions';

export default class Actions {
  constructor(table) {
    this.table = table;

    this.init();
  }

  init() {
    const self = this;

    this.table.$element.find('[data-action-records]').each(function() {
      if (self.table.pagination) {
        $(this).disabled(self.table.pagination.totalCount === 0);
      } else {
        $(this).disabled(self.table.rowCount === 0);
      }
    });

    this.table.$element.find('[data-action-filter]').each(function() {
      self.initAction($(this));
    });

    this.table.$element.find('[data-action-selection]').each(function() {
      self.initAction($(this).disabled());
    });
  }

  update() {
    const self = this;

    this.table.$element.find('[data-action-records]').each(function() {
      if (self.table.pagination) {
        $(this).disabled(self.table.pagination.totalCount === 0);
      } else {
        $(this).disabled(self.table.rowCount === 0);
      }
    });

    this.table.$element.find('[data-action-filter]').each(function() {
      self.updateAction($(this));
    });

    this.table.$element.find('[data-action-selection]').each(function() {
      self.updateAction($(this));
    });
  }

  initAction($action) {
    if ($action.attr(ATTR_CLICK_ACTION_URL)) {
      $action.attr(ATTR_CLICK_ACTION_URL + '-original', $action.attr(ATTR_CLICK_ACTION_URL));
    }

    if ($action.attr('href')) {
      $action.attr('href-original', $action.attr('href'));
    }
  }

  updateAction($action) {
    let params = {};

    if ($action.is('[data-action-filter]')) {
      if (this.table.filter) {
        params = Object.assign(params, this.table.filter.values);
      }
      if (this.table.search?.value) {
        params['filter_fulltext'] = this.table.search.value;
      }
    }

    if ($action.is('[data-action-selection]') && this.table.selection) {
      const selection = this.table.selection.toString();

      if (selection) {
        $action.disabled(false);
        params = Object.assign(params, { selection });
      } else {
        $action.disabled();
      }
    }

    if ($action.attr(ATTR_CLICK_ACTION_URL + '-original')) {
      $action.attr(ATTR_CLICK_ACTION_URL,
        updateUrl($action.attr(ATTR_CLICK_ACTION_URL + '-original'), params));
    }

    if ($action.attr('href-original')) {
      $action.attr('href',
        updateUrl($action.attr('href-original'), params));
    }
  }

}
