import $ from 'jquery';

const SELECTOR_MAX_LENGTH = 'input[maxlength],textarea[maxlength]';

class MaxLength {
  constructor($element) {
    this.$input = $element;
    this.$counter = $element.closest('.form-group').find('.form-counter');

    this.maxLength = this.$input.attr('maxlength');

    if (this.$counter.exists() && this.maxLength) {
      this.updateCounter();
      this.bindEvents();
    }
  }

  bindEvents() {
    this.$input.on('input', () => this.updateCounter());
  }

  updateCounter() {
    this.$counter.text(`${this.$input.val()?.length || 0} / ${this.maxLength}`);
  }
}

function init($root) {
  $root.find(SELECTOR_MAX_LENGTH).each(function() {
    new MaxLength($(this));
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
