import $ from 'jquery';
import { formatAttrSelector } from '../utils/functions';

export const ATTR_REQUIRED_GROUP = 'data-required-group';

export default class RequiredInputGroup {
  constructor($root, groupName) {
    this.$inputs = $root.find(formatAttrSelector(ATTR_REQUIRED_GROUP, groupName));

    this.bindEvents();
    this.update(false);
  }

  bindEvents() {
    this.$inputs.on('change', () => this.update());
  }

  update(validate = true) {
    let required = true;

    this.$inputs.each(function() {
      const $input = $(this);

      if ($input.is(':radio,:checkbox')) {
        if ($input.is(':checked')) {
          required = false;
        }
      } else {
        if ($input.val()) {
          required = false;
        }
      }
    });

    this.$inputs.required(required, validate);
  }
}
