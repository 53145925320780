import $ from 'jquery';
import noUiSlider from 'nouislider';
import Numbers from '../core/numbers';
import { formatAttrSelector } from '../utils/functions';

const SELECTOR = formatAttrSelector('data-auto-init', 'rating');

class RatingInput {
  constructor($element) {
    this.$element = $element;
    this.$input = $element.find('.rp-rating-input');
    this.$label = $element.find('.rp-rating-label-value');
    this.$slider = $element.find('.rp-rating-slider');

    this.slider = this.createSlider();

    this.bindEvents();
    this.init();
  }

  init() {
    this.updateState(this.$input.val());
    this.updateDisabled();
  }

  bindEvents() {
    this.$input.on('change', (event, data) => {
      if (data.update) {
        return; // avoid endless loop
      }

      this.slider.set(this.$input.val());
    });

    this.slider.on('update', (values, handle) => {
      const value = values[handle];

      this.$input.val(value).trigger('change', {
        update: true // avoid endless loop
      });

      this.updateState(value);
    });

    this.$slider.on('click', '.noUi-marker', event => {
      if (this.$slider.is(formatAttrSelector('disabled'))) {
        return;
      }

      this.slider.set($(event.currentTarget).next().attr('data-value'));
    });

    this.observer = new MutationObserver(() => this.updateDisabled());
    this.observer.observe(this.$input.get(0), {
      attributes: true,
      attributeFilter: ['disabled']
    });
  }

  updateState(value) {
    const label = this.$slider.attr('data-rating-' + value + '-label');
    const color = this.$slider.attr('data-rating-' + value + '-color');

    this.$label.text(label);
    this.$element.attr('data-color', color);

    this.$slider.find('.noUi-marker').removeClass('active');
    this.$slider.find('.noUi-value' + formatAttrSelector('data-value', value))
      .prevAll('.noUi-marker')
      .addClass('active');
  }

  updateDisabled() {
    if (this.$input.prop('disabled')) {
      this.slider.disable();
    } else {
      this.slider.enable();
    }
  }

  createSlider() {
    const ratingMin = Numbers.parse(this.$slider.attr('data-rating-min'));
    const ratingMax = Numbers.parse(this.$slider.attr('data-rating-max'));
    const value = Numbers.parse(this.$input.val());

    return noUiSlider.create(this.$slider.get(0), {
      connect: 'lower',
      range: {
        min: [ratingMin],
        max: [ratingMax]
      },
      start: [value],
      step: 1,
      pips: {
        mode: 'steps',
        filter: (value, type) => type === 0 ? -1 : 1
      },
      format: {
        from: function(value) {
          return Numbers.parse(value);
        },
        to: function(value) {
          return Numbers.format(value);
        }
      }
    });
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $element = $(this);
    const ratingInput = new RatingInput($element);

    $element.data('rp-rating-input', ratingInput);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
