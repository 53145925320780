import $ from 'jquery';
import Validation from '../components/validation';

$.fn.exists = function() {
  return this.length > 0;
};

$.fn.form = function() {
  return this.map(function() {
    if (this.form) {
      return this.form;
    }
    return this.closest('form');
  });
};

$.fn.disabled = function(disabled) {
  disabled = typeof disabled === 'undefined' || disabled;

  return this.each(function() {
    const $this = $(this);
    const $container = $this.closest('.rp-vue-app-container');

    // if current element is child of .rp-vue-app-container, disable .rp-vue-app-container instead
    if ($container.exists() && !$this.is($container)) {
      $container.disabled();
      return;
    }

    // if current element is .rp-vue-app-container
    if ($this.is($container)) {
      if ($this.is(':not([data-never-disable])')) {
        if (disabled) {
          $this.attr('data-disabled', 'true')
        } else {
          $this.removeAttr('data-disabled');
        }
      }
      return;
    }

    // if current element is input of some kind
    if ($this.is(':input')) {
      if ($this.is(':not([data-never-disable])')) {
        $this.prop('disabled', disabled);
      }
      return;
    }

    // if current element is link button of some kind
    if ($this.is('a.btn,a.rp-icon-btn')) {
      if ($this.is(':not([data-never-disable])')) {
        $this.toggleClass('disabled', disabled);
        if (disabled) {
          $this.attr('tabindex', '-1').attr('aria-disabled', 'true');
        } else {
          $this.removeAttr('tabindex').removeAttr('aria-disabled');
        }
      }
      return;
    }

    // otherwise try to disable children (in case method was called on a form etc)
    $this.children().disabled(disabled);
  });
};

$.fn.loading = function(loading) {
  loading = typeof loading === 'undefined' || loading;

  return this.each(function() {
    const $this = $(this);

    if ($this.is('.btn') || $this.is('.rp-icon-btn')) {
      $this.toggleClass('loading', loading);
    }

    $this.disabled(loading);
  });
};

$.fn.hidden = function(hidden) {
  hidden = typeof hidden === 'undefined' || hidden;

  return this.each(function() {
    $(this).prop('hidden', hidden);
  });
};

$.fn.required = function(required, validate) {
  required = typeof required === 'undefined' || required;
  validate = typeof validate === 'undefined' || validate;

  return this.each(function() {
    const $input = $(this);
    const $group = $input.closest('.form-group');
    const $container = $input.closest('.rp-vue-app-container');

    if ($container.exists()) {
      if (required) {
        $container.attr('data-required', 'true')
      } else {
        $container.removeAttr('data-required');
      }
    } else {
      $input.prop('required', required);
    }
    if ($group.exists()) {
      $group.toggleClass('required', required);
    }

    if (validate) {
      Validation.validate(this);
    }
  });
};

$.fn.highlight = function() {
  return this.each(function() {
    let $element = $(this).closest('.input-group');

    if ($element.exists() && $element.is(':visible')) {
      $element.one('transitionend', () => {
        $element.one('transitionend', () => {
          $element.removeClass('highlight');
        });

        $element.removeClass('in');
      });

      $element.addClass('highlight in');
    }
  });
};

$.fn.shake = function() {
  return this.each(function() {
    let $element = $(this);

    if ($element.is(':visible')) {
      $element.one('animationend', () => {
        $element.removeClass('shake');
      });

      $element.addClass('shake');
    }
  });
};

$.fn.serializeObject = function() {
  const object = {};

  for (const param of this.serializeArray()) {
    object[param.name] = object[param.name] || [];
    object[param.name].push(param.value);
  }

  return object;
};

$.valHooks.input = {
  get(element) {
    const container = element.closest('.rp-vue-app-container');
    if (container != null) {
      return container.dataset.value;
    }
  },
  set(element, value) {
    const container = element.closest('.rp-vue-app-container');
    if (container != null) {
      container.dataset.value = value;
      return true;
    }
  }
}

$.valHooks.div = {
  get(element) {
    if (element.classList.contains('rp-vue-app-container')) {
      return element.dataset.value;
    }
  },
  set(element, value) {
    if (element.classList.contains('rp-vue-app-container')) {
      element.dataset.value = value;
      return true;
    }
  }
};

$.valHooks.script = {
  get(element) {
    if (element.type === 'text/rp-vue-app-template') {
      return element.dataset.value;
    }
  },
  set(element, value) {
    if (element.type === 'text/rp-vue-app-template') {
      element.dataset.value = value;
      return true;
    }
  }
};
