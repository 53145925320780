import $ from 'jquery';
import TaskCalendar from './calendar';

function init($root) {
  $root.find('.rp-task-calendar').each(function() {
    const $element = $(this);
    const taskCalendar = new TaskCalendar($element);

    $element.data('rp-task-calendar', taskCalendar);
  });
}

$(() => {
  init($(document.body));
});
