import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';

class CreatePrereservationModalForm {
  constructor($modal) {
    this.$products = $modal.find('#products');
    this.$expirationDate = $modal.find('#ExpirationDate');

    if (this.$expirationDate.exists()) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.$products.on('change', () => {
      Fetch.post(Router.get('API_NEXT_PRERESERVATION_DATE'), { 'UnitIds': this.$products.val() })
        .then(response => {
          if (response.ok) {
            const validFrom = response.body['ValidFrom'];
            const validUntil = response.body['ValidUntil'];

            this.$expirationDate
              .attr('data-min-date', validFrom).trigger('update')
              .val(validUntil).trigger('change');
          }
        })
        .catch(error => Log.error(error));
    });
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#create-prereservation-modal-form")) {
      new CreatePrereservationModalForm($('#create-prereservation-modal-form'));
    }
  });
});
