import $ from 'jquery';
import Numbers from '../../core/numbers';

const NUMBER_FORMAT_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

class AcquisitionBasicData {
  constructor($root) {
    this.$landNetArea = $root.find('#LandNetArea');
    this.$sellingPrice = $root.find('#SellingPrice');
    this.$revenue = $root.find('#Revenue');

    if (this.$revenue.exists() && this.$sellingPrice.exists() && this.$landNetArea.exists()) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.$landNetArea.on('change', () => {
      this.recalculateRevenue();
    });

    this.$sellingPrice.on('change', () => {
      this.recalculateRevenue();
    });
  }

  recalculateRevenue() {
    const sellingPrice = Numbers.parse(this.$sellingPrice.val());
    const netArea = Numbers.parse(this.$landNetArea.val());
    if (sellingPrice && netArea) {
      const revenue = sellingPrice * netArea;
      this.$revenue.val(Numbers.format(revenue, NUMBER_FORMAT_OPTIONS)).highlight();
    } else {
      this.$revenue.val('').highlight();
    }
  }
}

$(() => {
  $(document).on('rp-tabs:loaded', (event, data) => {
    if (data.$tab?.is('#acquisition-tabs-basic-data-tab')) {
      new AcquisitionBasicData(data.$root);
    }
  });
});