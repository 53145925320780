import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import ResponseCommand from './responseCommand';

$(() => {
  $(document).on('click', '.language-menu .dropdown-item', event => {
    Fetch.post(Router.get('API_UPDATE_LOCALE'), { 'locale': $(event.currentTarget).attr('id') })
      .then(response => new ResponseCommand(response).handle())
      .catch(error => Log.error(error));
  });
});
