import $ from 'jquery';
import { createAlert } from './alerts';

class PopoverConfirmation {
  constructor($button) {
    this.$button = $button;
    this.$confirmation = $button.find('.btn-confirmation');

    if (this.$confirmation.exists()) {
      this.init();
    }
  }

  init() {
    this.$button.popover({
      container: 'body',
      boundary: 'window',
      placement: 'top',
      trigger: 'manual',
      html: true,
      customClass: () => {
        return 'popover-confirmation '
          + this.$confirmation.attr('data-confirmation-class')
      },
      content: () => {
        return $('<div>')
          .addClass('confirmation-message')
          .html(this.$confirmation.html())
          .add($('<div>')
            .addClass('confirmation-buttons')
            .append($('<button>')
              .addClass('btn btn-link confirmation-cancel')
              .text(this.$confirmation.attr('data-cancel-label')))
            .append($('<button>')
              .addClass('btn btn-link confirmation-confirm')
              .text(this.$confirmation.attr('data-confirm-label'))));
      }
    });
  }

  bindEvents(resolve) {
    this.$button.on('shown.bs.popover', () => {
      $(document).on('click.confirmation', event => {
        const $target = $(event.target);
        if ($target.is(this.$button)) {
          return;
        }

        if ($target.closest('.popover-confirmation').exists()) {
          if ($target.is('.confirmation-confirm')) {
            this.$button.popover('hide');
            resolve();
          }

          if ($target.is('.confirmation-cancel')) {
            this.$button.popover('hide');
          }

          return;
        }

        this.$button.popover('hide');
      });

      $(document).on('show.bs.dropdown.confirmation', () => {
        this.$button.popover('hide');
      });

      this.$button.one('hide.bs.popover', () => {
        this.$button.off('shown.bs.popover');
        $(document).off('click.confirmation');
        $(document).off('show.bs.dropdown.confirmation');
      });
    });
  }

  confirm() {
    if (this.$confirmation.exists()) {
      return new Promise((resolve) => {
        this.bindEvents(resolve);
        this.$button.popover('toggle');
      });
    }

    return Promise.resolve();
  }
}

const CONFIRMATION_TO_ALERT_LEVEL = {
  'confirmation-primary': 'info',
  'confirmation-success': 'success',
  'confirmation-warning': 'warning',
  'confirmation-danger': 'error'
};

class ModalConfirmation {
  constructor($button) {
    this.$button = $button;
    this.$confirmation = $button.find('.btn-confirmation');

    if (this.$confirmation.exists()) {
      this.init();
    }
  }

  init() {
    const modalHtml = `
      <div class="modal fade" role="dialog" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              ${createAlert(CONFIRMATION_TO_ALERT_LEVEL[this.$confirmation.attr('data-confirmation-class')], this.$confirmation.text())}
            </div>
            <div class="modal-footer-container">
              <div class="modal-footer">
                <div class="modal-footer-buttons">
                  <button type="button" class="btn btn-primary-filled confirmation-confirm">
                    <span class="btn-text">${this.$confirmation.attr('data-confirm-label')}</span>
                  </button>
                  <button type="button" class="btn btn-primary-outline" data-dismiss="modal">
                    <span class="btn-text">${this.$confirmation.attr('data-cancel-label')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    this.$modal = $(modalHtml);
    this.$modal.appendTo('body');
    this.$modal.modal({
      show: false
    });
  }

  bindEvents(resolve) {
    this.$modal.on('shown.bs.modal', () => {
      this.$modal.on('click.confirmation', '.confirmation-confirm', () => {
        this.$modal.modal('hide');
        resolve();
      });

      this.$modal.one('hide.bs.modal', () => {
        this.$modal.off('click.confirmation');
      });
    });
  }

  confirm() {
    if (this.$confirmation.exists()) {
      return new Promise((resolve) => {
        this.bindEvents(resolve);
        this.$modal.modal('show');
      });
    }

    return Promise.resolve();
  }
}

export default class Confirmation {
  static create($button) {
    let confirmation = $button.data('rp-confirmation');
    if (confirmation) {
      return confirmation;
    }

    if ($button.is('.dropdown-item') || $button.closest('.rp-interactive-list-item').exists()) {
      confirmation = new ModalConfirmation($button);
    } else {
      confirmation = new PopoverConfirmation($button);
    }

    $button.data('rp-confirmation', confirmation);
    return confirmation;
  }
}
