import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import dayjs from 'dayjs';
import { abbreviate, debounce } from 'utils/functions';
import Avatar from '../../avatars';

const FORMAT_DATE = 'YYYY-MM-DD';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = FORMAT_DATE + ' ' + FORMAT_TIME;

export const CSS_PX_PER_MINUTE = 1.5;
export const CSS_TOP_SHIFT = 30 * CSS_PX_PER_MINUTE;

const ABBR_THRESHOLD_SALESMAN = 13;
const ABBR_THRESHOLD_CUSTOMER = 11;

const STATE_DONE = 2;
const STATE_CANCELLED = 3;

const LOAD_DELAY = 250;

class Event {
  constructor(record) {
    this._record = record;
    this._avatar = new Avatar(String(record['salesman_id']));

    this._date = dayjs(record['date'], FORMAT_DATE);
    if (record['start']) {
      this._start = dayjs(record['date'] + ' ' + record['start'], FORMAT_DATE_TIME);
    }
    if (record['end']) {
      this._end = dayjs(record['date'] + ' ' + record['end'], FORMAT_DATE_TIME);
    }

    this.cssLeft = 0;
    this.cssIndex = 1;
  }

  get id() {
    return this._record['id'];
  }

  get date() {
    return this._date;
  }

  get dateKey() {
    return this.date.format(FORMAT_DATE);
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  get timeless() {
    return !this.start || !this.end;
  }

  get past() {
    return this.date.isBefore(dayjs(), 'day');
  }

  get done() {
    return this._record['state_id'] === STATE_DONE;
  }

  get cancelled() {
    return this._record['state_id'] === STATE_CANCELLED;
  }

  get deadline() {
    if (this.timeless) {
      return this.date.format(FORMAT_DATE);
    }
    return this.start.format(FORMAT_DATE_TIME) + ' - ' + this.end.format(FORMAT_TIME);
  }

  get duration() {
    if (this.timeless) {
      return '';
    }
    return this.start.format(FORMAT_TIME) + ' - ' + this.end.format(FORMAT_TIME);
  }

  get cssTop() {
    if (this.timeless) {
      return null;
    }

    return this.start.diff(this.start.startOf('day'), 'minute') * CSS_PX_PER_MINUTE + CSS_TOP_SHIFT;
  }

  get cssHeight() {
    if (this.timeless) {
      return null;
    }

    return this.end.diff(this.start, 'minute') * CSS_PX_PER_MINUTE;
  }

  get cssBorderColor() {
    return this._avatar.getPrimaryColor();
  }

  get cssBgColor() {
    return this._avatar.getSecondaryColor();
  }

  get cssStyle() {
    const properties = [];

    properties.push({
      key: 'border-color',
      value: this.cssBorderColor
    });

    properties.push({
      key: 'background',
      value: this.cssBgColor
    });

    if (!this.timeless) {
      properties.push({
        key: 'top',
        value: this.cssTop + 'px'
      });

      properties.push({
        key: 'left',
        value: this.cssLeft + 'px'
      });

      properties.push({
        key: 'z-index',
        value: this.cssIndex
      });

      properties.push({
        key: 'height',
        value: this.cssHeight + 'px'
      });
    }

    return properties.map(p => p.key + ':' + p.value).join(';');
  }

  get cssClass() {
    const classes = [];

    if (this.past) {
      classes.push('past');
    }

    if (this.done) {
      classes.push('done');
    }

    if (this.cancelled) {
      classes.push('cancelled');
    }

    return classes.join(' ');
  }

  get typeName() {
    return this._record['type_name'];
  }

  get salesmanId() {
    return this._record['salesman_id'];
  }

  get salesmanName() {
    return this._record['salesman_name'];
  }

  get salesmanNameAbbr() {
    return abbreviate(this.salesmanName, ABBR_THRESHOLD_SALESMAN);
  }

  get salesmanLetter() {
    return this.salesmanName.charAt(0).toUpperCase();
  }

  get customerName() {
    return this._record['customer_name'] || '';
  }

  get customerNameAbbr() {
    return abbreviate(this.customerName, ABBR_THRESHOLD_CUSTOMER);
  }

  get text() {
    return this._record['text'] || '';
  }
}

export default class TaskCalendarData {
  constructor(observable, state) {
    this.observable = observable;
    this.state = state;

    this.bindEvents();
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.load();

      this.observable.on('week-change', debounce(LOAD_DELAY, () => this.load()));
      this.observable.on('salespeople-change', debounce(LOAD_DELAY, () => this.load()));

      $(document).on('rp-content:reload', () => this.load());
    });
  }

  load() {
    Fetch.post(Router.get('API_TASK_CALENDAR_DATA'), this.state.getFilter())
      .then(response => {
        if (response.ok) {
          this.state.setEvents(response.body['events'].map(record => new Event(record)));
          this.state.setOverdueEvents(response.body['overdue_events'].map(record => new Event(record)));
        }
      })
      .catch(error => Log.error(error));
  }
}
