import $ from 'jquery';
import RequiredInputGroup from '../../components/requiredInputGroup';

$(() => {
  $(document).on('rp-partial:loaded', (event, data) => {
    if (data.$root.is("#discount-step")) {
      new RequiredInputGroup(data.$root, 'discount-group');
    }

    if (data.$root.is("#add-price-step")) {
      new RequiredInputGroup(data.$root, 'add-price-group');
    }

    if (data.$root.is("#set-price-step")) {
      new RequiredInputGroup(data.$root, 'set-price-group');
    }
  });
});