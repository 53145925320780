import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import { debounce, formatAttrSelector } from 'utils/functions';

const ATTR_SALESPEOPLE_SELECTED = 'data-salespeople-selected';
const ATTR_SALESMAN_ID = 'data-salesman-id';

const SELECTOR_LIST = '.rp-task-calendar-salespeople-list';
const SELECTOR_SALESMAN = '.rp-task-calendar-salesman';
const SELECTOR_CURRENT_USER = '.rp-task-calendar-salesman[data-current-user]';

const KV_STORE_DELAY = 250;
const KV_STORE_KEY = 'tasks_calendar_salespeople';

export default class TaskCalendarSalespeople {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.$list = $element.find(SELECTOR_LIST);

    this.saveSalespeople = debounce(KV_STORE_DELAY, value => Fetch
      .post(Router.get('API_USER_KV_STORE'), { key: KV_STORE_KEY, value: value.join(',') })
      .catch(error => Log.error(error)));

    if (this.$list.exists()) {
      this.init();
      this.bindEvents();
    }
  }

  init() {
    let found = false;

    this.$list.attr(ATTR_SALESPEOPLE_SELECTED).split(',').forEach(salesman => {
      if (this.$list.find(formatAttrSelector(ATTR_SALESMAN_ID, salesman)).exists()) {
        this.state.toggleSalesman(salesman);
        found = true;
      }
    });

    if (found) {
      return;
    }

    this.state.toggleSalesman(this.$list.find(SELECTOR_CURRENT_USER).attr(ATTR_SALESMAN_ID));
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.updateList();

      this.observable.on('salespeople-change', () => {
        this.updateList();
        this.saveSalespeople(this.state.getSalespeople());
      });

      this.$list.on('click', SELECTOR_SALESMAN, event => {
        const $element = $(event.currentTarget);
        this.state.toggleSalesman($element.attr(ATTR_SALESMAN_ID));
      });
    });
  }

  updateList() {
    const self = this;
    this.$list.find(SELECTOR_SALESMAN).each(function() {
      const $element = $(this);
      $element.toggleClass('selected', self.state.isSalesmanSelected($element.attr(ATTR_SALESMAN_ID)));
    });
  }
}
