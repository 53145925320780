export class Config {
  constructor(data = { actions: {} }) {
    this.itemsAction = data.actions['items_list'];
    this.createEmailAction = data.actions['email_create'];
    this.createTaskAction = data.actions['task_create'];
    this.createInteractionAction = data.actions['interaction_create'];

    this.filterableTypes = data['filterable_types'] ?? [];
    this.alerts = new ConfigAlerts(data['alerts']);
  }
}

export class ConfigAlerts {
  constructor(data = {}) {
    this.warnings = data['warnings'] ?? [];
    this.infos = data['infos'] ?? [];
  }
}

export class BaseItem {
  constructor(data) {
    this.id = data['id'];
    this.type = data['type'];
    this.typeName = data['type_name'];
    this.timestamp = new Date(data['timestamp']);
  }
}

export class EmailItem extends BaseItem {
  constructor(data) {
    super(data);

    this.emailFrom = [];
    if (data['email_from']) {
      for (const address of data['email_from']) {
        this.emailFrom.push(new EmailAddress(address));
      }
    }

    this.emailTo = [];
    if (data['email_to']) {
      for (const address of data['email_to']) {
        this.emailTo.push(new EmailAddress(address));
      }
    }

    this.emailCc = [];
    if (data['email_cc']) {
      for (const address of data['email_cc']) {
        this.emailCc.push(new EmailAddress(address));
      }
    }

    this.emailSubject = data['email_subject'];
    this.emailContentHtml = data['email_content_html'];
    this.emailContentText = data['email_content_text'];

    this.emailAttachments = [];
    if (data['email_attachments']) {
      for (const attachment of data['email_attachments']) {
        this.emailAttachments.push(new EmailAttachment(attachment));
      }
    }

    this.emailReplyAction = data.actions['email_reply'];
    this.emailReplyAllAction = data.actions['email_reply_all'];
  }

  get hasActions() {
    return this.emailReplyAction || this.emailReplyAllAction;
  }
}

export class EmailAddress {
  constructor(data) {
    this.address = data['address'];
    this.name = data['name'];

    switch (data['type']) {
      case 'customer':
        this.icon = 'person';
        break;
      case 'salesman':
        this.icon = 'support_agent';
        break;
    }
  }
}

export class EmailAttachment {
  constructor(data) {
    this.url = data['url'];
    this.fileName = data['file_name'];
    this.fileSize = data['file_size'];
  }
}

export class EmailDraft {
  constructor(data) {
    this.id = data['id'];
    this.reply = data['reply'];
    this.subject = data['subject'];
    this.content_text = data['content_text'];
    this.content_html = data['content_html'];

    this.to = [];
    for (const address of data['to'] ?? []) {
      this.to.push(new EmailAddress(address));
    }

    this.cc = [];
    for (const address of data['cc'] ?? []) {
      this.cc.push(new EmailAddress(address));
    }

    this.updateAction = data.actions['draft_update'];
    this.sendAction = data.actions['draft_send'];
    this.deleteAction = data.actions['draft_delete'];
  }
}

export class TaskItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.taskType = data['task_type'];
    this.taskStatusColor = data['task_status_color'];
    this.taskStatusName = data['task_status_name'];
    this.taskText = data['task_text'];
    this.taskDeadline = data['task_deadline'] ? new Date(data['task_deadline']) : null;
    this.taskCompleted = data['task_completed'] ? new Date(data['task_completed']) : null;

    this.taskCompleteAction = data.actions['task_complete'];
    this.taskCancelAction = data.actions['task_cancel'];
    this.taskReopenAction = data.actions['task_reopen'];
    this.taskUpdateAction = data.actions['task_update'];
    this.taskDeleteAction = data.actions['task_delete'];
  }

  get hasActions() {
    return this.taskCompleteAction || this.taskCancelAction || this.taskReopenAction
      || this.taskUpdateAction || this.taskDeleteAction;
  }
}

export class InteractionItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.interactionType = data['interaction_type'];
    this.interactionNote = data['interaction_note'];

    this.interactionUpdateAction = data.actions['interaction_update'];
    this.interactionDeleteAction = data.actions['interaction_delete'];
  }

  get hasActions() {
    return this.interactionUpdateAction || this.interactionDeleteAction;
  }
}

export class EventItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.eventType = data['event_type'];
    this.eventText = data['event_text'];
    this.eventTaskType = data['event_task_type'];
    this.eventDeal = data['event_deal'];
    this.eventDealUrl = data['event_deal_url'];
    this.eventProject = data['event_project'];
    this.eventProjectUrl = data['event_project_url'];
    this.eventUnit = data['event_unit'];
    this.eventUnitUrl = data['event_unit_url'];
  }
}

export class DateItem {
  constructor(date) {
    this.id = `date-${date}`;
    this.type = 'date';
    this.date = date;
  }
}
