import $ from 'jquery';

const ROUTES_SELECTOR = '#rp-routes';

export default class Router {
  static loaded = false;
  static routes = new Map();

  static getRoutes() {
    if (this.loaded) {
      return this.routes;
    }

    const json = $(ROUTES_SELECTOR).remove().text();
    if (json.length > 0) {
      for (const [key, value] of Object.entries(JSON.parse(json))) {
        this.routes.set(key, value);
      }
    }

    this.loaded = true;
    return this.routes;
  }

  static get(key, args = {}) {
    const routes = this.getRoutes();

    if (!routes.has(key)) {
      return key;
    }

    return routes.get(key).replace(/{([^:}]+)(:[^}]+)?}/g, (match, group1) => {
      const arg = args[group1];
      if (arg === undefined) {
        return group1;
      }
      return arg;
    });
  }
}
