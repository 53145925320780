class AbstractStorage {
  #storage;
  #prefix;

  constructor(storage, prefix) {
    this.#storage = storage;
    this.#prefix = prefix;
  }

  #formatKey(key) {
    if (this.#prefix) {
      return this.#prefix + '-' + key;
    }

    return key;
  }

  get(key) {
    try {
      return this.#storage.getItem(this.#formatKey(key));
    } catch (e) {
      // ignore
      return null;
    }
  }

  getJson(key) {
    try {
      return JSON.parse(this.get(key));
    } catch (e) {
      // ignore
      return null;
    }
  }

  set(key, value) {
    try {
      this.#storage.setItem(this.#formatKey(key), value);
    } catch (e) {
      // ignore
    }
  }

  setJson(key, value) {
    try {
      this.set(key, JSON.stringify(value));
    } catch (e) {
      // ignore
    }
  }

  remove(key) {
    try {
      this.#storage.removeItem(this.#formatKey(key));
    } catch (e) {
      // ignore
    }
  }
}

export class LocalStorage extends AbstractStorage {
  constructor(prefix) {
    super(window.localStorage, prefix);
  }
}

export class SessionStorage extends AbstractStorage {
  constructor(prefix) {
    super(window.sessionStorage, prefix);
  }
}
