import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { deepEqual } from 'utils/functions';

const INPUT_FILTER = ':input[name^=filter_]';
const SELECTOR_FILTER_FORM = '.rp-filter';

class Filter {
  constructor($element) {
    this.$element = $element;
    this.$container = $element.find('.rp-filter-container');
    this.$additional = $element.find('.rp-filter-additional');

    this.filterId = $element.attr('data-filter-id');
    this.rememberValues = $element.attr('data-filter-remember-values') === 'true';
    this.rememberSettings = $element.attr('data-filter-remember-settings') === 'true';

    this.defaultValues = this.getValues();
    this.defaultSettings = this.getSettings();

    this.bindEvents();
    this.restoreValues();
    this.restoreSettings();
  }

  bindEvents() {
    this.$element.on('click', '.rp-filter-apply', () => {
      this.filter();
    });

    this.$element.on('click', '.rp-filter-reset', () => {
      this.reset();
    });

    if (this.rememberSettings) {
      this.$element.on('shown.bs.collapse', () => {
        this.$element.trigger('rp-filter:visibility-changed');

        this.saveState();
      });

      this.$element.on('hidden.bs.collapse', () => {
        this.$element.trigger('rp-filter:visibility-changed');

        this.saveState();
      });
    }

    this.$element.on('change', '[data-filter-parent]', event => {
      const $parent = $(event.target);
      const selected = $parent.val();
      const $container = $parent.closest('.rp-filter-hierarchical');

      $container.find('[data-filter-child]').each(function() {
        const $child = $(this);

        if (selected && selected.includes($child.attr('data-filter-child'))) {
          $child.hidden(false);
        } else {
          $child.hidden();
          $child.find('select').val('').trigger('change');
        }
      });
    });
  }

  saveState() {
    let values = this.getValues();
    let settings = this.getSettings();

    if (deepEqual(this.defaultValues, values)) {
      values = {};
    }

    if (deepEqual(this.defaultSettings, settings)) {
      settings = {};
    }

    Fetch.post(Router.get('API_SAVE_FILTER_STATE'), {
      'filter': this.filterId,
      'save_values': String(this.rememberValues),
      'filter_values': JSON.stringify(values),
      'save_settings': String(this.rememberSettings),
      'filter_settings': JSON.stringify(settings)
    }).catch(error => Log.error(error));
  }

  filter() {
    this.$element.trigger('rp-filter:filter', {
      id: this.filterId,
      values: this.getValues(),
      nonDefault: this.isNonDefault()
    });

    if (this.rememberValues) {
      this.saveState();
    }
  }

  reset() {
    const self = this;

    this.$element.find(INPUT_FILTER).each(function() {
      var $input = $(this);
      var name = $input.attr('name');
      var value = self.defaultValues[name];

      if ($input.is(':checkbox,:radio')) {
        if (value) {
          $input.prop('checked', value).trigger('change');
        } else {
          $input.prop('checked', false).trigger('change');
        }
      } else {
        if (value) {
          $input.val(value).trigger('change');
        } else {
          $input.val('').trigger('change');
        }
      }
    });

    this.$element.trigger('rp-filter:reset', {
      id: this.filterId,
      values: this.getValues(),
      nonDefault: this.isNonDefault()
    });

    if (this.rememberValues) {
      this.saveState();
    }
  }

  getFilterState() {
    return {
      'filter_values': JSON.stringify(this.getValues()),
      'filter_settings': JSON.stringify(this.getSettings())
    }
  }

  getValues() {
    var values = {};

    this.$element.find(INPUT_FILTER).each(function() {
      var $input = $(this);
      var name = $input.attr('name');

      var value;
      if ($input.is(':checkbox,:radio')) {
        value = $input.is(':checked');
      } else {
        value = $input.val();
      }

      if (value) {
        values[name] = value;
      }
    });

    return values;
  }

  isNonDefault() {
    return !deepEqual(this.getValues(), this.defaultValues);
  }

  getSettings() {
    var settings = {};

    settings['filter_hidden'] = this.$container.is(':not(.show)');
    settings['additional_criteria_visible'] = this.$additional.is('.show');

    return settings;
  }

  getJson(className) {
    var json = this.$element.find('.' + className).text();
    if (json) {
      var values = JSON.parse(json);
      if (values) {
        return values;
      }
    }

    return {};
  }

  restoreValues() {
    var values = this.getJson('rp-filter-values');

    this.$element.find(INPUT_FILTER).each(function() {
      var $input = $(this);
      var name = $input.attr('name');
      var value = values[name];

      if (value) {
        if ($input.is(':checkbox,:radio')) {
          $input.prop('checked', value).trigger('change');
        } else {
          $input.val(value).trigger('change');
        }
      }
    });
  }

  restoreSettings() {
    var settings = this.getJson('rp-filter-settings');

    var hidden = settings['filter_hidden'];
    if (typeof hidden !== 'undefined') {
      if (hidden) {
        this.$container.removeClass('show');
      } else {
        this.$container.addClass('show');
      }
    }

    var additionalVisible = settings['additional_criteria_visible'];
    if (typeof additionalVisible !== 'undefined') {
      if (additionalVisible) {
        this.$additional.addClass('show');
      } else {
        this.$additional.removeClass('show');
      }
    }
  }
}

function init($root) {
  $root.find(SELECTOR_FILTER_FORM).each(function() {
    const $element = $(this);
    const filter = new Filter($element);

    $element.data('rp-filter', filter);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-tabs:loaded', (event, data) => {
    init(data.$root);
  });
});
