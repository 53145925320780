import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';
import { createError, formatAttrSelector } from 'utils/functions';
import Validation from './validation';

const HANDLERS = {
  'data-help-action': 'click',
  'data-change-action': 'change'
};

export default class FormUpdate {
  constructor($element, url) {
    this.$form = $element.form();
    this.url = url;
  }

  handle() {
    const data = new FormData(this.$form.get(0));

    Fetch.post(this.url, data)
      .then(response => this.handleResponse(response))
      .catch(error => Log.error(error));
  }

  handleResponse(response) {
    if (response.ok) {
      this.handleUpdate(response.body['values']);
    } else {
      if (response.body['validations']) {
        Validation.setErrors(this.$form, response.body['validations']);
      } else {
        throw createError('Form update request failed', {
          source: 'FormUpdate',
          response: response
        });
      }
    }
  }

  handleUpdate(values, triggerChange = true) {
    for (const [name, value] of Object.entries(values)) {
      const $input = this.$form.find(formatAttrSelector('name', name));
      if ($input.exists()) {
        let isChanged = false;

        if ($input.is(':checkbox,:radio')) {
          if ($input.is(':checked') !== !!value) {
            $input.prop('checked', value);
            isChanged = true;
          }
        } else {
          if ($input.val() !== value) {
            $input.val(value);
            isChanged = true;
          }
        }

        if (isChanged) {
          if (triggerChange) {
            $input.trigger('change');
          }
          $input.highlight();

          Validation.setError($input, null);
          Validation.validate($input);
        }
      }
    }
  }
}

$(() => {
  for (const [attr, event] of Object.entries(HANDLERS)) {
    $(document).on(event, formatAttrSelector(attr), event => {
      const $element = $(event.currentTarget);

      if (event.cancelable) {
        event.preventDefault();
      }
      new FormUpdate($element, $element.attr(attr)).handle();
    });
  }
});
