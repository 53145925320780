import $ from 'jquery';
import Numbers from '../core/numbers';
import { formatAttrSelector } from '../utils/functions';

const PREFIX_VAT_CALCULATOR = 'data-vat-calculator-';

const SELECTOR_FIELD_PRICE = PREFIX_VAT_CALCULATOR + 'price';
const SELECTOR_FIELD_PRICE_VAT = PREFIX_VAT_CALCULATOR + 'price-vat';
const SELECTOR_FIELD_PRICE_VAT_RATE = PREFIX_VAT_CALCULATOR + 'price-vat-rate';

const SELECTOR_BUTTON_PRICE = PREFIX_VAT_CALCULATOR + 'calculate-price';
const SELECTOR_BUTTON_PRICE_VAT = PREFIX_VAT_CALCULATOR + 'calculate-price-vat';
const SELECTOR_BUTTON_PRICE_VAT_RATE = PREFIX_VAT_CALCULATOR + 'calculate-price-vat-rate';

class PriceVatCalculator {
  constructor($price, $priceVat, $priceVatRate, $calculatePrice, $calculatePriceVat, $calculatePriceVatRate) {
    this.$price = $price;
    this.$priceVat = $priceVat;
    this.$priceVatRate = $priceVatRate;

    this.$calculatePrice = $calculatePrice;
    this.$calculatePriceVat = $calculatePriceVat;
    this.$calculatePriceVatRate = $calculatePriceVatRate;

    this.bindEvents();
    this.init();
  }

  init() {
    if (this.price && this.priceVat) {
      this.calculatePriceVatRate(false);
    }
  }

  bindEvents() {
    this.$price.on('change', () => this.calculatePriceVat());
    this.$priceVat.on('change', () => this.calculatePrice());

    this.$calculatePrice.on('click', event => {
      event.preventDefault();
      this.calculatePrice();
    });

    this.$calculatePriceVat.on('click', event => {
      event.preventDefault();
      this.calculatePriceVat();
    });

    this.$calculatePriceVatRate.on('click', event => {
      event.preventDefault();
      this.calculatePriceVatRate();
    });
  }

  calculatePrice(highlight = true) {
    this.handleDefaultVatRate(highlight);

    const priceVat = this.priceVat;
    const priceVatRate = this.priceVatRate;

    if (priceVat !== null && priceVatRate !== null) {
      const oldPrice = this.price;
      const newPrice = Numbers.round((priceVat * 100) / (priceVatRate + 100));

      this.price = newPrice;

      if (oldPrice !== newPrice) {
        this.$price.trigger('change');

        if (highlight) {
          this.$price.highlight();
        }
      }
    }
  }

  calculatePriceVat(highlight = true) {
    this.handleDefaultVatRate(highlight);

    const price = this.price;
    const priceVatRate = this.priceVatRate;

    if (price !== null && priceVatRate !== null) {
      const oldPriceVat = this.priceVat;
      const newPriceVat = Numbers.round((price * priceVatRate) / 100 + price);

      this.priceVat = newPriceVat;

      if (oldPriceVat !== newPriceVat) {
        this.$priceVat.trigger('change');

        if (highlight) {
          this.$priceVat.highlight();
        }
      }
    }
  }

  calculatePriceVatRate(highlight = true) {
    const price = this.price;
    const priceVat = this.priceVat;

    if (price !== null && priceVat !== null) {
      const oldPriceVatRate = this.priceVatRate;
      const newPriceVatRate = price !== 0 ? Numbers.round((priceVat - price) * 100 / price) : 0;

      this.priceVatRate = newPriceVatRate;

      if (oldPriceVatRate !== newPriceVatRate) {
        this.$priceVatRate.trigger('change');

        if (highlight) {
          this.$priceVatRate.highlight();
        }
      }
    }
  }

  handleDefaultVatRate(highlight = true) {
    if (this.priceVatRate) {
      return;
    }

    const defaultVatRate = this.defaultVatRate;
    if (defaultVatRate !== null && (this.price !== null || this.priceVat !== null)) {
      this.priceVatRate = defaultVatRate;

      if (highlight) {
        this.$priceVatRate.highlight();
      }
    }
  }

  get price() {
    return Numbers.parse(this.$price.val());
  }

  set price(value) {
    this.$price.val(Numbers.format(value));
  }

  get priceVat() {
    return Numbers.parse(this.$priceVat.val());
  }

  set priceVat(value) {
    this.$priceVat.val(Numbers.format(value));
  }

  get priceVatRate() {
    return Numbers.parse(this.$priceVatRate.val());
  }

  set priceVatRate(value) {
    this.$priceVatRate.val(Numbers.format(value));
  }

  get defaultVatRate() {
    return Numbers.parse(this.$priceVatRate.attr('data-vat-rate-default'));
  }
}

function init($root) {
  $root.find(formatAttrSelector(SELECTOR_FIELD_PRICE)).each(function() {
    const group = $(this).attr(SELECTOR_FIELD_PRICE);

    const $price = $root.find(formatAttrSelector(SELECTOR_FIELD_PRICE, group));
    const $priceVat = $root.find(formatAttrSelector(SELECTOR_FIELD_PRICE_VAT, group));
    const $priceVatRate = $root.find(formatAttrSelector(SELECTOR_FIELD_PRICE_VAT_RATE, group));

    const $calculatePrice = $root.find(formatAttrSelector(SELECTOR_BUTTON_PRICE, group));
    const $calculatePriceVat = $root.find(formatAttrSelector(SELECTOR_BUTTON_PRICE_VAT, group));
    const $calculatePriceVatRate = $root.find(formatAttrSelector(SELECTOR_BUTTON_PRICE_VAT_RATE, group));

    const $all = $price.add($priceVat)
      .add($priceVatRate).add($calculatePrice)
      .add($calculatePriceVat).add($calculatePriceVatRate);

    if ($all.length === 6) {
      new PriceVatCalculator($price, $priceVat, $priceVatRate,
        $calculatePrice, $calculatePriceVat, $calculatePriceVatRate);
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
