import { CSS_PX_PER_MINUTE, CSS_TOP_SHIFT } from './data';
import calculateShift from './overlap';
import { TaskCalendarCreatePopover, TaskCalendarUpdatePopover } from './popovers';

function template(event) {
  if (event.timeless) {
    return `
      <div class="rp-task-calendar-event rp-task-calendar-event-timeless ${event.cssClass}" style="${event.cssStyle}" data-event-id="${event.id}">
        <div class="rp-task-calendar-event-timeless-type">${event.typeName}</div>
        <div class="rp-task-calendar-event-timeless-customer">${event.customerNameAbbr}</div>
      </div>
    `;
  }

  return `
    <div class="rp-task-calendar-event rp-task-calendar-event-regular ${event.cssClass}" style="${event.cssStyle}" data-event-id="${event.id}">
      <div class="rp-task-calendar-event-regular-type">${event.typeName}</div>
      <div class="rp-task-calendar-event-regular-footer">
          <div class="rp-task-calendar-event-regular-time">${event.duration}</div>
          <div class="rp-task-calendar-event-regular-customer">${event.customerNameAbbr}</div>
      </div>
    </div>
  `;
}

function templateNew(timeSlot, top) {
  return `<div class="rp-task-calendar-event-new" style="top:${top}px" data-event-time-slot="${timeSlot}">`;
}

const MONDAY = 1;
const SUNDAY = 7;

const SCROLL_PADDING = CSS_PX_PER_MINUTE * 10;
const SCROLL_POSITION_DEFAULT = CSS_PX_PER_MINUTE * 60 * 8;

const SLOT_SIZE = 15;

const SELECTOR_EVENTS_REGULAR = '.rp-task-calendar-events-regular .rp-task-calendar-week';
const SELECTOR_EVENTS_TIMELESS = '.rp-task-calendar-events-timeless .rp-task-calendar-week';
const SELECTOR_EVENT_REGULAR = '.rp-task-calendar-event-regular';
const SELECTOR_EVENT_TIMELESS = '.rp-task-calendar-event-timeless';
const SELECTOR_DAY = '.rp-task-calendar-day';
const SELECTOR_DAY_PREFIX = '.rp-task-calendar-day-';

export default class TaskCalendarEvents {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.$scroller = $element;
    this.$regularEvents = $element.find(SELECTOR_EVENTS_REGULAR);
    this.$timelessEvents = $element.find(SELECTOR_EVENTS_TIMELESS);

    this.allowNew = true;

    this.bindEvents();
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.observable.on('events-change', () => this.updateEvents());

      this.observable.on('popover-loaded', () => {
        this.allowNew = false;
        this.$scroller.addClass('stop-scroll');
        this.$timelessEvents.find(SELECTOR_DAY).addClass('stop-scroll');
      });

      this.observable.on('popover-closed', () => {
        this.allowNew = true;
        this.$scroller.removeClass('stop-scroll');
        this.$timelessEvents.find(SELECTOR_DAY).removeClass('stop-scroll');
      });

      this.$regularEvents.on('click', SELECTOR_EVENT_REGULAR, event => {
        new TaskCalendarUpdatePopover($(event.currentTarget), this.observable, this.state);
      });

      this.$timelessEvents.on('click', SELECTOR_EVENT_TIMELESS, event => {
        new TaskCalendarUpdatePopover($(event.currentTarget), this.observable, this.state);
      });

      this.$regularEvents.on('click', SELECTOR_DAY, event => {
        const $day = $(event.target);
        if (this.allowNew && $day.is(SELECTOR_DAY)) {
          this.createNewEvent($day, event.offsetY);
        }
      });
    });
  }

  createNewEvent($day, offset) {
    const minutes = (offset - CSS_TOP_SHIFT) / CSS_PX_PER_MINUTE;
    const hour = Math.floor(minutes / 60);
    const minute = Math.floor(minutes % 60 / SLOT_SIZE) * SLOT_SIZE;

    const timeSlot = this.state.getTimeSlot($day.attr('data-day'), hour, minute);
    const top = (hour * 60 + minute) * CSS_PX_PER_MINUTE + CSS_TOP_SHIFT;

    const $new = $(templateNew(timeSlot, top));
    $day.append($new);

    new TaskCalendarCreatePopover($new, this.observable, this.state);
  }

  updateEvents() {
    this.update(this.state.getRegularEventsMap(), this.$regularEvents, true);
    this.update(this.state.getTimelessEventsMap(), this.$timelessEvents, false);

    this.updateScroll();
  }

  update(eventsMap, $events, shift) {
    for (let i = MONDAY; i <= SUNDAY; i++) {
      const key = this.state.getWeekdayKey(i);
      const events = eventsMap[key] || [];

      if (shift) {
        calculateShift(events);
      }

      const $day = $events.find(SELECTOR_DAY_PREFIX + i).empty();

      events.forEach(event => {
        $day.append($(template(event)));
      });
    }

    $events.trigger('rp-content:loaded');
  }

  updateScroll() {
    const events = this.state.getRegularEvents();
    if (events.length > 0) {
      const topmostEvent = events.reduce((e1, e2) => e1.cssTop > e2.cssTop ? e2 : e1);

      this.$scroller.get(0).scrollTop = topmostEvent.cssTop - SCROLL_PADDING;
    } else {
      this.$scroller.get(0).scrollTop = SCROLL_POSITION_DEFAULT;
    }
  }
}
