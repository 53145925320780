<script setup>
import I18N from 'core/i18n';

const body = defineModel();

</script>

<template>
  <div class="rpvue-ActivityStreamEmailDraftBody">
    <div class="rpvue-ActivityStreamEmailDraftBody-editor">
      <textarea class="rpvue-u-TypoLabel rpvue-u-TypoLabel--large rp-scrollbar"
                :placeholder="I18N.t('email_draft_placeholder_body')"
                v-model="body">
      </textarea>
    </div>
  </div>
</template>
