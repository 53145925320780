import $ from 'jquery';
import RequiredInputGroup from '../../components/requiredInputGroup';
import { copyToClipboard } from '../../utils/functions';

class EquivalentAreaConfigModal {
  constructor($root) {
    this.$modal = $root;

    new RequiredInputGroup($root, 'ea-config');

    this.bindEvents();
  }

  bindEvents() {
    this.$modal.on('click', 'kbd', (event) => copyToClipboard($(event.currentTarget).text()))
  }
}

$(() => {
  $(document).on('rp-partial:loaded', (event, data) => {
    if (data.$root.is("#equivalent-area-config-modal")) {
      new EquivalentAreaConfigModal(data.$root);
    }
  });
});
