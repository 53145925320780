import $ from 'jquery';
import RequiredInputGroup from '../../components/requiredInputGroup';
import DuplicateCustomerCheck from '../customer/duplicateCustomerCheck';

class CreateSalesStatusPrereservationModalForm {
  constructor($modal) {
    this.$sectionCustomerExisting = $modal.find('#section-customer-existing');
    this.$sectionCustomerNew = $modal.find('#section-customer-new');
    this.$customerMode = $modal.find('[name="customer-mode"]');

    this.bindEvents();
  }

  bindEvents() {
    this.$customerMode.on('change', () => {
      const newCustomer = this.$customerMode.filter(':checked').val() === 'customer-mode-new';
      this.$sectionCustomerExisting.hidden(newCustomer).disabled(newCustomer);
      this.$sectionCustomerNew.hidden(!newCustomer).disabled(!newCustomer);
    }).trigger('change');
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#create-sales-status-prereservation-modal-form')) {
      new CreateSalesStatusPrereservationModalForm(data.$root);
      new DuplicateCustomerCheck(data.$root);
      new RequiredInputGroup(data.$root, 'contact');
    }
  });
});