import $ from 'jquery';

class Preference {

  constructor($form) {
    this.$form = $form;
    this.$showProductsButton = $('#show-products');
    this.$productsTable = $('#CustomerPreferenceProductsTable');

    this.$showProductsButton.on('click', () => {
      this.showProducts();
    });
  }

  showProducts() {
    const params = this.$form.find(':input:not(.rp-not-filter)').serializeObject();
    this.$productsTable.trigger('rp-table:load', params);
  }
}

$(() => {
  const $customerPreferenceForm = $('#customer-preference-form');
  if ($customerPreferenceForm.exists()) {
    let preference = new Preference($customerPreferenceForm);
    if ($customerPreferenceForm.get(0).hasAttribute('data-form-show-products')) {
      preference.showProducts();
    }
  }
});