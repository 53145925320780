import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import ResponseCommand from '../components/responseCommand';

class SsoAlert {
  constructor($alert, $link) {
    this.$alert = $alert;
    this.$link = $link;

    this.bindEvents();
  }

  bindEvents() {
    this.$link.on('click', () => this.closeAlert());
  }

  closeAlert() {
    this.$alert.trigger('rp-alert:close');
  }
}

class SsoModal {
  constructor($modal) {
    this.$modal = $modal;

    this.bindEvents();
  }

  bindEvents() {
    this.$modal.on('rp-modal:closed', () => this.hideAlert());
  }

  hideAlert() {
    Fetch.post(Router.get('API_SSO_MICROSOFT_DISABLE_ADVERT'))
      .then(response => new ResponseCommand(response).handle())
      .catch(error => Log.error(error));
  }
}

$(()=> {
  const $ssoLinkOpen = $('#sso-link-open');
  const $ssoAlert = $ssoLinkOpen.closest('.rp-alert');
  if ($ssoLinkOpen.exists() && $ssoAlert.exists()) {
    new SsoAlert($ssoAlert, $ssoLinkOpen);
  }

  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#sso-modal")) {
      new SsoModal(data.$root);
    }
  });
});