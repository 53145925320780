import { getCurrentHub } from '@sentry/browser';
import { createError } from 'utils/functions';

/**
 * Function that checks if {@link error} should be logged to Sentry.
 *
 * Any checks inside should be as explicit as possible to avoid suppressing something useful:
 * - Check {@link error} type if suppressed {@link error} has specific type
 * - Prefer to use "equals" instead of "starts with" / "contains" when checking {@link error} message if possible
 */
function shouldLogError(error) {
  // suppress error caused by Fetch API being unable to make HTTP request (very likely due to connectivity problems)
  if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
    return false;
  }

  return true;
}

export default class Log {
  static error(message, properties = {}) {
    const error = createError(message, properties);

    const hub = getCurrentHub();
    if (hub.getClient().getDsn()) {
      if (shouldLogError(error)) {
        hub.captureException(error);
      }
    } else {
      console.error(error)
    }
  }

  static warn(message) {
    const hub = getCurrentHub();
    if (hub.getClient().getDsn()) {
      hub.captureMessage(message, 'warning');
    } else {
      console.warn(message)
    }
  }
}
