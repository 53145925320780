<script setup>
import { HoverActions, IconButton, IconButtonSize, Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { computed } from 'vue';
import { TaskItem } from './Types';
import { useClickActions } from '../../useClickActions';
import { createAction } from './utils';

const props = defineProps({
  item: {
    type: TaskItem,
    required: true
  }
});

const { clickAction, disabled } = useClickActions();

const actionsData = {
  taskCompleteAction: {
    label: 'crm_task_table_action_complete_tooltip',
    icon: 'check_circle',
    clickHandler: (url) => clickAction('modal', url)
  },
  taskCancelAction: {
    label: 'crm_task_table_action_cancel_tooltip',
    icon: 'cancel',
    clickHandler: (url) => clickAction('modal', url)
  },
  taskReopenAction: {
    label: 'crm_task_table_action_reopen_tooltip',
    icon: 'undo',
    clickHandler: (url) => clickAction('request', url, {
      level: 'error',
      message: I18N.t('crm_task_modal_reopen_text'),
      confirmLabel: I18N.t('crm_task_modal_reopen_button')
    })
  },
  taskUpdateAction: {
    label: 'table_actions_update',
    icon: 'edit',
    clickHandler: (url) => clickAction('modal', url)
  },
  taskDeleteAction: {
    label: 'table_actions_delete',
    icon: 'delete',
    clickHandler: (url) => clickAction('request', url, {
      level: 'error',
      message: I18N.t('delete_modal_text'),
      confirmLabel: I18N.t('delete')
    })
  }
};

const actions = computed(() => {
  const ret = [];

  for (const [name, data] of Object.entries(actionsData)) {
    if (props.item[name]) {
      ret.push(createAction(props.item[name], data));
    }
  }

  return ret;
});
</script>

<template>
  <!-- TODO rework while moving code to FE lib/app -->
  <HoverActions>
    <div class="rpvue-HoverActions-item" v-for="action in actions">
      <IconButton :icon="action.icon"
                  :size="IconButtonSize.SM"
                  :disabled="action.disabled || disabled"
                  @click="action.onClick">
        <template v-slot:tooltip>
          <Tooltip>{{ action.reason ?? action.label }}</Tooltip>
        </template>
      </IconButton>
    </div>
  </HoverActions>
</template>
