import $ from 'jquery';
import { createError, formatAttrSelector } from '../utils/functions';
import Fetch from '../core/fetch';
import Confirmation from './confirmation';
import FormSubmit from './formSubmit';
import { ATTR_CLICK_ACTION } from './clickAction';

const TEMPLATE = `
  <div class="popover" role="tooltip">
    <div class="popover-body"></div>
  </div>
`;

const TEMPLATE_ARROW = `
  <div class="popover" role="tooltip">
    <div class="arrow"></div>
    <div class="popover-body"></div>
  </div>
`;

export default class Popover {
  constructor($element, url) {
    this.$element = $element;
    this.url = url;
  }

  open() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      Fetch.getHtml(this.url)
        .then(response => {
          if (response.ok) {
            this.create(response.body);
          } else {
            this.reject(createError('Failed to load popover content', {
              source: 'Popover',
              response: response
            }));
          }
        })
        .catch(reason => this.reject(reason));
    });
  }

  create(content) {
    const $content = $(content);

    const placement = $content.attr('data-placement');
    const placementSimple = placement.split('-')[0];
    const arrow = $content.attr('data-arrow') === 'true';

    this.$element.popover({
      container: 'body',
      placement: placementSimple,
      trigger: 'manual',
      html: true,
      template: arrow ? TEMPLATE_ARROW : TEMPLATE,
      content: () => $content,
      customClass: 'popover-form',
      popperConfig: {
        placement: placement,
        modifiers: {
          arrow: {
            enabled: arrow,
            element: '.arrow'
          },
          computeStyle: {
            gpuAcceleration: false
          },
          flip: {
            behavior: 'flip'
          },
          preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }
    });

    this.bindEvents($content);

    this.$element.popover('show');

    this.$element.trigger('rp-partial:loaded', {
      $root: $content
    });

    this.$element.trigger('rp-popover:loaded', {
      $root: $content
    });
  }

  bindEvents($content) {
    this.$element.one('shown.bs.popover', () => {
      $(document).on('click.popover-form', event => {
        const $target = $(event.target);
        if ($target.is(this.$element)) {
          return;
        }

        if ($target.closest('.popover').exists()) {
          if ($target.closest('[data-dismiss="popover"]').exists()) {
            this.$element.popover('hide');
          }

          const modalClickAction = formatAttrSelector(ATTR_CLICK_ACTION, 'modal');
          if ($target.is(modalClickAction) || $target.closest(modalClickAction).exists()) {
            this.$element.popover('hide');
          }

          return;
        }

        // keep popover open on clicks within date picker or select dropdown
        if ($target.closest('.flatpickr-calendar,.select2-container').exists()) {
          return;
        }

        // close popover only on clicks on elements that are still in DOM
        // (Vue select dropdown is removed from DOM before event bubbles and we handle it here)
        if ($.contains(document, event.target)) {
          this.$element.popover('hide');
        }
      });

      $(document).one('show.bs.dropdown rp-content:reload', () => {
        this.$element.popover('hide');
      });

      this.$element.one('hide.bs.popover', () => {
        $(document).off('click.popover-form');

        this.$element.trigger('rp-popover:closed');
      });
    });

    this.$element.one('hidden.bs.popover', () => this.dispose());

    $content.on('submit', event => {
      new FormSubmit(event).handle()
        .then(response => {
          this.$element.popover('hide');
          this.resolve(response);
        })
        .catch(reason => {
          if (reason.invalid) {
            $content.trigger('rp-submit:invalid', {
              response: reason.response
            });
          } else {
            this.reject(reason);
          }
        });
    });

    $content.on('click', ':submit', event => {
      event.preventDefault();

      const $button = $(event.currentTarget);
      Confirmation.create($button).confirm().then(() => {
        $button.attr('data-clicked', 'true');
        $button.form().trigger('submit');
      });
    });
  }

  dispose() {
    this.resolve({
      ok: false,
      body: {}
    });

    this.$element.popover('dispose');
  }
}
