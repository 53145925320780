<script setup>
import I18N from 'core/i18n';
import { useClickActions } from '../useClickActions';
import UserMenuItem from './UserMenuItem.vue';
import { ProfileDetails } from './userMenuTypes';

const props = defineProps({
  profileDetails: {
    type: ProfileDetails,
    required: true
  }
});

const { clickAction, disabled } = useClickActions();

const onProfileClick = () => clickAction('request', props.profileDetails.switchUrl, {
  message: I18N.t('user_menu_profile_switch_confirmation'),
  confirmLabel: I18N.t('user_menu_profile_switch')
});

</script>

<template>
  <div class="rpvue-UserMenuProfilesListItem">
    <UserMenuItem :item="profileDetails.details" :disabled="disabled" @itemClick="onProfileClick"/>
  </div>
</template>