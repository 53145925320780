import $ from 'jquery';
import { formatAttrSelector } from 'utils/functions';

class DealUnitsSourceWarning {
  constructor($form) {
    this.$form = $form;

    this.$products = this.$form.find('.deal-units-source-warning-products-select');
    this.$alerts = this.$form.find('.modal-footer-alerts');
    this.$mainCtaButton = this.$form.find('.deal-units-source-warning-main-cta-button');
    this.$confirmButton = this.$form.find('.deal-units-source-warning-confirm-button');

    this.toggleAlert(false);
    this.bindEvents();
  }

  bindEvents() {
    this.$form.on('rp-submit:invalid', (event, data) => this.handleWarning(data));
    this.$products.on('change', () => this.toggleWarning());
  }

  handleWarning(data) {
    const displayWarning = data?.response?.body?.['additional_data']?.['displayWarning'] === true;
    this.toggleWarning(displayWarning);
  }

  toggleWarning(display = false) {
    this.$mainCtaButton.hidden(display);
    this.$confirmButton.hidden(!display);
    this.toggleAlert(display);
  }

  toggleAlert(display = false) {
    if (display) {
      if (this.$alert != null) {
        this.$alerts.append(this.$alert);
      }
      this.$alert = null;
    } else {
      if (this.$alert == null) {
        this.$alert = this.$alerts
          .find(formatAttrSelector('data-alert-id', 'deal-units-source-warning-alert'))
          .detach();
      }
    }
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.find('.deal-units-source-warning-form').exists()) {
      new DealUnitsSourceWarning(data.$root);
    }
  })
})
