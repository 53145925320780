<script setup>
import { Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import Dates from 'core/dates';
import I18N from 'core/i18n';
import { computed } from 'vue';
import ActivityStreamTimestamp from './ActivityStreamTimestamp.vue';
import { TaskItem } from './Types';

const props = defineProps({
  item: {
    type: TaskItem,
    required: true
  }
});

const created = computed(() => Dates.format(props.item?.timestamp, Dates.FORMAT_DATE_TIME));
const deadline = computed(() => Dates.format(props.item?.taskDeadline, Dates.FORMAT_DATE_TIME));
const completed = computed(() => Dates.format(props.item?.taskCompleted, Dates.FORMAT_DATE_TIME));

</script>

<template>
  <span class="rpvue-ActivityStreamTimestampTask" v-if="completed">
    <span class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
      {{ I18N.t('crm_task_param_finished') }}: {{ completed }}
    </span>
    <Tooltip>
      <div>{{ I18N.t('created') }}: {{ created }}</div>
      <div v-if="deadline">{{ I18N.t('deadline') }}: {{ deadline }}</div>
    </Tooltip>
  </span>
  <span class="rpvue-ActivityStreamTimestampTask" v-else-if="deadline">
    <span class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
      {{ I18N.t('deadline') }}: {{ deadline }}
    </span>
    <Tooltip>
      {{ I18N.t('created') }}: {{ created }}
    </Tooltip>
  </span>
  <ActivityStreamTimestamp v-else :timestamp="item.timestamp"/>
</template>
