import $ from 'jquery';
import Viewer from 'viewerjs';

const SELECTOR_IMAGE_VIEWER = '.rp-image-viewer';

Viewer.setDefaults({
  url: 'data-original',
  navbar: false,
  toolbar: {
    zoomIn: 2,
    zoomOut: 2,
    oneToOne: 2,
    reset: 2,
    prev: {
      show: 1,
      size: 'large',
    },
    play: 0,
    next: {
      show: 1,
      size: 'large',
    },
    rotateLeft: 2,
    rotateRight: 2,
    flipHorizontal: 2,
    flipVertical: 2,
  },
});

function init($root) {
  $root.find(SELECTOR_IMAGE_VIEWER).each(function() {
    const $element = $(this);
    const viewer = new Viewer($element.get(0));

    if ($element.is('.rp-table')) {
      $element.on('rp-table:loaded', () => viewer.update());
    }

    $element.data('rp-image-viewer', viewer);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
