import Fetch from 'core/fetch';
import Log from 'core/log';
import Numbers from 'core/numbers';
import Router from 'core/router';

const NOTARY_ESCROW = 2;
const BANK_ESCROW = 3;
const MORTGAGE = 4;

export default class ThreeFpcPayments {
  constructor($form) {
    this.dealId = $form.attr('data-deal-id');
    this.rfAmount = Numbers.parse($form.attr('data-rf-amount'));
    this.firstPaymentContainsReservation = $form.attr('data-first-payment-contains-reservation') === 'true';
    this.discountedPriceVat = $form.attr('data-discounted-price-vat');

    this.$firstPaymentAmount = $form.find('#first-payment-amount');
    this.$firstPaymentPercent = $form.find('#first-payment-percent');

    this.$secondPaymentAmount = $form.find('#second-payment-amount');
    this.$secondPaymentPercent = $form.find('#second-payment-percent');

    this.$thirdPaymentAmount = $form.find('#third-payment-amount');
    this.$thirdPaymentPercent = $form.find('#third-payment-percent');

    this.$additionalPaymentAmount = $form.find('#additional-payment-amount');

    this.$firstPaymentMethod = $form.find('#first-payment-method');
    this.$secondPaymentMethod = $form.find('#second-payment-method');
    this.$thirdPaymentMethod = $form.find('#third-payment-method');
    this.$additionalPaymentMethod = $form.find('#additional-payment-method');

    this.$notaryEscrowDeadline = $form.find('#notary-escrow-deadline');
    this.$bankEscrowDeadline = $form.find('#bank-escrow-deadline');
    this.$mortgageDeadline = $form.find('#mortgage-deadline');

    this.bindEvent();
    this.init();
  }

  bindEvent() {
    this.$firstPaymentAmount
      .add(this.$secondPaymentAmount)
      .add(this.$thirdPaymentAmount)
      .on('change', () => {
        this.updateAdditional();
      });

    this.$firstPaymentMethod
      .add(this.$secondPaymentMethod)
      .add(this.$thirdPaymentMethod)
      .add(this.$additionalPaymentMethod)
      .on('change', () => {
        this.togglePaymentMethodFields();
      })
  }

  togglePaymentMethodFields() {
    const isNotaryEscrow = this.firstPaymentMethod === NOTARY_ESCROW
      || this.secondPaymentMethod === NOTARY_ESCROW
      || this.thirdPaymentMethod === NOTARY_ESCROW
      || this.additionalPaymentMethod === NOTARY_ESCROW;

    const isBankEscrow = this.firstPaymentMethod === BANK_ESCROW
      || this.secondPaymentMethod === BANK_ESCROW
      || this.thirdPaymentMethod === BANK_ESCROW
      || this.additionalPaymentMethod === BANK_ESCROW;

    const isMortgage = this.firstPaymentMethod === MORTGAGE
      || this.secondPaymentMethod === MORTGAGE
      || this.thirdPaymentMethod === MORTGAGE
      || this.additionalPaymentMethod === MORTGAGE;

    this.toggleField(this.$notaryEscrowDeadline, !isNotaryEscrow);
    this.toggleField(this.$bankEscrowDeadline, !isBankEscrow);
    this.toggleField(this.$mortgageDeadline, !isMortgage);
  }

  init() {
    this.setUpPercentField();
    this.updateAdditional();
    this.togglePaymentMethodFields();
  }

  get firstPaymentAmount() {
    return Numbers.parse(this.$firstPaymentAmount.val());
  }

  get secondPaymentAmount() {
    return Numbers.parse(this.$secondPaymentAmount.val());
  }

  get thirdPaymentAmount() {
    return Numbers.parse(this.$thirdPaymentAmount.val());
  }

  get firstPaymentPercent() {
    return Numbers.parse(this.$firstPaymentPercent.val());
  }

  get secondPaymentPercent() {
    return Numbers.parse(this.$secondPaymentPercent.val());
  }

  get thirdPaymentPercent() {
    return Numbers.parse(this.$thirdPaymentPercent.val());
  }

  get firstPaymentMethod() {
    return Numbers.parse(this.$firstPaymentMethod.val());
  }

  get secondPaymentMethod() {
    return Numbers.parse(this.$secondPaymentMethod.val());
  }

  get thirdPaymentMethod() {
    return Numbers.parse(this.$thirdPaymentMethod.val());
  }

  get additionalPaymentMethod() {
    return Numbers.parse(this.$additionalPaymentMethod.val());
  }

  get discountedPrice() {
    return Numbers.parse(this.discountedPriceVat);
  }

  toggleField($field, disabled) {
    const wasDisabled = $field.prop('disabled');
    $field.disabled(disabled);
    $field.closest('.form-group').hidden(disabled);
    if (wasDisabled && !disabled) {
      $field.highlight();
    }
  }

  setUpPercentField() {
    this.$firstPaymentAmount.on('change', () => {
      this.calculatePercent(this.$firstPaymentPercent, this.firstPaymentAmount, true);
    });
    this.$firstPaymentPercent.on('change', () => {
      this.calculateAmount(this.$firstPaymentAmount, this.firstPaymentPercent, true);
    });

    this.$secondPaymentAmount.on('change', () => {
      this.calculatePercent(this.$secondPaymentPercent, this.secondPaymentAmount, false);
    });
    this.$secondPaymentPercent.on('change', () => {
      this.calculateAmount(this.$secondPaymentAmount, this.secondPaymentPercent, false);
    });

    this.$thirdPaymentAmount.on('change', () => {
      this.calculatePercent(this.$thirdPaymentPercent, this.thirdPaymentAmount, false);
    });
    this.$thirdPaymentPercent.on('change', () => {
      this.calculateAmount(this.$thirdPaymentAmount, this.thirdPaymentPercent, false);
    });
  };

  calculatePercent($percent, amount, isFirstPayment) {
    const discountedPrice = this.discountedPrice;

    let percent;
    if (this.firstPaymentContainsReservation) {
      if (isFirstPayment) {
        percent = (amount + this.rfAmount) / discountedPrice * 100;
      } else {
        percent = amount / discountedPrice * 100;
      }
    } else {
      percent = amount / (discountedPrice - this.rfAmount) * 100;
    }

    $percent.val(Numbers.format(percent)).highlight();
  }

  calculateAmount($amount, percent, isFirstPayment) {
    const discountedPrice = this.discountedPrice;

    let amount;
    if (this.firstPaymentContainsReservation) {
      if (isFirstPayment) {
        amount = (discountedPrice * percent) / 100 - this.rfAmount;
      } else {
        amount = (discountedPrice * percent) / 100;
      }
    } else {
      amount = (discountedPrice - this.rfAmount) * percent / 100;
    }

    $amount.val(Numbers.format(amount)).trigger('change').highlight();
  }

  updateAdditional() {
    const body = {
      'DealId': this.dealId,
      'DiscountedPriceVat': this.discountedPriceVat,
      'Payment1Amount': this.$firstPaymentAmount.val(),
      'Payment2Amount': this.$secondPaymentAmount.val(),
      'Payment3Amount': this.$thirdPaymentAmount.val()
    }

    Fetch.post(Router.get('API_GET_ADDITIONAL_PAYMENT'), body).then(response => {
      if (response.ok) {
        this.$additionalPaymentAmount.val(Numbers.format(response.body['AdditionalPaymentAmount']))
          .trigger('change').highlight();
      }
    }).catch(error => Log.error(error));
  }

}
