import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';
import { formatAttrSelector } from 'utils/functions';
import { ATTR_CLICK_ACTION_URL } from '../clickAction';
import { ATTR_INTERACTIVE_ELEMENT, ATTR_INTERACTIVE_ELEMENT_URL } from '../interactiveElement';
import Actions from './actions';
import Filter from './filter';
import InteractiveRow from './interactiveRow';
import Pagination from './pagination';
import ResizableColumns from './resizableColumns';
import Search from './search';
import Selection from './selection';
import Sorting from './sorting';
import State from './state';
import Status from './status';
import StickyHeader from './stickyHeader';

export default class Table {
  constructor($element) {
    this.$element = $element;

    this.id = $element.attr('id');
    this.url = $element.attr('data-table-url');

    this.$loading = $element.find('.rp-table-loading-panel');

    this.$table = $element.find('table:not(.rp-table-sticky-header)');
    this.$tbody = this.$table.find('tbody');
    this.$template = this.$tbody.find('.rp-row.template');
    this.$empty = this.$tbody.find('.rp-row.empty');
    this.$error = this.$tbody.find('.rp-row.error');

    this.status = new Status(this);
    this.actions = new Actions(this);
    this.interativeRow = new InteractiveRow(this);

    if (this.$element.find('.rp-table-pagination').exists()) {
      this.pagination = new Pagination(this);
    }

    if (this.$table.find('.rp-row-select-all').exists()) {
      this.selection = new Selection(this);
    }

    const sorting = this.$element.attr('data-table-sorting');
    if (sorting) {
      this.sorting = new Sorting(this, sorting);
    }

    const tableId = this.$element.attr('data-table-id');
    if (tableId) {
      this.state = new State(this, tableId);
    }

    const filterId = this.$element.attr('data-table-filter-id');
    if (filterId) {
      this.filter = new Filter(this, filterId);
    }

    if (this.$element.find('.rp-table-search').exists()) {
      this.search = new Search(this);
    }

    this.resizableColumns = new ResizableColumns(this);

    if (this.$element.find('.rp-table-sticky-header').exists()) {
      this.stickyHeader = new StickyHeader(this);
    }

    this.bindEvents();

    if (this.$element.attr('data-table-auto-load')) {
      this.load();
    }
  }

  bindEvents() {
    this.$element.on('rp-table:load', (event, data) => {
      this.customParams = data;
      this.load(true);
    });
  }

  load(resetPage) {
    this.$loading.hidden(false);

    if (this.pagination && resetPage) {
      this.pagination.pageNumber = 1;
    }

    Fetch.post(this.url, this.params).then(response => {
      this.clearRows();
      this.rowCount = 0;

      if (response.ok) {
        if (this.pagination) {
          this.pagination.totalCount = response.body['total_count'];
        }

        if (this.selection) {
          this.selection.clear();
        }

        const $rows = [];
        const selection = [];

        if (response.body.rows) {
          this.rowCount = response.body.rows.length;

          response.body.rows.forEach(row => {
            $rows.push(this.createRow(row));

            const selectable = typeof row.selectable === 'undefined' || row.selectable;
            if (selectable && row.selected) {
              selection.push(row.id);
            }
          });
        }

        if (this.rowCount > 0 && response.body['aggregated']) {
          $rows.push(this.createAggregatedRow(response.body['aggregated']));
        }

        this.$tbody.prepend($rows);

        this.interativeRow.updateState();

        if (this.selection) {
          this.selection.selection = selection;
        }

        this.$empty.hidden(this.rowCount > 0);
        this.$error.hidden();

        this.$element.trigger('rp-table:loaded', response.body.data);

        if (this.state) {
          this.state.save();
        }
      } else {
        this.$empty.hidden();
        this.$error.hidden(false);
      }

      this.status.update();
      this.actions.update();
      this.$loading.hidden();
    }).catch(error => Log.error(error));
  }

  get customParams() {
    return this._customParams;
  }

  set customParams(value) {
    this._customParams = value || {};
  }

  get params() {
    const params = new FormData();

    if (this.pagination) {
      params.set('offset', this.pagination.offset);
      params.set('limit', this.pagination.limit);
    }

    if (this.sorting) {
      params.set('sorting', this.sorting.toString());
    }

    if (this.filter) {
      for (const [name, value] of Object.entries(this.filter.values)) {
        params.append(name, String(value));
      }
    }

    if (this.search) {
      params.set('filter_fulltext', this.search.value);
    }

    if (this.customParams) {
      for (const [name, value] of Object.entries(this.customParams)) {
        params.append(name, String(value));
      }
    }

    return params;
  }

  getTableState() {
    const data = {};

    if (this.pagination) {
      data['page_number'] = String(this.pagination.pageNumber);
      data['page_size'] = String(this.pagination.pageSize);
    }

    if (this.sorting) {
      data['table_sorting'] = this.sorting.toString();
    }

    if (this.search) {
      data['filter_fulltext'] = this.search.value;
    }

    return data;
  }

  clearRows() {
    this.$tbody.find('.rp-row:not(.template,.empty,.error)').remove();
  }

  newRow() {
    return this.$template.clone().hidden(false).removeClass('template');
  }

  createRow(row) {
    const $row = this.newRow();

    $row.attr('data-record-id', row.id);

    this.fillRow($row, row);

    if (row.actions) {
      for (const [action, state] of Object.entries(row.actions)) {
        if (state === 'hidden') {
          $row.find('[data-action-id="' + action + '"]').remove();
        }
        if (state === 'disabled') {
          $row.find('[data-action-id="' + action + '"]').disabled();
        }
      }

      if ($row.find('.rp-actions .dropdown-menu').is(':empty')) {
        $row.find('.rp-actions .dropdown').remove();
      }
    }

    const $select = $row.find('.rp-row-select');
    if ($select.exists()) {
      const $input = $select.find('input');
      const $label = $select.find('label');
      const $checkbox = $select.find('.custom-control');
      const $disabledTooltip = $select.find('.custom-control-disabled-tooltip');

      $input.attr('id', $input.attr('id') + '-' + row.id);
      $label.attr('for', $label.attr('for') + '-' + row.id);

      const selectable = typeof row.selectable === 'undefined' || row.selectable;
      if (!selectable) {
        $checkbox.disabled().addClass('disabled');
      }

      if (row.selectTooltip) {
        $checkbox.append(`<rp-tooltip>${row.selectTooltip}</rp-tooltip>`);
        $disabledTooltip.append(`<rp-tooltip>${row.selectTooltip}</rp-tooltip>`);
      }
    }

    const $toggle = $row.find('.rp-actions .dropdown .dropdown-toggle');
    if ($toggle.exists()) {
      $toggle.attr('data-boundary', 'scrollParent');
      $toggle.removeAttr('data-offset');
    }

    return $row;
  }

  createAggregatedRow(row) {
    const $row = this.newRow();

    $row.addClass('aggregated');

    $row.find('.rp-row-select').empty();
    $row.find('.rp-actions').empty();

    $row.removeAttr(ATTR_INTERACTIVE_ELEMENT);
    $row.removeAttr(ATTR_INTERACTIVE_ELEMENT_URL);

    this.fillRow($row, row);

    return $row;
  }

  fillRow($row, row) {
    for (const [column, cell] of Object.entries(row.cells)) {
      const $cell = $row.find('.rp-cell[data-column-id=' + column + ']');

      if (cell.value) {
        $cell.html(cell.value);
      }

      if (cell.classes) {
        $cell.addClass(cell.classes);
      }
    }

    if (row.classes) {
      $row.addClass(row.classes)
    }

    this.updateActions($row, row);
  }

  updateActions($row, row) {
    if ($row.is(formatAttrSelector(ATTR_INTERACTIVE_ELEMENT))) {
      $row.attr(ATTR_INTERACTIVE_ELEMENT_URL, $row.attr(ATTR_INTERACTIVE_ELEMENT_URL).replace('$ID$', row.id));
    }

    $row.find('[data-action-id]').each(function() {
      const $action = $(this);

      if ($action.attr(ATTR_CLICK_ACTION_URL)) {
        $action.attr(ATTR_CLICK_ACTION_URL,
          $action.attr(ATTR_CLICK_ACTION_URL).replace('$ID$', row.id));
      }

      if ($action.attr('href')) {
        $action.attr('href',
          $action.attr('href').replace('$ID$', row.id));
      }
    });
  }
}
