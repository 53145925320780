export default class DealUnits {
  constructor($form) {
    this.contractKey = $form.attr('data-contract-key');

    this.$input = $form.find('[name=' + this.contractKey + '-units]');
    this.$table = $('#DealUnits-' + this.contractKey);

    if (this.$table.exists()) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.$table.on('rp-table:selection', (event, data) => {
      if (data.selection) {
        this.$input.val(data.selection.join());
      }
    });
  }
}
