import $ from 'jquery';
import Dates from '../../core/dates';
import Numbers from '../../core/numbers';

class CadastreContractData {
  constructor($form) {
    this.$form = $form;
    this.fieldIdPrefix = this.$form.attr('field-id-prefix');
    this.deadlineDays = Numbers.parse(this.$form.attr('cadastre-contract-deadline-days'));

    this.$pledgeCadastreSentDate = this.$form.find(this.formatSelector('submit-date'));
    this.$deadline = this.$form.find(this.formatSelector('deadline'));

    this.$proceedingsStoppedOn = this.$form.find(this.formatSelector('proceedings-stopped-on'));
    this.$proceedingsAppealDays = this.$form.find(this.formatSelector('proceedings-appeal-days'));
    this.$deadlineToAppeal = this.$form.find(this.formatSelector('deadline-to-appeal'));

    this.$proceedingsAppealWaived = this.$form.find(this.formatSelector('proceedings-appeal-waived'));
    this.$proceedingsAppealWaiverOn = this.$form.find(this.formatSelector('proceedings-appeal-waiver-on'));

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    this.$pledgeCadastreSentDate.on('change', () => this.updateDeadline());
    this.$proceedingsStoppedOn.add(this.$proceedingsAppealDays).on('change', () => this.updateDeadlineToAppeal());
    this.$proceedingsAppealWaived.on('change', () => this.updateWaiverOnRequired());

    /**
     * Workaround how to keep field disabled if it has paired inputs {@link PairedInput}
     */
    this.$deadlineToAppeal.on('rp-paired-input:change', () => this.$deadlineToAppeal.disabled());
  }

  init() {
    this.updateDeadline();
    this.updateDeadlineToAppeal();
    this.updateWaiverOnRequired();

    /**
     * Workaround how to keep field disabled if it has paired inputs {@link PairedInput}
     */
    this.$deadlineToAppeal.disabled();
  }

  updateDeadline() {
    const pledgeCadastreSentDate = Dates.parse(this.$pledgeCadastreSentDate.val());
    if (pledgeCadastreSentDate && this.deadlineDays !== null) {
      const deadline = Dates.format(pledgeCadastreSentDate.add(this.deadlineDays, 'days'));
      this.$deadline.val(deadline).highlight();
    }
  }

  updateDeadlineToAppeal() {
    const proceedingsStoppedOn = Dates.parse(this.$proceedingsStoppedOn.val());
    const proceedingsAppealDays = Numbers.parse(this.$proceedingsAppealDays.val());
    if (proceedingsStoppedOn && proceedingsAppealDays !== null) {
      const deadlineToAppeal = Dates.format(proceedingsStoppedOn.add(proceedingsAppealDays, 'days'));
      this.$deadlineToAppeal.val(deadlineToAppeal).highlight();
    }
  }

  updateWaiverOnRequired() {
    this.$proceedingsAppealWaiverOn.required(this.$proceedingsAppealWaived.is(':checked')).highlight();
  }

  formatSelector(suffix) {
    return '#' + this.fieldIdPrefix + suffix;
  }
}

$(() => {
  $(document).on('rp-tabs:loaded', (event, data) => {
    data.$root.find('#pledge-contract-form, #cadastre-contract-form').each(function() {
      const $form = $(this);
      if ($form.attr('has-cadastre-contact-data') === 'true') {
        new CadastreContractData($form);
      }
    });
  })
})