<script>
export default {
  inheritAttrs: false,
  props: {
    name: String
  }
}
</script>

<template>
  {{ name }}
</template>

<style lang="scss">
  :host {
    display: inline-block;

    font-family: "MaterialSymbolsRounded";
    font-weight: 400;
    font-style: normal;

    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: normal;

    white-space: nowrap;
    word-wrap: normal;

    text-transform: none;
    direction: ltr;

    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
</style>
