<script setup>
import { Dropdown } from '@realpadsoftware/realpad-crm-frontend-lib';
import { onMounted } from 'vue';
import UserMenuActiveProfile from './UserMenuActiveProfile.vue';
import UserMenuDetails from './UserMenuDetails.vue';
import { useMenuData } from './useMenuData';

const props = defineProps({
  url: {
    type: String,
    required: true
  }
});

const { activeProfile, details, loadMenuData } = useMenuData();

onMounted(() => loadMenuData(props.url));

</script>

<template>
  <div class="rpvue-UserMenu">
    <Dropdown>
      <template v-slot:activator="{ toggle }">
        <UserMenuActiveProfile :active-profile="activeProfile" @click="toggle"/>
      </template>
      <template v-slot:content>
        <UserMenuDetails :details="details"/>
      </template>
    </Dropdown>
  </div>
</template>