import AllowClear from 'select2/src/js/select2/selection/allowClear';
import MultipleSelection from 'select2/src/js/select2/selection/multiple';
import Search from 'select2/src/js/select2/selection/search';
import Utils from 'select2/src/js/select2/utils';

const originalDisplay = MultipleSelection.prototype.display;
const originalSearchRemoveChoice = Search.prototype.searchRemoveChoice;

MultipleSelection.prototype.display = function(data, container) {
  if (data.locked) {
    container.addClass('select2-selection__choice__locked');
  }

  return originalDisplay.call(this, data, container);
};

Search.prototype.searchRemoveChoice = function(decorated, item) {
  if (item.locked) {
    return;
  }

  originalSearchRemoveChoice.call(this, decorated, item);
};

AllowClear.prototype._handleClear = function(_, event) {
  if (this.isDisabled()) {
    return;
  }

  const $clear = this.$selection.find('.select2-selection__clear');
  if ($clear.length === 0) {
    return;
  }

  event.stopPropagation();

  const data = Utils.GetData($clear[0], 'data');

  const clearData = { data: data };
  this.trigger('clear', clearData);
  if (clearData.prevented) {
    return;
  }

  let cleared = true;
  for (let item of data) {
    if (item.locked) {
      cleared = false;
      continue;
    }

    const unselectData = { data: item };
    this.trigger('unselect', unselectData);
    if (unselectData.prevented) {
      cleared = false;
    }
  }

  if (cleared) {
    this.$element.val(this.placeholder.id);
  }
  this.$element.trigger('input').trigger('change');
};
