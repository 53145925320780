import $ from 'jquery';
import { formatAttrSelector, updateUrl } from '../utils/functions';

const ATTR_DATA_PASS_FILTER_TO_MODAL = 'data-pass-filter-to-modal';
const ATTR_DATA_FILTER_ID = 'data-filter-id';

class ModalFormPassFilterData {
  constructor($modalForm, filterId) {
    this.$modalForm = $modalForm;
    this.filterId = filterId;

    this.handleFilterParams();
  }

  handleFilterParams() {
    const $filter = $('.rp-filter' + formatAttrSelector(ATTR_DATA_FILTER_ID, this.filterId));
    if ($filter.exists()) {
      const filter = $filter.data('rp-filter');
      if (filter) {
        const params = filter.getValues();
        if (params) {
          this.$modalForm.attr('action', updateUrl(this.$modalForm.attr('action'), params));
        }
      }
    }
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    const $modalForm = data.$root.find('form');
    if ($modalForm.exists()) {
      const filterId = $modalForm.attr(ATTR_DATA_PASS_FILTER_TO_MODAL);
      if (filterId) {
        new ModalFormPassFilterData($modalForm, filterId);
      }
    }
  });
});