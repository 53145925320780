const SELECTOR_TITLE = '.rp-task-calendar-title';
const SELECTOR_WEEK_PREV = '.rp-task-calendar-week-prev';
const SELECTOR_WEEK_NEXT = '.rp-task-calendar-week-next';
const SELECTOR_WEEK_CURRENT = '.rp-task-calendar-week-current';

export default class TaskCalendarHeader {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.$title = $element.find(SELECTOR_TITLE);

    this.$prevWeek = $element.find(SELECTOR_WEEK_PREV);
    this.$nextWeek = $element.find(SELECTOR_WEEK_NEXT);
    this.$currentWeek = $element.find(SELECTOR_WEEK_CURRENT);

    this.bindEvents();
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.updateTitle();

      this.observable.on('week-change', () => this.updateTitle());

      this.$prevWeek.on('click', () => this.state.prevWeek());
      this.$nextWeek.on('click', () => this.state.nextWeek());
      this.$currentWeek.on('click', () => this.state.currentWeek());
    });
  }

  updateTitle() {
    this.$title.text(this.state.getMonthYear());
  }
}
