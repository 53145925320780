<script setup>
import { ref } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'star_rate'
  },
  message: {
    type: String,
    default: 'Hello World!'
  },
  exampleBoolean: Boolean,
  exampleNumber: Number,
  exampleArray: Array,
  exampleObject: Object
});

const icon = ref(props.icon);
const message = ref(props.message);

const iconElement = ref(null);
const messageElement = ref(null);
</script>

<template>
  <div class="card">
    <div class="card-header">
      Example
    </div>
    <div class="card-body">
      <h5 class="card-title">Example Vue.js component</h5>

      <div class="form-group">
        <label class="form-label">Icon</label>
        <div class="form-control-container">
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <rp-icon :name="icon" class="icon"></rp-icon>
              </div>
            </div>
            <input type="text" class="form-control" v-model="icon" ref="iconElement">
            <rp-tooltip :target="iconElement">Please enter icon name</rp-tooltip>
          </div>
          <div class="form-text">
            <div class="form-help">Icon from props: {{ props.icon }}</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Message</label>
        <div class="form-control-container">
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ message }}</div>
            </div>
            <input type="text" class="form-control" v-model="message" ref="messageElement">
            <rp-tooltip :target="messageElement">Please enter message</rp-tooltip>
          </div>
          <div class="form-text">
            <div class="form-help">Message from props: {{ props.message }}</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Language</label>
        <div class="form-control-container">
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <slot name="prepend"></slot>
              </div>
            </div>
            <slot></slot>
            <div class="input-group-append">
              <div class="input-group-text">
                <slot name="append"></slot>
              </div>
            </div>
          </div>
          <div class="form-text">
            <div class="form-help">Input and addons are passed via slots</div>
          </div>
        </div>
      </div>

      <p class="card-text">
        <ul class="list-unstyled">
          <li>Example boolean prop has correct type: {{ typeof exampleBoolean === 'boolean' }}</li>
          <li>Example number prop has correct type: {{ typeof exampleNumber === 'number' }}</li>
          <li>Example array prop has correct type: {{ Array.isArray(exampleArray) }}</li>
          <li>Example object prop has correct type: {{ typeof exampleObject === 'object' }}</li>
        </ul>
      </p>
    </div>
  </div>
</template>
