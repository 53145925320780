import $ from 'jquery';
import { updateUrl } from '../../utils/functions';

class CreateDealModal {
  constructor($modal) {
    this.$customer = $modal.find('#customer');
    this.$products = $modal.find('#products');
    this.originalUrl = this.$products.attr('data-select-url');

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    this.$customer.on('change', () => this.updatePrereservations());
  }

  init() {
    this.updatePrereservations();
  }

  updatePrereservations() {
    if (this.$customer.val()) {
      const url = updateUrl(this.originalUrl, { 'CustomerId': this.$customer.val() });
      this.$products.attr('data-select-url', url);
    } else {
      this.$products.attr('data-select-url', this.originalUrl);
    }
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#create-deal-modal')) {
      new CreateDealModal(data.$root);
    }
  })
})