import Log from 'core/log';
import { SessionStorage } from 'core/storage';
import $ from 'jquery';
import { createError, redirect } from 'utils/functions';
import Modal from './modal';

const RESPONSE_COMMAND_PENDING_KEY = 'rp-response-command-pending';

const RESPONSE_COMMANDS_NAVIGATION = [
  'redirect',
  'reload-page',
  'reload-page-open-modal'
];

class ResponseCommandStorage {
  static #storage = new SessionStorage();

  static load() {
    try {
      const body = this.#storage.getJson(RESPONSE_COMMAND_PENDING_KEY);
      if (body) {
        new ResponseCommand({ body }).handle().catch(error => Log.error(error));
      }
    } finally {
      this.#storage.remove(RESPONSE_COMMAND_PENDING_KEY);
    }
  }

  static save(command, url) {
    this.#storage.setJson(RESPONSE_COMMAND_PENDING_KEY, { command, url });
  }
}

export default class ResponseCommand {
  constructor(response, $caller) {
    this.command = response?.body?.command;
    this.url = response?.body?.url;
    this.$caller = $caller;
  }

  static isNavigationCommand(command) {
    return RESPONSE_COMMANDS_NAVIGATION.indexOf(command) >= 0;
  }

  handle() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      if (this.command) {
        switch (this.command) {
          case 'redirect':
            this.handleRedirect();
            break;
          case 'reload-page':
            this.handlePageReload();
            break;
          case 'reload-table':
            this.handleTableReload();
            break;
          case 'reload-content':
            this.handleContentReload();
            break;
          case 'open-modal':
            this.handleModalOpen();
            break;
          case 'reload-page-open-modal':
            this.handlePageReloadModalOpen();
            break;
          case 'reload-table-open-modal':
            this.handleTableReloadModalOpen();
            break;
          case 'reload-content-open-modal':
            this.handleContentReloadModalOpen();
            break
          default:
            this.errorUnknownCommand();
            break;
        }
      } else {
        this.resolve();
      }
    });
  }

  reloadTable() {
    if (this.$caller) {
      const table = this.$caller.closest('.rp-table').data('rp-table');

      if (table) {
        table.load();
        return true;
      }
    }

    return false;
  }

  handleRedirect() {
    if (this.url) {
      redirect(this.url);
      this.resolve();
    } else {
      this.errorNoUrl();
    }
  }

  handlePageReload() {
    window.location.reload();
    this.resolve();
  }

  handleTableReload() {
    if (this.reloadTable()) {
      this.resolve();
    } else {
      this.handlePageReload();
    }
  }

  handleContentReload() {
    $(document).trigger('rp-content:reload');
  }

  handleModalOpen() {
    if (this.url) {
      new Modal(this.url).open()
        .then(response => new ResponseCommand(response, this.$caller).handle())
        .then(() => this.resolve())
        .catch(reason => this.reject(reason));
    } else {
      this.errorNoUrl();
    }
  }

  handlePageReloadModalOpen() {
    if (this.url) {
      ResponseCommandStorage.save('open-modal', this.url);
      this.handlePageReload();
    } else {
      this.errorNoUrl();
    }
  }

  handleTableReloadModalOpen() {
    if (this.reloadTable()) {
      this.handleModalOpen();
    } else {
      this.handlePageReloadModalOpen();
    }
  }

  handleContentReloadModalOpen() {
    this.handleContentReload();
    this.handleModalOpen();
  }

  errorUnknownCommand() {
    this.reject(createError('Unknown response command ' + this.command, {
      source: 'ResponseCommand'
    }));
  }

  errorNoUrl() {
    this.reject(createError('URL is not provided for command ' + this.command, {
      source: 'ResponseCommand'
    }));
  }
}

$(() => ResponseCommandStorage.load());
