<script setup>
import { onMounted } from 'vue';
import ActivityStreamEmailDraft from './ActivityStreamEmailDraft.vue';
import ActivityStreamHeader from './ActivityStreamHeader.vue';
import ActivityStreamList from './ActivityStreamList.vue';
import { useConfig } from './useConfig';
import { useEmailDraft } from './useEmailDraft';

const props = defineProps({
  configUrl: {
    type: String,
    required: true
  }
});

const { config, loadConfig } = useConfig();

const { draft } = useEmailDraft();

onMounted(() => loadConfig(props.configUrl));

</script>

<template>
  <div class="rpvue-ActivityStream">
    <div class="rpvue-ActivityStream-header">
      <ActivityStreamHeader :config="config"/>
    </div>
    <div class="rpvue-ActivityStream-list">
      <ActivityStreamList :config="config"/>
    </div>
    <div class="rpvue-ActivityStream-draft" v-if="draft">
      <ActivityStreamEmailDraft :draft="draft"/>
    </div>
  </div>
</template>
