<script setup>
import { Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import Dates from 'core/dates';
import { computed } from 'vue';

const props = defineProps({
  timestamp: {
    type: Date,
    required: true
  }
});

const time = computed(() => Dates.format(props.timestamp, Dates.FORMAT_TIME));
const dateTime = computed(() => Dates.format(props.timestamp, Dates.FORMAT_DATE_TIME));

</script>

<template>
  <span class="rpvue-ActivityStreamTimestamp">
    <span class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
      {{ time }}
    </span>
    <Tooltip>{{ dateTime }}</Tooltip>
  </span>
</template>
