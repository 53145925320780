<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
const props = defineProps({
  collapsible: {
    type: Boolean,
    default: false
  },
  topLeftLargeFont: {
    type: Boolean,
    default: false
  },
  topRightLargeFont: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <div class="rpvue-QuadroContainer">
    <div class="rpvue-QuadroContainer-Row rpvue-QuadroContainer-Row--top" v-if="slots.topLeft || slots.topRight || !collapsible">
      <div class="rpvue-QuadroContainer-Slot rpvue-QuadroContainer-Slot--left" :class="{ 'rpvue-QuadroContainer-Slot--large-font': topLeftLargeFont }">
        <slot name="topLeft"></slot>
      </div>
      <div class="rpvue-QuadroContainer-Slot rpvue-QuadroContainer-Slot--right" :class="{ 'rpvue-QuadroContainer-Slot--large-font': topRightLargeFont }">
        <slot name="topRight"></slot>
      </div>
    </div>
    <div class="rpvue-QuadroContainer-Row rpvue-QuadroContainer-Row--bottom" v-if="slots.bottomLeft || slots.bottomRight || !collapsible">
      <div class="rpvue-QuadroContainer-Slot rpvue-QuadroContainer-Slot--left">
        <slot name="bottomLeft"></slot>
      </div>
      <div class="rpvue-QuadroContainer-Slot rpvue-QuadroContainer-Slot--right">
        <slot name="bottomRight"></slot>
      </div>
    </div>
    <slot name="tooltip"></slot>
  </div>
</template>
