import Dates from 'core/dates';
import Fetch from 'core/fetch';
import Log from 'core/log';
import Numbers from 'core/numbers';
import Router from 'core/router';
import $ from 'jquery';
import DealUnits from './units';

class DealReservationForm {
  constructor($form) {
    this.$rcSignatureDate = $form.find('#rc-signature-date');
    this.$rfDeadline = $form.find('#rf-deadline');
    this.$fpcSignatureDeadline = $form.find('#fpc-signature-deadline');

    this.dealId = $form.attr('data-deal-id');
    this.rfDeadlineDays = Numbers.parse($form.attr('data-rf-deadline-days'));

    if (this.$rcSignatureDate.exists()) {
      this.bindEvents();
      this.calculateFpcSignatureDeadline();
    }
  }

  bindEvents() {
    this.$rcSignatureDate.on('change', event => {
      this.calculateFpcSignatureDeadline(event);
    });
  }

  calculateFpcSignatureDeadline(event) {
    const rcSignatureDate = this.$rcSignatureDate.val();
    if (rcSignatureDate) {
      // call was triggered by change event
      // or RF deadline is not set
      if (event || !this.$rfDeadline.val()) {
        const rfDeadline = Dates
          .parse(rcSignatureDate)
          .add(this.rfDeadlineDays, 'days');

        this.$rfDeadline
          .val(Dates.format(rfDeadline))
          .trigger('change');
      }
    }

    const body = {
      'DealId': this.dealId,
      'RCSignatureDate': rcSignatureDate
    };

    Fetch.post(Router.get('API_DEAL_FPC_SIGNATURE_DEADLINE'), body)
      .then(response => {
        if (!response.ok || response.body['Result'] !== 'OK') {
          return;
        }

        // call was triggered by change event
        // or FPC signature deadline is not set
        if (event || !this.$fpcSignatureDeadline.val()) {
          this.$fpcSignatureDeadline
            .val(response.body['FPCSignatureDeadline'])
            .trigger('change');
        }

        if (response.body['FPCSignatureDeadline']) {
          if (event) {
            // only enable if was triggered by change event
            this.$fpcSignatureDeadline.disabled(false);
          }
        } else {
          this.$fpcSignatureDeadline.disabled();
        }
      })
      .catch(error => Log.error(error));
  }
}

$(()=> {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $form = data.$root.find('#deal-reservation-form');
    if ($form.exists()) {
      new DealUnits($form);
      new DealReservationForm($form);
    }
  });
});
