<script setup>
import { Button, ButtonSize, ButtonType } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { computed, inject, ref } from 'vue';
import ActivityStreamEmailDraftAttachments from './ActivityStreamEmailDraftAttachments.vue';
import { EmailDraft } from './Types';

const props = defineProps({
  draft: EmailDraft
});

const canSend = computed(() => props.draft.subject && (props.draft.content_text || props.draft.content_html));

const sendDraft = inject('sendDraft');
const disabled = ref(false);

const onSend = async () => {
  disabled.value = true;
  await sendDraft();
  disabled.value = false;
};

</script>

<template>
  <div class="rpvue-ActivityStreamEmailDraftFooter">
    <div class="rpvue-ActivityStreamEmailDraftFooter-attachments">
      <ActivityStreamEmailDraftAttachments/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftFooter-send">
      <Button :type="ButtonType.REGULAR" :size="ButtonSize.SM" :disabled="!canSend || disabled" @click="onSend">
        {{ I18N.t('email_draft_send') }}
      </Button>
    </div>
  </div>
</template>
