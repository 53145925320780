import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';
import { delay, formatAttrSelector } from 'utils/functions';
import Confirmation from './confirmation';
import Modal from './modal';
import Offcanvas from './offcanvas';
import ResponseCommand from './responseCommand';

const POST_TIMEOUT = 5000;

export const ATTR_CLICK_ACTION = 'data-click-action';
export const ATTR_CLICK_ACTION_URL = 'data-click-action-url';

class ClickAction {
  constructor($element) {
    this.$element = $element;

    this.type = $element.attr(ATTR_CLICK_ACTION);
    this.url = $element.attr(ATTR_CLICK_ACTION_URL);

    this.confirmation = Confirmation.create($element);
  }

  handle() {
    switch (this.type) {
      case 'request':
        this.confirm().then(() => this.handleRequest());
        break;
      case 'post':
        this.confirm().then(() => this.handlePost());
        break;
      case 'modal':
        this.handleModal();
        break;
      case 'offcanvas':
        this.handleOffcanvas();
        break;
    }
  }

  handleRequest() {
    this.disable();

    Fetch.post(this.url)
      .then(response => new ResponseCommand(response, this.$element).handle())
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  handlePost() {
    if (Fetch.isValidUrl(this.url)) {
      this.disable();
      delay(POST_TIMEOUT).then(() => this.enable());

      $('<form>')
        .attr('method', 'POST')
        .attr('action', this.url)
        .appendTo(document.body)
        .trigger('submit');
    } else {
      Log.error('URL is not valid', {
        source: 'ClickAction',
        url: this.url
      });
    }
  }

  handleModal() {
    this.disable();

    new Modal(this.url).open()
      .then(response => new ResponseCommand(response, this.$element).handle())
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  handleOffcanvas() {
    this.disable();

    new Offcanvas(this.url).open()
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  confirm() {
    return this.confirmation.confirm();
  }

  enable() {
    this.$element.loading(false);
  }

  disable() {
    this.$element.loading(true);
  }
}

$(() => {
  $(document).on('click', formatAttrSelector(ATTR_CLICK_ACTION), event => {
    event.preventDefault();

    const $target = $(event.currentTarget);
    if ($target.is(':disabled') || $target.is('.disabled')) {
      return;
    }

    new ClickAction($target).handle();
  });
});
