import $ from 'jquery';
import InteractiveElement, { EVENT_ACTIVATE, EVENT_DEACTIVATE } from './interactiveElement';
import { formatAttrSelector } from '../utils/functions';

const ATTR_HOVER_BADGE = 'data-hover-badge';

const SELECTOR_CARD = '.rp-interactive-card';
const SELECTOR_BADGES = '.rp-card-section-badges';
const SELECTOR_HOVER_BADGE = '.rp-card-hover-badge';

class InteractiveCard {
  constructor($element) {
    this.$element = $element;
    this.$badges = $element.find(SELECTOR_BADGES);

    new InteractiveElement($element);

    this.bindEvents();
  }

  bindEvents() {
    if (this.$badges.exists()) {
      this.$element.on('mouseover', event => {
        const $target = $(event.target).closest(formatAttrSelector(ATTR_HOVER_BADGE));

        if ($target.exists()) {
          this.$badges.find(SELECTOR_HOVER_BADGE).hidden();
          this.$badges.find(formatAttrSelector(ATTR_HOVER_BADGE, $target.attr(ATTR_HOVER_BADGE))).hidden(false);
        }
      });

      this.$element.on('mouseout', event => {
        const $target = $(event.target).closest(formatAttrSelector(ATTR_HOVER_BADGE));

        if ($target.exists()) {
          this.$badges.find(SELECTOR_HOVER_BADGE).hidden();
        }
      });
    }

    this.$element.on(EVENT_ACTIVATE, (event, data) => data.$element.addClass('active'));
    this.$element.on(EVENT_DEACTIVATE, (event, data) => data.$element.removeClass('active'));
  }
}

function init($root) {
  $root.find(SELECTOR_CARD).each(function() {
    const $element = $(this);
    const interactiveCard = new InteractiveCard($element);

    $element.data('rp-interactive-card', interactiveCard);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
