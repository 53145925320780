<script setup>
import { Button, ButtonType, ButtonSize, Dropdown, DropdownRow, Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { computed } from 'vue';
import { EmailItem } from './Types';

const props = defineProps({
  item: {
    type: EmailItem,
    required: true
  }
});

const hasAttachmentsDropdown = computed(() => props.item.emailAttachments.length > 3);
const hasAttachments = computed(() => props.item.emailAttachments.length > 0);

</script>

<template>
  <div class="rpvue-ActivityStreamEmailAttachments" v-if="hasAttachmentsDropdown">
    <div class="rpvue-ActivityStreamEmailAttachments-item">
      <Dropdown>
        <template v-slot:activator="{ isOpen, toggle }">
          <Button icon="folder" :type="ButtonType.OUTLINE" :size="ButtonSize.SM" :active="isOpen" @click="toggle">
            {{ I18N.t('customer_activity_stream_attachments', item.emailAttachments.length) }}
          </Button>
        </template>
        <template v-slot:content="{ close }">
          <DropdownRow v-for="attachment in item.emailAttachments" :url="attachment.url" @click="close" target="_blank">
            {{ attachment.fileName }}
            <template v-slot:tooltip>
              <Tooltip>{{ attachment.fileSize }}</Tooltip>
            </template>
          </DropdownRow>
        </template>
      </Dropdown>
    </div>
  </div>
  <div class="rpvue-ActivityStreamEmailAttachments" v-else-if="hasAttachments">
    <div class="rpvue-ActivityStreamEmailAttachments-item" v-for="attachment in item.emailAttachments">
      <Button icon="attach_file" :url="attachment.url" :type="ButtonType.OUTLINE" :size="ButtonSize.SM" target="_blank">
        {{ attachment.fileName }}
        <template v-slot:tooltip>
          <Tooltip>{{ attachment.fileSize }}</Tooltip>
        </template>
      </Button>
    </div>
  </div>
</template>
