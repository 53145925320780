import I18N from '../../core/i18n';
import Dropdown from 'select2/src/js/select2/dropdown';

function template(I18N) {
  return `
    <span class="select2-helper-buttons">
      <span class="select2-helper-button select2-select-all">
        ${I18N.t('select_all')}
      </span>
      <span class="select2-helper-button select2-select-none">
        ${I18N.t('select_none')}
      </span>
    </span>
  `;
}

const original = Dropdown.prototype.render;

Dropdown.prototype.render = function() {
  const $dropdown = original.call(this);

  if (this.options.get('multiple')) {
    this.$helperButtons = $(template(I18N));
    $dropdown.prepend(this.$helperButtons);
  }

  return $dropdown;
};

Dropdown.prototype.bind = function(container) {
  if (this.options.get('multiple')) {
    this.$helperButtons.on('click', '.select2-select-all', event => {
      container.dataAdapter.query({}, data => {
        if (data.results) {
          data.results.forEach(value => {
            if (value.children) {
              value.children.forEach(child => {
                this.trigger('select', {
                  originalEvent: event,
                  data: child
                });
              });
            } else {
              this.trigger('select', {
                originalEvent: event,
                data: value
              });
            }
          });
        }
      });
    });

    this.$helperButtons.on('click', '.select2-select-none', event => {
      container.dataAdapter.current(data => {
        data.forEach(value => {
          this.trigger('unselect', {
            originalEvent: event,
            data: value
          });
        });
      });
    });
  }
};
