<script setup>
import I18N from 'core/i18n';

const subject = defineModel();

</script>

<template>
  <div class="rpvue-ActivityStreamEmailDraftSubject">
    <div class="rpvue-ActivityStreamEmailDraftSubject-label">
      <span class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--large">
        {{ I18N.t('email_message_param_subject') }}:
      </span>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftSubject-input">
      <input class="rpvue-u-TypoLabel rpvue-u-TypoLabel--large"
             :placeholder="I18N.t('email_draft_placeholder_subject')"
             v-model="subject">
    </div>
  </div>
</template>
