import $ from 'jquery';
import DealUnits from './units';

$(()=> {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $form = data.$root.find('#deal-purchase-form');
    if ($form.exists()) {
      new DealUnits($form);
    }
  });
});
