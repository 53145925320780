import $ from 'jquery';
import Table from './table';

function init($root) {
  $root.find('.rp-table').each(function() {
    const $element = $(this);
    const table = new Table($element);

    $element.data('rp-table', table);
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-tabs:loaded', (event, data) => {
    init(data.$root);
  });
});
