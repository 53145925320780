import $ from 'jquery';
import I18N from 'core/i18n';
import FormUpdate from '../../components/formUpdate';

const PAYMENT_METHOD_NOTARY_ESCROW = '2';
const ATTR_CALCULATE_PAYMENT_URL = 'data-calculate-payment-url';

class DealPaymentPrescribedModal {
  constructor($modal) {
    new AmountControl($modal);

    this.$vatRate = $modal.find('#VATRate');
    const urlVatRate = this.$vatRate.attr(ATTR_CALCULATE_PAYMENT_URL);
    this.$formUpdateByVatRate = new CalculatePaymentFormUpdate(this.$vatRate, urlVatRate);

    this.$vatAmount = $modal.find('#VAT');
    const urlVatAmount = this.$vatAmount.attr(ATTR_CALCULATE_PAYMENT_URL);
    this.$formUpdateByVatAmount = new CalculatePaymentFormUpdate(this.$vatAmount, urlVatAmount);

    this.$percent = $modal.find('#Percent');
    const urlPercent = this.$percent.attr(ATTR_CALCULATE_PAYMENT_URL);
    this.$formUpdateByPercent = new CalculatePaymentFormUpdate(this.$percent, urlPercent);

    this.$paymentMethod = $modal.find('#AccountType');
    this.$note1 = $modal.find('#Note1');
    this.$note2 = $modal.find('#Note2');
    this.$note3 = $modal.find('#Note3');

    this.bindEvents();
    this.toggleNoteFields();
  }

  bindEvents() {
    this.$vatRate.on('change', () => this.$formUpdateByVatRate.handle());
    this.$vatAmount.on('change', () => this.$formUpdateByVatAmount.handle());
    this.$percent.on('change', () => this.$formUpdateByPercent.handle());

    this.$paymentMethod.on('change', () => {
      this.toggleNoteFields();
    });
  }

  toggleNoteFields() {
    var method = this.$paymentMethod.val();

    this.toggleField(this.$note1, method !== PAYMENT_METHOD_NOTARY_ESCROW);
    this.toggleField(this.$note2, method !== PAYMENT_METHOD_NOTARY_ESCROW);
    this.toggleField(this.$note3, method !== PAYMENT_METHOD_NOTARY_ESCROW);
  }

  toggleField($field, disabled) {
    $field.disabled(disabled);
    $field.closest('.form-group').hidden(disabled);
  }
}

class CalculatePaymentFormUpdate extends FormUpdate {
  handleUpdate(values) {
    super.handleUpdate(values, false); // prevent change to avoid endless update loop
    this.$form.trigger('rp-form-update:updated');
  }
}

class AmountControl {
  constructor($modal) {
    this.$modal = $modal;

    this.$fieldWithVat = $modal.find('#amount-with-vat');
    const urlWithVat = this.$fieldWithVat.attr(ATTR_CALCULATE_PAYMENT_URL);
    this.$formUpdateByAmountWithVat = new CalculatePaymentFormUpdate(this.$fieldWithVat, urlWithVat);

    this.$fieldWithoutVat = $modal.find('#amount-without-vat');
    const urlWithoutVat = this.$fieldWithoutVat.attr(ATTR_CALCULATE_PAYMENT_URL);
    this.$formUpdateByAmountWithoutVat = new CalculatePaymentFormUpdate(this.$fieldWithoutVat, urlWithoutVat);

    this.$lockedButton = $modal.find('#locked-button');
    this.$unlockedButton = $modal.find('#unlocked-button');
    this.$fieldUnlocked = $modal.find('#vat-rate-unlocked');

    this.$fieldWithVatHelpInput = $modal.find('#amount-with-vat-help');
    this.$fieldWithoutVatHelpInput = $modal.find('#amount-without-vat-help');
    this.$fieldWithVatHelpText = this.$fieldWithVat.closest('.form-group').find('.form-help');
    this.$fieldWithoutVatHelpText = this.$fieldWithoutVat.closest('.form-group').find('.form-help');

    this.init();
    this.bindEvents();
  }

  init() {
    this.handleUnlock(false);
  }

  bindEvents() {
    this.$lockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$unlockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$fieldWithVat.on('change', () => this.$formUpdateByAmountWithVat.handle());
    this.$fieldWithoutVat.on('change', () => this.$formUpdateByAmountWithoutVat.handle());

    this.$modal.on('rp-form-update:updated', () => {
      this.handleHelpText(this.$fieldWithVatHelpText, this.$fieldWithVatHelpInput.val());
      this.handleHelpText(this.$fieldWithoutVatHelpText, this.$fieldWithoutVatHelpInput.val());
    });
    this.$fieldWithVatHelpText.on('click', 'a', event => {
      event.preventDefault();
      this.handleHelpTextClick(this.$fieldWithVat, this.$fieldWithVatHelpInput.val());
    });
    this.$fieldWithoutVatHelpText.on('click', 'a', event => {
      event.preventDefault();
      this.handleHelpTextClick(this.$fieldWithoutVat, this.$fieldWithoutVatHelpInput.val());
    });
  }

  handleUnlock(unlocked) {
    this.unlocked = unlocked;
    this.$fieldUnlocked.val(this.unlocked);

    this.toggleButton(this.$lockedButton, !this.unlocked);
    this.toggleButton(this.$unlockedButton, this.unlocked);
  }

  toggleButton(button, show) {
    button.hidden(!show);
    button.disabled(!show);
  }

  handleHelpText($helpText, value) {
    $helpText.empty();

    if (value) {
      $helpText.append(`<span class="text-danger">${I18N.t('deal_payments_prescribed_amount_help')}: </span>`);
      $helpText.append(`<a href="#">${value}</a>`);
    }
  }

  handleHelpTextClick($input, value) {
    this.$fieldWithVatHelpInput.val('');
    this.$fieldWithoutVatHelpInput.val('');
    this.$fieldWithVatHelpText.empty();
    this.$fieldWithoutVatHelpText.empty();

    if (value) {
      $input.val(value).trigger('change');
    }
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#deal-payment-prescribed-modal')) {
      new DealPaymentPrescribedModal(data.$root);
    }
  });
});