import $ from 'jquery';

const SELECTOR = '[data-auto-init="localized-input"]';

class LocalizedInput {
  constructor($element) {
    this.$inputs = $element.find('input');

    this.bindEvents();
    this.update(false);
  }

  bindEvents() {
    this.$inputs.on('change', () => this.update(true));
  }

  update(validate) {
    let required = true;
    this.$inputs.each(function() {
      if ($(this).val()) {
        required = false;
      }
    });

    this.$inputs.required(required, validate);
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $element = $(this);

    if ($element.attr('data-required') === 'true') {
      new LocalizedInput($element);
    }
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
