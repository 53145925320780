import I18N from 'core/i18n';

export function createAction(spec, data) {
  const action = {
    label: I18N.t(data.label),
    icon: data.icon
  };

  if (spec.url) {
    action.onClick = () => data.clickHandler(spec.url);
  } else {
    action.onClick = () => {};
  }

  if (spec.reason) {
    action.disabled = true;
    action.reason = I18N.t(spec.reason);
  }

  return action;
}
