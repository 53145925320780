import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import 'dayjs/locale/bg';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/hu';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/sl';
import 'dayjs/locale/uk';

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.locale(document.documentElement.lang);

const FORMAT_DATE = 'YYYY-MM-DD';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = `${FORMAT_DATE} ${FORMAT_TIME}`;

export default class Dates {
  static FORMAT_DATE = FORMAT_DATE;
  static FORMAT_TIME = FORMAT_TIME;
  static FORMAT_DATE_TIME = FORMAT_DATE_TIME;

  static now() {
    return dayjs();
  }

  static format(value, format = FORMAT_DATE) {
    const parsed = this.parse(value);
    if (parsed) {
      return parsed.format(format);
    }

    return value;
  }

  static parse(value, format = FORMAT_DATE) {
    if (value instanceof dayjs) {
      return value;
    }

    if (value instanceof Date) {
      return dayjs(value);
    }

    if (typeof value !== 'string') {
      return null;
    }

    const parsed = dayjs(value, format, true);
    if (parsed.isValid()) {
      return parsed;
    }

    return null;
  }
}
