import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Numbers from 'core/numbers';
import Router from 'core/router';
import $ from 'jquery';

const SELECTOR = '[data-auto-init="multi-file-input"]';

function createUpload() {
  return `
    <div class="input-group flex-nowrap file-input-part file-input-upload">
      <div class="custom-file">
        <input type="file" class="custom-file-input" data-never-validate>
        <label class="custom-file-label" data-browse="${I18N.t('upload')}">${I18N.t('upload_choose')}</label>
      </div>
    </div>
  `;
}

function createProgress() {
  return `
    <div class="input-group flex-nowrap file-input-part file-input-progress">
      <span class="form-control">
        ${I18N.t('uploading')}
      </span>
      <div class="input-group-append">
        <span class="input-group-text">
          <span class="spinner-border spinner-border-sm" role="status"></span>
        </span>
      </div>
    </div>
  `;
}

function createDisplay(resource) {
  const openButton = createOpenButton(resource);

  return `
    <div class="input-group flex-nowrap file-input-part file-input-display" data-resource-id="${resource['id']}">
      <span class="form-control file-input-preview">
        ${resource['file_name']}
      </span>
      <div class="input-group-append">
        ${openButton}
        <a href="${resource['download_url']}" role="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part" data-never-disable>
          <rp-icon name="download" class="icon rp-icon-btn-icon"></rp-icon>
        </a>
        <button type="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part file-input-delete">
          <rp-icon name="delete" class="icon rp-icon-btn-icon"></rp-icon>
        </button>
      </div>
    </div>
  `;
}

function createOpenButton(resource) {
  if (!resource['pdf']) {
    return '';
  }

  return `
    <a href="${resource['url']}" target="_blank" role="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part" data-never-disable>
      <rp-icon name="open_in_new" class="icon rp-icon-btn-icon"></rp-icon>
    </a>
  `;
}

class MultiFileInput {
  constructor($element) {
    this.$element = $element;
    this.resources = [];
    this.limit = Numbers.parse(this.$element.attr('data-limit')) || 10;

    this.$container = this.$element.closest('.input-group-stack');

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    this.$container.on('change', '.file-input-upload', event => {
      const input = event.target;

      if (input.files.length > 0) {
        this.upload(input.files[0]);
        input.value = null;
      }
    });

    this.$container.on('click', '.file-input-delete', event => {
      const $display = $(event.currentTarget).closest('.file-input-display');

      this.delete(Numbers.parse($display.attr('data-resource-id')));
    });

    this.observer = new MutationObserver(() => this.updateState());
    this.observer.observe(this.$element.get(0), {
      attributes: true,
      attributeFilter: ['disabled']
    });
  }

  init() {
    if (this.$element.val()) {
      Fetch.post(Router.get('API_RESOURCE_DETAIL'), { 'resourceids': this.$element.val() })
        .then(response => this.handleResponse(response))
        .catch(error => Log.error(error));
    } else {
      this.update();
    }
  }

  upload(file) {
    this.startProgress();

    const formData = new FormData();
    formData.append('file', file);

    Fetch.post(Router.get('API_RESOURCE_UPLOAD'), formData)
      .then(response => this.handleResponse(response, true))
      .catch(error => Log.error(error));
  }

  handleResponse(response, merge = false) {
    if (response.ok && response.body['Resources']) {
      if (merge) {
        this.merge(response.body['Resources']);
      } else {
        this.resources = response.body['Resources'];
      }
    } else {
      this.resources = [];
    }
    this.update(merge);
  }

  findIndex(id) {
    return this.resources.findIndex(r => r['id'] === id);
  }

  merge(resources) {
    for (const resource of resources) {
      const index = this.findIndex(resource['id']);
      if (index === -1) {
        this.resources.push(resource);
      }
    }
  }

  delete(id) {
    const index = this.findIndex(id);
    if (index !== -1) {
      this.resources.splice(index, 1);
    }

    this.update(true);
  }

  startProgress() {
    this.$container.find('.file-input-upload').remove();
    this.$container.append(createProgress());
  }

  clear() {
    this.$container.find('.file-input-part').remove();
  }

  update(triggerChange = false) {
    this.clear();

    for (const resource of this.resources) {
      this.$container.append(createDisplay(resource));
    }

    if (this.limit > this.resources.length) {
      this.$container.append(createUpload());
    }

    this.updateValue(triggerChange);
    this.updateState();
  }

  updateValue(triggerChange = false) {
    this.$element.val(this.resources.map(r => r['id']).join(','));

    if (triggerChange) {
      this.$element.trigger('change');
      this.$element.trigger('rp-multi-file-input:change', {
        resources: this.resources
      });
    }
  }

  updateState() {
    this.$container.find('.file-input-part').disabled(this.$element.prop('disabled'));
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $element = $(this);
    const multiFileInput = new MultiFileInput($element);

    $element.data('rp-multi-file-input', multiFileInput);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
