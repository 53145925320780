import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { debounce } from 'utils/functions';

const SEARCH_DELAY = 1000;
const SEARCH_TERM_MIN_LENGTH = 3;

function groupTemplate(group) {
  return `<h6 class="dropdown-header">${group.title}</h6>`;
}

function resultTemplate(result) {
  return `
    <a class="dropdown-item quick-search-result" href="${result.url}">
      <span>${result.title}</span>
    </a>
  `;
}

function emptyTemplate() {
  return `
    <span class="dropdown-item-text text-center">
      ${I18N.t('quicksearch_result_empty')}
     </span>
  `;
}

class QuickSearch {
  constructor() {
    this.$container = $('.quick-search');
    this.$results = this.$container.find('.quick-search-results');
    this.$input = this.$container.find('.quick-search-input');
    this.$progress = this.$container.find('.quick-search-progress');

    this.searchCounter = 0;
    this.handleSearch = debounce(SEARCH_DELAY, () => this.search());

    this.bindEvents();
  }

  bindEvents() {
    this.$input.on('input', () => {
      this.showProgress();
      this.handleSearch();
    });

    this.$container.on('shown.bs.dropdown', () => this.focusInput());

    this.$container.on('hidden.bs.dropdown', () => {
      this.clearResults();
      this.clearInput();
    });

    this.$container.on('click', '.dropdown-menu', event => {
      const $target = $(event.target);

      if ($target.closest('.quick-search-result').exists()) {
        return;
      }

      // prevent clicks on anything except results from closing quick search
      event.stopPropagation();
    });
  }

  search() {
    const term = this.$input.val();
    if (term.length < SEARCH_TERM_MIN_LENGTH) {
      return;
    }

    const counter = ++this.searchCounter;

    Fetch.post(Router.get('API_QUICK_SEARCH'), { term })
      .then(response => {
        if (counter !== this.searchCounter) {
          return;
        }

        this.clearResults();
        this.hideProgress();

        if (response.ok && response.body.groups.length > 0) {
          response.body.groups.forEach(group => this.addGroup(group));
        } else {
          this.addEmpty();
        }
      })
      .catch(error => {
        Log.error(error);

        if (counter === this.searchCounter) {
          this.hideProgress();
        }
      });
  }

  focusInput() {
    this.$input.focus();
  }

  clearInput() {
    this.$input.val('');
  }

  clearResults() {
    this.$results.empty();
  }

  showProgress() {
    this.$progress.removeClass('invisible');
  }

  hideProgress() {
    this.$progress.addClass('invisible');
  }

  addGroup(group) {
    this.$results.append($(groupTemplate(group)));

    group.results.forEach(result => this.addResult(result));
  }

  addResult(result) {
    const $result = $(resultTemplate(result));

    if (result.description) {
      $result.append($(`<small>${result.description}</small>`));
    }

    this.$results.append($result);
  }

  addEmpty() {
    this.$results.append($(emptyTemplate()));
  }
}

$(() => {
  new QuickSearch();
});
