import $ from 'jquery';

$(() => {
  $(document).on('click', event => {
    if ($(event.target).closest('#sidebar').exists()) {
      return;
    }

    const $sidebar = $('#sidebar');
    if ($sidebar.is('.show')) {
      $sidebar.collapse('hide');
    }
  });
});
