import $ from 'jquery';

export default class Sorting {
  constructor(table, sorting) {
    this.table = table;
    this.$header = table.$table.find('thead > tr');

    this.sorting = this.fromString(sorting);
    this.bindEvents();
  }

  get sorting() {
    return this._sorting;
  }

  set sorting(value) {
    this._sorting = value;

    this.updateColumns();
  }

  fromString(string) {
    const sorting = [];

    string.split(',').forEach(s => {
      const parts = s.trim().split(' ');
      if (parts.length !== 2) {
        return;
      }

      const column = parts[0];
      let direction = parts[1];

      if (direction !== 'ASC' && direction !== 'DESC') {
        direction = 'ASC';
      }

      const $column = this.findColumn(column);
      if ($column.exists()) {
        sorting.push({
          column: column,
          direction: direction
        });
      }
    });

    return sorting;
  }

  toString() {
    return this.sorting.map(s => s.column + ' ' + s.direction).join();
  }

  findColumn(column) {
    return this.$header
      .find('.rp-column-sortable[data-column-id=' + column + ']');
  }

  updateColumns() {
    this.$header.find('.rp-column-sortable')
      .removeClass('rp-column-sorted-asc')
      .removeClass('rp-column-sorted-desc');

    this.sorting.forEach(s => {
      this.findColumn(s.column)
        .toggleClass('rp-column-sorted-asc', s.direction === 'ASC')
        .toggleClass('rp-column-sorted-desc', s.direction === 'DESC');
    });
  }

  bindEvents() {
    this.$header.on('click', '.rp-column-sortable', event => {
      const $column = $(event.currentTarget);
      const column = $column.attr('data-column-id');

      let sorting = this.sorting;

      if (event.ctrlKey) {
        const index = sorting.findIndex(s => s.column === column);
        if (index !== -1) {
          sorting.splice(index, 1);
        }
      } else {
        sorting = [];
      }

      if ($column.is('.rp-column-sorted-asc')) {
        sorting.push({
          column: column,
          direction: 'DESC'
        });
      } else {
        sorting.push({
          column: column,
          direction: 'ASC'
        });
      }

      this.sorting = sorting;
      this.table.load(true);
    });
  }
}
