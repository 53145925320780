<script setup>
import I18N from 'core/i18n';
import { computed } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  empty: {
    type: Boolean,
    default: false
  }
});

const message = computed(() => {
  if (props.loading) {
    return I18N.t('customer_activity_stream_message_loading');
  }
  if (props.error) {
    return I18N.t('customer_activity_stream_message_error');
  }
  if (props.empty) {
    return I18N.t('customer_activity_stream_message_empty');
  }
  return null;
});
</script>

<template>
  <div class="rpvue-u-py-4 rpvue-u-px-7" v-if="message">
    <span class="rpvue-u-TypoTitle rpvue-u-TypoTitle--small" :class="{ 'rpvue-u-TextColorError': error }">
      {{ message }}
    </span>
  </div>
</template>
