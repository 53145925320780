<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: String,
  icon: String,
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'medium', 'large'].includes(value)
  }
});

const name = computed(() => props.name?.substring(0, 1) ?? '?');
const style = computed(() => {
  if (props.name) {
    return { background: getColor(props.name) }
  }
});

const SATURATION = 95;
const LIGHTNESS = 34;

const HUE_STEP = 25;
const HUE_STEP_MIN = 1;
const HUE_STEP_MAX = 13;

function calculateHash(value) {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}

function normalizeHash(hash, min, max) {
  return Math.floor((hash % (max - min)) + min);
}

function getHue(value) {
  return normalizeHash(calculateHash(value), HUE_STEP_MIN, HUE_STEP_MAX) * HUE_STEP;
}

function getColor(value) {
  return `hsl(${getHue(value)} ${SATURATION}% ${LIGHTNESS}%)`;
}
</script>

<template>
  <div class="rpvue-Avatar" :class="['rpvue-Avatar--' + size]" :style="style">
    <rp-icon class="rpvue-Avatar-Icon" :name="icon" v-if="icon"></rp-icon>
    <span class="rpvue-Avatar-Name" v-else>{{ name }}</span>
    <slot name="tooltip"></slot>
  </div>
</template>
