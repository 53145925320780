export default class Observable {
  constructor() {
    this.listeners = {};
  }

  on(event, callback) {
    if (event in this.listeners) {
      this.listeners[event].push(callback);
    } else {
      this.listeners[event] = [callback];
    }
  }

  trigger(event, data) {
    if (event in this.listeners) {
      for (const listener of this.listeners[event]) {
        listener.call(this, data);
      }
    }

    if ('*' in this.listeners) {
      for (const listener of this.listeners['*']) {
        listener.call(this, event, data);
      }
    }
  }
}
