import Log from 'core/log';
import { defineCustomElement } from 'vue';

const COMPONENT_NAME_REGEX = /^[A-Z]+[a-zA-Z0-9]+$/
const CUSTOM_ELEMENT_NAME_REGEX = /^rp(-[a-z0-9]+)+$/;

export default class VueRegistry {
  static #components = new Map();

  static registerComponent(name, component) {
    if (COMPONENT_NAME_REGEX.test(name)) {
      this.#components.set(name, component);
    } else {
      Log.error('Invalid Vue component name: ' + name);
    }
  }

  static registerCustomElement(name, component) {
    if (CUSTOM_ELEMENT_NAME_REGEX.test(name)) {
      try {
        window.customElements.define(name, defineCustomElement(component));
      } catch (e) {
        Log.error(e);
      }
    } else {
      Log.error('Invalid Vue custom element name: ' + name);
    }
  }

  static get(name) {
    return this.#components.get(name);
  }

  static getComponents() {
    return Object.fromEntries(this.#components.entries());
  }
}
