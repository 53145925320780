import $ from 'jquery';
import SelectAdapter from 'select2/src/js/select2/data/select';
import AjaxAdapter from 'select2/src/js/select2/data/ajax';
import MinimumInputLength from 'select2/src/js/select2/data/minimumInputLength';
import Utils from 'select2/src/js/select2/utils';

const originalGet = $.valHooks.select.get;
const originalSet = $.valHooks.select.set;
const originalBind = SelectAdapter.prototype.bind;
const originalCurrent = AjaxAdapter.prototype.current;
const originalQuery = MinimumInputLength.prototype.query;

$.valHooks.select.get = function(element) {
  const value = originalGet.call(this, element);

  if (Array.isArray(value)) {
    return value.join(',');
  }

  return value;
};

$.valHooks.select.set = function(element, value) {
  const $element = $(element);
  const selection = String(value || '').split(',');

  if ($element.is('[data-select-url]')) {
    let triggerInit = false;

    for (const selected of selection) {
      if (selected) {
        const $option = $element.find('option').filter(function() {
          return this.value === selected;
        });

        if (!$option.exists()) {
          $element.append($(`<option value="${selected}">⋯</option>`));
          triggerInit = true;
        }
      }
    }

    if (triggerInit) {
      $element.attr('data-init-value', selection.join(','));
      $element.trigger('rp-select2:init');
    }
  }

  if ($element.is('[multiple]')) {
    return originalSet.call(this, element, selection);
  }

  return originalSet.call(this, element, value);
};

SelectAdapter.prototype.bind = function(container, $container) {
  originalBind.call(this, container, $container);

  this.$element.on('rp-select2:init', () => {
    container.dataAdapter.current(data => {
      container.trigger('selection:update', { data });
    });
  });
};

AjaxAdapter.prototype.current = function(callback) {
  const value = this.$element.attr('data-init-value');
  if (value) {
    this.$element.removeAttr('data-init-value');

    this.query({
      init: true,
      selected: value
    }, data => {
      if (data.results) {
        this.$element.find('option').each(function() {
          Utils.RemoveData(this);
          $(this).remove();
        });

        data.results.forEach(item => {
          const $option = this.option({
            selected: !item.children,
            ...item
          });

          if (item.children) {
            item.children.forEach(child => {
              $option.append(this.option({
                selected: true,
                ...child
              }));
            });
          }

          this.$element.append($option);
        });
      }

      this.$element.trigger('rp-select2:loaded');

      originalCurrent.call(this, callback);
    });
  } else {
    originalCurrent.call(this, callback);
  }
};

MinimumInputLength.prototype.query = function(decorated, params, callback) {
  if (params.init) {
    decorated.call(this, params, callback);
    return;
  }

  originalQuery.apply(this, arguments);
};
