import $ from 'jquery';
import Numbers from '../../core/numbers';
import Dates from '../../core/dates';
import DuplicateCustomerCheck from './duplicateCustomerCheck';

const PERSONAL_NUMBER_YEAR_THRESHOLD = 22;
const PERSONAL_NUMBER_REGEX = /^\s*\d{2}(([05][1-9])|([16][012]))((0[1-9])|([12]\d)|(3[01]))(\/)?(\d{3,4})\s*$/;

class CustomerDetail {
  constructor($root) {
    new DuplicateCustomerCheck($root);

    this.$personalNumber = $root.find('#persondetail_pn');
    this.$personalDateOfBirth = $root.find('#persondetail_birthdate');

    this.$vatIdSk = $root.find('#vatid-sk');

    this.bindEvents();
  }

  bindEvents() {
    if (this.$vatIdSk.exists()) {
      this.$vatIdSk.on('change', event => {
          $(event.target).removeClass("font-italic text-muted");
        }
      )
    }

    if (this.$personalNumber.attr('data-bcnumber') === 'true') {
      this.$personalNumber.on('change', event => {
        const value = event.target.value;
        const birthDate = this.getDateOfBirthFromBCNumber(value);

        if (birthDate) {
          this.$personalDateOfBirth.val(birthDate);
          this.$personalDateOfBirth.trigger('change');
        }
      });
    }
  }

  getDateOfBirthFromBCNumber(bcNumber) {
    if (!PERSONAL_NUMBER_REGEX.test(bcNumber)) {
      return null;
    }
    bcNumber = bcNumber.trim();
    // bcNumber is a birth number ( YYMMDD/SSSC )
    // YY - is last two digits of year of birth
    // MM - month of birth
    // DD - day of birth
    // /SSSC is a serial number separating persons born on the same date ( not used )
    let year = Numbers.parse(bcNumber.substring(0, 2));
    // Making full year format, if year number is less then 22 ( it's mean person was born in 2000 + year )
    year = year < PERSONAL_NUMBER_YEAR_THRESHOLD ? year + 2000 : year + 1900;

    let month = Numbers.parse(bcNumber.substring(2, 4));
    // In BCN for female month number is MM+50, so if month number is bigger than 50 we extract 50
    month = month < 50 ? month : month - 50;

    let day = Numbers.parse(bcNumber.substring(4, 6));

    return Dates.format(Dates.now().year(year).month(month - 1).date(day));
  }
}

class NameFields {
  constructor($role, $name, $surname) {
    this.$role = $role;
    this.$name = $name;
    this.$surname = $surname;

    this.bindEvents();
  }

  required(required) {
    this.$name.required(required);
    this.$surname.required(required);
  }

  bindEvents() {
    this.$role.on('change', event => {
        if (event.target.value) {
          this.required(true);
        } else {
          this.required(false);
        }
      }
    )
  }
}

class CustomerContactInfo {
  constructor($root) {
    new DuplicateCustomerCheck($root);

    const $executiveRole = $root.find('#executiverole');
    if ($executiveRole.exists()) {
      new NameFields($executiveRole, $root.find('#executivedetail_name'), $root.find('#executivedetail_surname'));
    }

    const $deputyRole = $root.find('#deputyrole');
    if ($deputyRole.exists()) {
      new NameFields($deputyRole, $root.find('#deputydetail_name'), $root.find('#deputydetail_surname'));
    }
  }
}

$(() => {
  $(document).on('rp-tabs:loaded', (event, data) => {
    if (data.$tab?.is('#customer-tabs-basicdata-tab')) {
      new CustomerDetail(data.$root);
      new CustomerContactInfo(data.$root);
    }
  });
});
