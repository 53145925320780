<script setup>
import { computed } from 'vue';
import ActivityStreamCardEmail from './ActivityStreamCardEmail.vue';
import ActivityStreamCardInteraction from './ActivityStreamCardInteraction.vue';
import ActivityStreamCardTask from './ActivityStreamCardTask.vue';
import ActivityStreamItemDate from './ActivityStreamItemDate.vue';
import ActivityStreamItemEvent from './ActivityStreamItemEvent.vue';
import { BaseItem, DateItem } from './Types';

const props = defineProps({
  item: {
    type: [BaseItem, DateItem],
    required: true
  }
});

const dot = computed(() => props.item.type === 'event');

const icon = computed(() => {
  switch (props.item.type) {
    case 'email':
      return 'mail';
    case 'task':
      return 'task';
    case 'interaction':
      return 'group';
    // no icon for other types
  }
});

const component = computed(() => {
  switch (props.item.type) {
    case 'email':
      return ActivityStreamCardEmail;
    case 'task':
      return ActivityStreamCardTask;
    case 'interaction':
      return ActivityStreamCardInteraction;
    case 'event':
      return ActivityStreamItemEvent;
    case 'date':
      return ActivityStreamItemDate;
    default:
      throw new Error('Unknown item type: ' + props.item.type);
  }
});
</script>

<template>
  <div class="rpvue-ActivityStreamListItem">
    <div class="rpvue-ActivityStreamListItem-indicator"
         :class="{ 'rpvue-ActivityStreamListItem-indicator--dot': dot }"
         v-if="icon || dot">
      <rp-icon class="rpvue-ActivityStreamListItem-indicatorIcon" :name="icon" v-if="icon"></rp-icon>
    </div>
    <div class="rpvue-ActivityStreamListItem-content">
      <component :is="component" :item="item"/>
    </div>
  </div>
</template>
