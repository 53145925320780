import $ from 'jquery';
import { formatAttrSelector } from '../../utils/functions';

const PAYMENT_DEADLINE_TYPE = 'data-payment-deadline-type';
const PAYMENT_DEADLINE_DATE = 'data-payment-deadline-date';
const PAYMENT_DEADLINE_MILESTONE = 'data-payment-deadline-milestone';
const PAYMENT_DEADLINE_MILESTONE_OFFSET = 'data-payment-deadline-milestone-offset';
const PAYMENT_DEADLINE_DOCUMENT = 'data-payment-deadline-document';
const PAYMENT_DEADLINE_DOCUMENT_OFFSET = 'data-payment-deadline-document-offset';

const TYPE_DATE = '1';
const TYPE_MILESTONE = '2';
const TYPE_DOCUMENT = '3';

class PaymentDeadline {
  constructor($root, group) {
    this.$type = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_TYPE, group));
    this.$date = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_DATE, group));
    this.$milestone = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_MILESTONE, group));
    this.$milestoneOffset = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_MILESTONE_OFFSET, group));
    this.$document = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_DOCUMENT, group));
    this.$documentOffset = $root
      .find(formatAttrSelector(PAYMENT_DEADLINE_DOCUMENT_OFFSET, group));

    this.bindEvents();
    this.toggleFields();
  }

  bindEvents() {
    this.$type.on('change', () => {
      this.toggleFields();
    });

    this.$milestone.on('change', () => {
      this.handleOffset(this.$milestone, this.$milestoneOffset);
    });

    this.$document.on('change', () => {
      this.handleOffset(this.$document, this.$documentOffset);
    });
  }

  toggleFields() {
    const type = this.$type.val();

    this.toggleField(this.$date, type !== TYPE_DATE);
    this.toggleField(this.$milestone, type !== TYPE_MILESTONE);
    this.toggleField(this.$milestoneOffset, type !== TYPE_MILESTONE);
    this.toggleField(this.$document, type !== TYPE_DOCUMENT);
    this.toggleField(this.$documentOffset, type !== TYPE_DOCUMENT);
  }

  toggleField($field, disabled) {
    $field.disabled(disabled);
    $field.closest('.form-group').hidden(disabled);
  }

  handleOffset($field, $fieldOffset) {
    if ($field.val()) {
      if (!$fieldOffset.val()) {
        $fieldOffset.val('0');
      }
      $fieldOffset.required();
    } else {
      $fieldOffset.required(false).val('');
    }
  }
}

class PaymentDeadlines {
  static init($root) {
    $root.find('[' + PAYMENT_DEADLINE_TYPE + ']').each(function() {
      new PaymentDeadline($root, $(this).attr(PAYMENT_DEADLINE_TYPE));
    });
  }
}

$(() => {
  PaymentDeadlines.init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    PaymentDeadlines.init(data.$root);
  });
});
