<script setup>
import { IconButton, IconButtonSize } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { inject } from 'vue';

defineProps({
  expanded: Boolean
});

defineEmits(['toggleExpanded']);

const draftSaved = inject('draftSaved');
const deleteDraft = inject('deleteDraft');
const closeDraft = inject('closeDraft');

</script>

<template>
  <div class="rpvue-ActivityStreamEmailDraftHeaderControls">
    <div class="rpvue-ActivityStreamEmailDraftHeaderControls-control" v-if="draftSaved">
      <span class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
        {{ I18N.t('email_draft_saved') }}
      </span>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftHeaderControls-control">
      <IconButton icon="delete" :size="IconButtonSize.MD" @click="deleteDraft"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftHeaderControls-control">
      <div class="rpvue-ActivityStreamEmailDraftHeaderControls-divider"></div>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftHeaderControls-control">
      <IconButton :icon="expanded ? 'unfold_less' : 'unfold_more'"
                  :size="IconButtonSize.MD"
                  @click="$emit('toggleExpanded')"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraftHeaderControls-control">
      <IconButton icon="close" :size="IconButtonSize.MD" @click="closeDraft"/>
    </div>
  </div>
</template>
