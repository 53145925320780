import $ from 'jquery';

const AVATAR_ATTR = 'data-avatar-value';
const AVATAR_SELECTOR = '.rp-avatar[' + AVATAR_ATTR + ']';

const PRIMARY_SATURATION = 95;
const PRIMARY_LIGHTNESS = 34;

const SECONDARY_SATURATION = 65;
const SECONDARY_LIGHTNESS = 95;

const HUE_STEP = 25;
const HUE_STEP_MIN = 1;
const HUE_STEP_MAX = 13;

function calculateHash(value) {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}

function normalizeHash(hash, min, max) {
  return Math.floor((hash % (max - min)) + min);
}

export default class Avatar {
  constructor(value) {
    this.value = value;
  }

  getPrimaryColor() {
    return `hsl(${this.getHue()} ${PRIMARY_SATURATION}% ${PRIMARY_LIGHTNESS}%)`;
  }

  getSecondaryColor() {
    return `hsl(${this.getHue()} ${SECONDARY_SATURATION}% ${SECONDARY_LIGHTNESS}%)`;
  }

  getHue() {
    return normalizeHash(calculateHash(this.value), HUE_STEP_MIN, HUE_STEP_MAX) * HUE_STEP;
  }
}

function init($avatar) {
  $avatar.css('background', new Avatar($avatar.attr(AVATAR_ATTR)).getPrimaryColor());
}

$(()=> {
  $(AVATAR_SELECTOR).each(function() {
    init($(this));
  });

  $(document).on('rp-partial:loaded', (event, data) => {
    data.$root.find(AVATAR_SELECTOR).each(function() {
      init($(this));
    });
  });

  $(document).on('rp-table:loaded', event => {
    $(event.target).find(AVATAR_SELECTOR).each(function() {
      init($(this));
    });
  });

  $(document).on('rp-content:loaded', event => {
    $(event.target).find(AVATAR_SELECTOR).each(function() {
      init($(this));
    });
  });
});
