import $ from 'jquery';

const SELECTOR = '.input-group-prepend, .input-group-append';

class InputGroupAddon {
  constructor($element) {
    this.$element = $element;

    this.bindEvents();
  }

  bindEvents() {
    this.observer = new MutationObserver(() => this.update());
    this.observer.observe(this.$element.get(0), {
      childList: true
    });
  }

  update() {
    if (!this.$element.children().exists()) {
      // clear potential whitespaces to make sure :empty CSS selector works
      this.$element.empty();
    }
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $element = $(this);
    const inputGroupAddon = new InputGroupAddon($element);

    $element.data('rp-input-group-addon', inputGroupAddon);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
