import SingleSelection from 'select2/src/js/select2/selection/single';
import MultipleSelection from 'select2/src/js/select2/selection/multiple';
import AllowClear from 'select2/src/js/select2/selection/allowClear';
import Search from 'select2/src/js/select2/selection/search';
import Utils from 'select2/src/js/select2/utils';

// ===================================
// ===== SingleSelection patches =====
// ===================================

SingleSelection.prototype.render = function() {
  const $selection = SingleSelection.__super__.render.call(this);

  $selection.addClass('select2-selection--single');

  $selection.append(`
    <span class="select2-selection__rendered"></span>
    <rp-icon name="arrow_drop_down" class="select2-selection__arrow"></rp-icon>
  `);

  return $selection;
};

SingleSelection.prototype.update = function(data) {
  this.clear();

  if (data.length === 0) {
    return;
  }

  const $rendered = this.$selection.find('.select2-selection__rendered');

  $rendered.append(this.display(data[0], $rendered));
};

// =====================================
// ===== MultipleSelection patches =====
// =====================================

MultipleSelection.prototype.render = function() {
  const $selection = MultipleSelection.__super__.render.call(this);

  $selection.addClass('select2-selection--multiple');
  $selection.append('<div class="select2-selection__rendered"></div>');

  return $selection;
};

const multipleSelectionBind = MultipleSelection.prototype.bind;

MultipleSelection.prototype.bind = function(container, $container) {
  multipleSelectionBind.call(this, container, $container);

  this.$selection.on('click', '.select2-selection__choice__link', event => event.stopPropagation());
  this.$selection.on('click', '.select2-selection__choice__remove', event => event.preventDefault());
};

MultipleSelection.prototype.selectionContainer = function(item) {
  if (item?.url) {
    return $(`
      <a href="${item.url}" class="select2-selection__choice select2-selection__choice__link">
        <rp-icon name="close" class="select2-selection__choice__remove"></rp-icon>
      </a>
    `);
  }

  return $(`
    <span class="select2-selection__choice">
      <rp-icon name="close" class="select2-selection__choice__remove"></rp-icon>
    </span>
  `);
};

MultipleSelection.prototype.update = function(data) {
  this.clear();

  if (data.length === 0) {
    return;
  }

  const $choices = data.map(item => {
    const $choice = this.selectionContainer(item);

    $choice.prepend(this.display(item, $choice));

    Utils.StoreData($choice[0], 'data', item);

    return $choice;
  });

  this.$selection.find('.select2-selection__rendered').append($choices);
};

// ==============================
// ===== AllowClear patches =====
// ==============================

AllowClear.prototype.update = function(decorated, data) {
  decorated.call(this, data);

  this.$selection.find('.select2-selection__clear').remove();

  if (data.length === 0 || this.$selection.find('.select2-selection__placeholder').exists()) {
    return;
  }

  const $clear = $('<rp-icon name="close" class="select2-selection__clear"></rp-icon>');

  Utils.StoreData($clear[0], 'data', data);

  this.$selection.find('.select2-selection__rendered').after($clear);
};

// ==========================
// ===== Search patches =====
// ==========================

const searchBind = Search.prototype.bind;

Search.prototype.bind = function (decorated, container, $container) {
  searchBind.call(this, decorated, container, $container);

  container.on('close', () => this.resizeSearch());
};

Search.prototype.render = function(decorated) {
  this.$searchContainer = $(`
    <span class="select2-search select2-search--inline">
      <input class="select2-search__field" type="search" tabindex="-1" autocomplete="off" role="searchbox"/>
    </span>
  `);

  this.$search = this.$searchContainer.find('input');

  const $rendered = decorated.call(this);

  this._transferTabIndex();

  return $rendered;
};

Search.prototype.resizeSearch = function() {
  this.$search.css('width', '25px');

  const text = this.$search.val() || this.$search.attr('placeholder') || '';
  const width = ((text.length + 1) * 0.75) + 'em';

  this.$search.css('width', width);
};
