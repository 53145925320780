import $ from 'jquery';
import { formatAttrSelector } from '../utils/functions';

const TOGGLEABLE_CONTENT_ORIGINAL_ATTRIBUTE_NAME = 'data-toggleable-content-original';
const TOGGLEABLE_CONTENT_BUTTON_SHOW_ATTRIBUTE_NAME = 'data-toggleable-content-button-show';
const TOGGLEABLE_CONTENT_BUTTON_HIDE_ATTRIBUTE_NAME = 'data-toggleable-content-button-hide';
const TOGGLEABLE_CONTENT_SUBSTITUTE_ATTRIBUTE_NAME = 'data-toggleable-content-substitute';

class ToggleableContent {
  constructor($originalContent, $substituteContent, $showButton, $hideButton) {
    this.$originalContent = $originalContent;
    this.$substituteContent = $substituteContent;
    this.$showButton = $showButton;
    this.$hideButton = $hideButton;

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    if (this.$showButton.exists()) {
      this.$showButton.on('click', () => this.hideSubstituteContent(false));
    }

    if (this.$hideButton.exists()) {
      this.$hideButton.on('click', () => this.hideSubstituteContent(true));
    }
  }

  init() {
    this.$substituteContent.hidden(true);
    this.$substituteContent.disabled(true);
  }

  hideSubstituteContent(hide) {
    this.$originalContent.hidden(!hide);
    this.$originalContent.disabled(!hide);

    this.$substituteContent.hidden(hide);
    this.$substituteContent.disabled(hide);
  }
}

function init($root) {
  $root.find('[' + TOGGLEABLE_CONTENT_ORIGINAL_ATTRIBUTE_NAME + ']').each(function() {
    const group = $(this).attr(TOGGLEABLE_CONTENT_ORIGINAL_ATTRIBUTE_NAME);

    const $showButton = $root.find(formatAttrSelector(TOGGLEABLE_CONTENT_BUTTON_SHOW_ATTRIBUTE_NAME, group));
    const $hideButton = $root.find(formatAttrSelector(TOGGLEABLE_CONTENT_BUTTON_HIDE_ATTRIBUTE_NAME, group));
    const $substituteContent = $root.find(formatAttrSelector(TOGGLEABLE_CONTENT_SUBSTITUTE_ATTRIBUTE_NAME, group));

    if (group && $substituteContent.exists()) {
      new ToggleableContent($(this), $substituteContent, $showButton, $hideButton);
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});