import $ from 'jquery';
import Numbers from '../../core/numbers';
import { formatAttrSelector } from '../../utils/functions';

const SELECTOR_PAYMENT_AMOUNT = 'data-payment-vat-calculator-amount';
const SELECTOR_PAYMENT_VAT_RATE = 'data-payment-vat-calculator-vat-rate';
const SELECTOR_PAYMENT_VAT_AMOUNT = 'data-payment-vat-calculator-vat-amount';

class PaymentVatCalculator {
  constructor($amount, $vatRate, $vatAmount) {
    this.$amount = $amount;
    this.$vatRate = $vatRate;
    this.$vatAmount = $vatAmount;

    this.bindEvents();
    this.init();
  }

  init() {
    const vatAmount = Numbers.parse(this.$vatAmount.val());
    const vatRate = Numbers.parse(this.$vatRate.val());

    if (vatAmount && !vatRate) {
      this.recalculateVatRate();
    }
    if (vatRate && !vatAmount) {
      this.recalculateVatAmount();
    }
  }

  bindEvents() {
    this.$amount.on('change', () => {
      this.recalculateVatAmount(true);
    });

    this.$vatRate.on('change', () => {
      this.recalculateVatAmount(true);
    });

    this.$vatAmount.on('change', () => {
      this.recalculateVatRate(true);
    });
  }

  recalculateVatRate(highlight) {
    const amount = Numbers.parse(this.$amount.val());
    const vatAmount = Numbers.parse(this.$vatAmount.val());
    const vatRate = vatAmount / (amount - vatAmount) * 100;

    this.$vatRate.val(Numbers.format(vatRate));
    if (highlight) {
      this.$vatRate.highlight();
    }
  }

  recalculateVatAmount(highlight) {
    const amount = Numbers.parse(this.$amount.val());
    const vatRate = Numbers.parse(this.$vatRate.val());
    const vatAmount = amount * vatRate / (100 + vatRate);

    this.$vatAmount.val(Numbers.format(vatAmount));
    if (highlight) {
      this.$vatAmount.highlight();
    }
  }
}

class PaymentVatCalculators {
  static init($root) {
    const self = this;

    $root.find('[' + SELECTOR_PAYMENT_AMOUNT + ']').each(function() {
      const group = $(this).attr(SELECTOR_PAYMENT_AMOUNT);

      const $amount = $root
        .find(formatAttrSelector(SELECTOR_PAYMENT_AMOUNT, group));
      const $vatRate = $root
        .find(formatAttrSelector(SELECTOR_PAYMENT_VAT_RATE, group));
      const $vatAmount = $root
        .find(formatAttrSelector(SELECTOR_PAYMENT_VAT_AMOUNT, group));

      if ($amount.exists() && $vatRate.exists() && $vatAmount.exists()) {
        new PaymentVatCalculator($amount, $vatRate, $vatAmount);
      }
    });
  }
}

$(() => {
  PaymentVatCalculators.init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    PaymentVatCalculators.init(data.$root);
  });
});
