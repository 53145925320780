import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';
import { formatAttrSelector } from 'utils/functions';

const SELECTOR_TYPE_COPY_FROM_ROLE = '#type-copy-from-role';

const ATTR_VALUE_TYPE = 'type';

class InviteUserModal {
  constructor($modal) {
    this.$modal = $modal;

    this.$type = this.$modal.find(formatAttrSelector('name', ATTR_VALUE_TYPE));
    this.$typeCopyFromRole = this.$modal.find(SELECTOR_TYPE_COPY_FROM_ROLE);

    this.$role = this.$modal.find('#role');
    this.$roleFormGroup = this.$role.closest('.form-group');
    this.$roleHelpText = this.$roleFormGroup.find('.form-help');

    this.$user = this.$modal.find('#user');
    this.$userFormGroup = this.$user.closest('.form-group');
    this.$userHelpText = this.$userFormGroup.find('.form-help');

    this.bindEvents();
    this.update();
  }

  bindEvents() {
    this.$type.on('change', () => this.update());
    this.$user.on('change', () => this.updateHelpText(this.$user, 'API_PERMISSION_GROUPS_USER',
      this.$user.val(), this.$userHelpText));
    this.$role.on('change', () => this.updateHelpText(this.$role, 'API_PERMISSION_GROUPS_ROLE',
      this.$role.val(), this.$roleHelpText));
  }

  update() {
    if (this.$typeCopyFromRole.is('.active')) {
      this.updateFields(this.$user, this.$userFormGroup, this.$roleFormGroup, this.$roleHelpText);
    } else {
      this.updateFields(this.$role, this.$roleFormGroup, this.$userFormGroup, this.$userHelpText);
    }
  }

  updateFields($selectToClear, $selectToHide, $selectToDisplay, $helpTextToHide) {
    // Clear value
    $selectToClear.val('');

    this.toggleField($selectToHide, true);
    this.toggleField($selectToDisplay, false);

    // Hide help text under select as it does not contain necessary information because nothing is selected yet
    $helpTextToHide.hidden(true);
  }

  toggleField($field, disabled) {
    // Hide and disable input when hiding it, so it's not submitted
    $field.hidden(disabled);
    $field.disabled(disabled);
  }

  updateHelpText($select, key, id, $helpTextElement) {
    // If select has any value
    if ($select.val()) {
      // Call an API to get permissions group
      Fetch.post(Router.get(key, { id }))
        .then(response => {
          if (response.ok) {
            const groups = response.body['groups'];
            // If there is at least one group we will update help text and display it to the user
            if (groups) {
              $helpTextElement.find('.permissions-groups').text(groups)
              $helpTextElement.hidden(false);
            } else {
              $helpTextElement.hidden(true);
            }
          }
        })
        .catch(error => Log.error(error));
    } else {
      $helpTextElement.hidden(true);
    }
  }

}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#invite-users-modal-form')) {
      new InviteUserModal(data.$root);
    }
  });
});
