import Log from 'core/log';
import $ from 'jquery';
import 'flatpickr';

import { Bulgarian } from 'flatpickr/dist/l10n/bg';
import { Czech } from 'flatpickr/dist/l10n/cs';
import { German } from 'flatpickr/dist/l10n/de';
import { French } from 'flatpickr/dist/l10n/fr';
import { Hungarian } from 'flatpickr/dist/l10n/hu';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { Romanian } from 'flatpickr/dist/l10n/ro';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { Slovenian } from 'flatpickr/dist/l10n/sl';
import { Ukrainian } from 'flatpickr/dist/l10n/uk';

const LOCALES = {
  bg: Bulgarian,
  cs: Czech,
  de: German,
  fr: French,
  hu: Hungarian,
  pl: Polish,
  ro: Romanian,
  ru: Russian,
  sk: Slovak,
  sl: Slovenian,
  uk: Ukrainian
};

const OPTIONS_DATE_PICKER = {
  allowInput: true,
  dateFormat: 'Y-m-d',
  errorHandler: errorHandler
};

const OPTIONS_DATE_TIME_PICKER = {
  allowInput: true,
  dateFormat: 'Y-m-d H:i',
  enableTime: true,
  time_24hr: true,
  errorHandler: errorHandler
};

const SELECTOR_DATE_PICKER = '[data-auto-init="date-picker"]';
const SELECTOR_DATE_TIME_PICKER = '[data-auto-init="date-time-picker"]';
const SELECTOR_DATE_RANGE = '[data-auto-init="date-range"]';

function errorHandler(error) {
  // ignore invalid date errors
  if (error.message.includes('Invalid date')) {
    return;
  }
  Log.error(error);
}

function resolveLocale($element) {
  return $element.prop('lang') || $element.closest('[lang]').prop('lang');
}

function initDatePicker($element, options) {
  const locale = LOCALES[resolveLocale($element)];
  if (locale) {
    options.locale = locale;
  }

  if ($element.attr('data-min-date')) {
    options.minDate = $element.attr('data-min-date');
  }

  if ($element.attr('data-max-date')) {
    options.maxDate = $element.attr('data-max-date');
  }

  const flatpickr = $element.flatpickr(options);

  $element.data('flatpickr', flatpickr);

  $element.on('change', () => {
    flatpickr.setDate($element.val(), false);
  });

  $element.on('update', () => {
    if ($element.attr('data-min-date')) {
      flatpickr.set('minDate', $element.attr('data-min-date'));
    } else {
      flatpickr.set('minDate', null);
    }

    if ($element.attr('data-max-date')) {
      flatpickr.set('maxDate', $element.attr('data-max-date'));
    } else {
      flatpickr.set('maxDate', null);
    }
  });
}

function initDateRange($element) {
  const $dateRangeMin = $element.find('.date-range-min');
  const $dateRangeMax = $element.find('.date-range-max');

  $dateRangeMin.on('change', () => {
    $dateRangeMax.attr('data-min-date', $dateRangeMin.val()).trigger('update');
  });

  $dateRangeMax.on('change', () => {
    $dateRangeMin.attr('data-max-date', $dateRangeMax.val()).trigger('update');
  });
}

function init($root) {
  $root.find(SELECTOR_DATE_PICKER).each(function() {
    initDatePicker($(this), {...OPTIONS_DATE_PICKER});
  });

  $root.find(SELECTOR_DATE_TIME_PICKER).each(function() {
    initDatePicker($(this), {...OPTIONS_DATE_TIME_PICKER});
  });

  $root.find(SELECTOR_DATE_RANGE).each(function() {
    initDateRange($(this));
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
