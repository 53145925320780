import { attachErrorHandler } from '@sentry/vue';
import Log from 'core/log';
import Numbers from 'core/numbers';
import $ from 'jquery';
import { createApp } from 'vue';
import VueRegistry from './registry';

import PrimeVue from 'primevue/config'

const VUE_APP_SELECTOR = 'script[type="text/rp-vue-app-template"]';
const VUE_APP_DATA_KEY = 'dataAttrs';

export default class VueLoader {
  static #load(element) {
    if (!element.matches(VUE_APP_SELECTOR)) {
      Log.error('Attempt to load not a Vue app template script');
      return;
    }

    const container = createContainer(element);
    const application = createApplication(element, container);

    element.replaceWith(container);

    application.use(PrimeVue, {
      unstyled: true
    });

    const instance = application.mount(container);

    watchDataAttributes(container, instance);
  }

  static init() {
    $(() => {
      $(VUE_APP_SELECTOR).each(function() {
        VueLoader.#load(this);
      });

      $(document).on('rp-partial:loaded', (event, data) => {
        data.$root.find(VUE_APP_SELECTOR).each(function() {
          VueLoader.#load(this);
        });
      });
    });
  }
}

function createContainer(element) {
  const container = document.createElement('div');

  if (element.id) {
    container.id = element.id;
  }

  if (element.className) {
    container.className = element.className;
  }

  container.classList.add('rp-vue-app-container');

  for (const key of Object.keys(element.dataset)) {
    container.dataset[key] = element.dataset[key];
  }

  return container;
}

function createApplication(element, container) {
  const application = createApp({
    template: element.innerHTML,
    components: VueRegistry.getComponents(),
    data() {
      return {
        [VUE_APP_DATA_KEY]: getDataAttributes(container)
      }
    },
    methods: {
      getDataAttr(attrName) {
        return this[VUE_APP_DATA_KEY][attrName];
      },
      getDataAttrAsBoolean(attrName) {
        const value = this.getDataAttr(attrName);
        return value === '' || value === 'true';
      },
      getDataAttrAsNumber(attrName) {
        const value = this.getDataAttr(attrName);
        if (value) {
          return Numbers.parse(value);
        }
      },
      getDataAttrAsArray(attrName) {
        const value = this.getDataAttr(attrName);
        if (value) {
          try {
            return JSON.parse(value);
          } catch (e) {
            return value.split(',');
          }
        }
      },
      getDataAttrAsObject(attrName) {
        const value = this.getDataAttr(attrName);
        if (value) {
          try {
            return JSON.parse(value);
          } catch (e) {
          }
        }
      },
      setDataAttr(name, value) {
        if (value != null) {
          container.dataset[name] = value;
        } else {
          delete container.dataset[name];
        }
      }
    }
  });

  attachErrorHandler(application, {
    attachProps: true,
    logErrors: true
  });

  return application;
}

function getDataAttributes(container) {
  const attributes = {};

  for (const key of Object.keys(container.dataset)) {
    attributes[key] = container.dataset[key];
  }

  return attributes;
}

function watchDataAttributes(container, instance) {
  const observer = new MutationObserver(() => {
    instance[VUE_APP_DATA_KEY] = getDataAttributes(container);
  });

  observer.observe(container, { attributes: true });
}
