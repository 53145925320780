import $ from 'jquery';

class ProductGraphicsForm {
  constructor($form) {
    this.$form = $form;

    this.bindEvents();
  }

  bindEvents() {
    this.$form.on('rp-single-file-input:change', '.unit-plan', event => {
      $(event.target).closest('.form-control-container').find('.size-error').parent().remove();
    });
  }
}

$(()=> {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $form = data.$root.find('#product-graphics-form');
    if ($form.exists()) {
      new ProductGraphicsForm($form);
    }
  });
});
