import $ from 'jquery';
import Sortable from 'sortablejs';

class ColumnSelect {
  constructor($form) {
    this.$form = $form;
    this.$order = $form.find('input[name=order]');
    this.$ordering = $form.find('.cs-ordering');

    this.init();
  }

  init() {
    const self = this;

    this.$form.find('[data-column]').each(function() {
      self.handleColumn($(this));
    });

    this.sortColumns();
    this.updateOrder();

    Sortable.create(this.$ordering.get(0), {
      onSort: () => {
        this.updateOrder();
      }
    });

    this.$form.on('change', '[data-column]', event => {
      this.handleColumn($(event.target));
    });

    this.$form.on('change', '.cs-group-all', event => {
      const $target = $(event.target);

      $target.closest('.cs-group').find('[data-column]')
        .prop('checked', $target.is(':checked')).trigger('change');
    });
  }

  handleColumn($column) {
    var $badge = this.$ordering
      .find('[data-column=' + $column.attr('data-column') + ']');

    if ($column.is(':checked')) {
      if (!$badge.exists()) {
        this.$ordering.append($('<span>', {
          'class': 'badge badge-primary',
          'data-column' : $column.attr('data-column'),
          'data-priority' : $column.attr('data-priority'),
          'text' : $column.closest('.custom-checkbox').find('label').text()
        }));
      }
    } else {
      $badge.remove();
    }

    this.updateOrder();
  }

  sortColumns() {
    const $columns = this.$ordering.children();

    $columns.sort((column1, column2) => {
      var priority1 = Number.parseInt($(column1).attr('data-priority'), 10);
      var priority2 = Number.parseInt($(column2).attr('data-priority'), 10);

      if (Number.isNaN(priority1) && Number.isNaN(priority2)) {
        return 0;
      }
      if (Number.isNaN(priority1)) {
        return -1;
      }
      if (Number.isNaN(priority2)) {
        return 1;
      }
      if (priority1 > priority2) {
        return 1;
      }
      if (priority1 < priority2) {
        return -1;
      }
      return 0;
    });

    $columns.detach().appendTo(this.$ordering);
  }

  updateOrder() {
    const order = [];

    this.$ordering.children().each(function() {
      order.push($(this).attr('data-column'));
    });

    this.$order.val(order.join());
  }
}

$(() => {
  $(document).on('rp-modal:loaded', event => {
    const $form = $(event.target).find('.column-select form');
    if ($form.exists()) {
      new ColumnSelect($form);
    }
  });
});
