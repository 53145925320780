import $ from 'jquery';

/*
 * Hackish workaround for a bug in select2 with jQuery 3.6.0 nested-focus "protection"
 * See https://github.com/select2/select2/issues/5993 for details
 * TODO update select2 once issue is fixed on their side
 */
$(document).on('select2:open', () => {
  const $search = $('.select2-container--open').last().find('.select2-search__field');
  if ($search.exists()) {
    $search.get(0).focus();
  }
});
