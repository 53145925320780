const PARAM_DATE = 'date';

export default class TaskCalendarUrl {
  constructor(observable, state) {
    this.observable = observable;
    this.state = state;

    this.init();
    this.bindEvents();
  }

  init() {
    const date = this.getUrlState().get(PARAM_DATE);
    if (date) {
      this.state.setDate(date);
    }
  }

  bindEvents() {
    this.observable.on('ready', () => {
      this.observable.on('week-change', () => this.updateUrlState());
    });
  }

  getUrlState() {
    const hash = window.location.hash;

    let state;
    if (hash) {
      state = new URLSearchParams(hash.substring(1));
    } else {
      state = new URLSearchParams();
    }

    return state;
  }

  updateUrlState() {
    const state = this.getUrlState();

    state.set(PARAM_DATE, this.state.getDate());
    window.history.replaceState(null, null, '#' + state.toString());
  }
}
