import I18N from 'core/i18n';
import $ from 'jquery';
import { formatAttrSelector } from 'utils/functions';

const LEGACY_INPUT_SELECTOR = 'input:not([data-never-validate]),select,textarea';
const VUE_INPUT_SELECTOR = '.rp-vue-app-container';
const HIDDEN_SELECTOR = 'input[type=hidden]';

function validate(element) {
  if (element instanceof $) {
    let valid = true;

    element.each(function() {
      valid &= validate(this);
    });

    return valid;
  }

  if (element.matches('form')) {
    let valid = true;

    element.querySelectorAll(`${LEGACY_INPUT_SELECTOR},${VUE_INPUT_SELECTOR}`).forEach(child => {
      valid &= validate(child);
    });

    return valid;
  }

  const vueInput = element.closest(VUE_INPUT_SELECTOR);
  if (vueInput) {
    const valid = isValidVueInput(vueInput);

    setClassesVueInput(vueInput, valid);
    setMessage(vueInput, valid);

    return valid;
  }

  if (element.matches(LEGACY_INPUT_SELECTOR)) {
    const valid = isValidLegacyInput(element);

    setClassesLegacyInput(element, valid);
    setMessage(element, valid);

    return valid;
  }

  return true;
}

function isValidVueInput(element) {
  if (element.dataset.isValidBackend === 'false') {
    return false;
  }

  if (isRequiredVueInput(element)) {
    if (element.dataset.value) {
      element.dataset.isValid = 'true'
      return true;
    } else {
      element.dataset.isValid = 'false'
      return false;
    }
  }

  element.dataset.isValid = 'true'
  return true;
}

function isRequiredVueInput(element) {
  const required = element.dataset.required === '' || element.dataset.required === 'true';
  const disabled = element.dataset.disabled === '' || element.dataset.disabled === 'true';

  return required && !disabled;
}

function isValidLegacyInput(element) {
  if (typeof element.checkValidity !== 'function') {
    return true;
  }

  // checkValidity() always returns true for hidden inputs
  // instead we check ValidityState + required attribute manually
  if (element.matches(HIDDEN_SELECTOR)) {
    if (!element.validity.valid) {
      return false;
    }
    if (element.matches('[required]:not([disabled])')) {
      return !!element.value;
    }
    return true;
  }

  return element.checkValidity();
}

function setClassesVueInput(element, valid) {
  const container = element.closest('.form-control-container');
  if (container) {
    container.classList.toggle('is-invalid', !valid);
  }
}

function setClassesLegacyInput(element, valid) {
  element.classList.toggle('is-invalid', !valid);

  if (element.classList.contains('file-input-hidden')) {
    element.parentNode.querySelectorAll('.custom-file-input,.file-input-preview')
      .forEach(input => input.classList.toggle('is-invalid', !valid));
  }

  if (element.classList.contains('rp-radio-btn-input')) {
    const group = element.closest('.rp-radio-btn-group');
    if (group) {
      group.classList.toggle('is-invalid', !valid);
    }
  }

  const container = element.closest('.form-control-container');
  if (container) {
    container.classList.toggle('is-invalid', !valid);
  }
}

function setMessage(element, valid) {
  const container = element.closest('.form-control-container');
  if (container) {
    const feedback = container.querySelector('.invalid-feedback');
    if (feedback) {
      feedback.innerHTML = getMessage(element, valid);
    }
  }
}

function getMessage(element, valid) {
  if (valid) {
    return '';
  }

  const vueInput = element.closest(VUE_INPUT_SELECTOR);
  if (vueInput) {
    return getMessageVueInput(vueInput);
  }

  return getMessageLegacyInput(element);
}

function getMessageVueInput(element) {
  if (element.dataset.validationMessage) {
    return element.dataset.validationMessage;
  }

  if (isRequiredVueInput(element) && !element.dataset.value) {
    return I18N.t('form_validation_required');
  }

  return I18N.t('form_validation_not_valid');
}

function getMessageLegacyInput(element) {
  const state = element.validity;

  if (state.customError) {
    return element.dataset.validationMessage || I18N.t('form_validation_not_valid');
  }

  if (state.patternMismatch) {
    return element.dataset.patternMessage || I18N.t('form_validation_not_valid');
  }

  if (state.tooLong) {
    return I18N.t('form_validation_max_length', element.maxLength);
  }

  if (state.tooShort) {
    return I18N.t('form_validation_min_length', element.minLength);
  }

  if (state.typeMismatch && element.type === 'email') {
    return I18N.t('form_validation_email');
  }

  if (state.valueMissing) {
    return I18N.t('form_validation_required');
  }

  if (element.matches(HIDDEN_SELECTOR) && element.matches('[required]')) {
    return I18N.t('form_validation_required');
  }

  return I18N.t('form_validation_not_valid');
}

function setError(element, message) {
  if (element instanceof $) {
    element.each(function() {
      setError(this, message);
    });

    return;
  }

  const vueInput = element.closest(VUE_INPUT_SELECTOR);
  if (vueInput) {
    setErrorVueInput(vueInput, message);
  } else {
    setErrorLegacyInput(element, message);
  }

  validate(element);
}

function setErrorVueInput(element, message) {
  element.dataset.isValid = message ? 'false' : 'true';
  element.dataset.isValidBackend = message ? 'false' : 'true';
  element.dataset.validationMessage = message || '';
}

function setErrorLegacyInput(element, message) {
  if (!element.matches(LEGACY_INPUT_SELECTOR)) {
    return;
  }
  if (typeof element.setCustomValidity !== 'function') {
    return;
  }

  element.setCustomValidity(message || '');
  element.dataset.validationMessage = message || '';
}

function setErrors(root, errors = {}) {
  if (root instanceof $) {
    root.each(function() {
      setErrors(this, errors);
    });

    return;
  }

  for (const [name, message] of Object.entries(errors)) {
    const element = root.querySelector(formatAttrSelector('name', name));
    if (element) {
      setError(element, message);
    }
  }
}

export default class Validation {
  static validate(element) {
    return validate(element);
  }

  static setError(element, message) {
    setError(element, message);
  }

  static setErrors(root, errors) {
    setErrors(root, errors);
  }
}

$(() => {
  $(document).on('submit', 'form.validated:not(.inline-form,.modal-form)', event => {
    if (!Validation.validate(event.target)) {
      event.preventDefault();
    }
  });

  $(document).on('change', 'form.validated :input', event => {
    const element = event.target;

    Validation.setError(element, null);
    Validation.validate(element);
  });
});
