import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';

class Tabs {
  constructor($element) {
    this.id = $element.attr('id');
    this.activeTabId = null;

    this.tabs = this.loadTabs($element);
    this.parentTab = $element.closest('.tab-pane').data('rp-tab');

    this.bindEvents();
    this.init();
  }

  init() {
    const tabId = this.loadState().get(this.id);
    const tab = this.tabs.get(tabId);

    if (tab) {
      tab.show();
    } else {
      // show first tab by default
      this.tabs.values().next().value.show();
    }

    for (const tab of this.tabs.values()) {
      tab.handleReadOnly();
    }
  }

  bindEvents() {
    $(window).on('hashchange', () => {
      const tabId = this.loadState().get(this.id);
      const tab = this.tabs.get(tabId);

      // we rely on the following facts:
      //  - event listeners will not be called for programmatic changes to hash
      //  - event listeners will be called in order they were added
      //  - parent tabs will add event listeners before child tabs
      if (tab) {
        tab.show();

        // show parent tab even if tab is already active
        if (this.parentTab) {
          this.parentTab.show();
        }
      }
    });
  }

  onShow(tabId) {
    // save state on tab change
    if (this.activeTabId) {
      this.saveState(tabId);

      // show parent tab on tab change
      if (this.parentTab) {
        this.parentTab.show();
      }
    }

    this.activeTabId = tabId;
  }

  loadTabs($element) {
    const container = this;
    const tabs = new Map();

    $element.find('> .nav-tabs > .nav-link').each(function() {
      const tabId = $(this).attr('data-tab');

      const $tab = $element.find(`#${container.id}-${tabId}-tab`);
      const $pane = $element.find(`#${container.id}-${tabId}-pane`);

      tabs.set(tabId, new Tab(container, tabId, $tab, $pane));
    });

    return tabs;
  }

  loadState() {
    const hash = window.location.hash;
    if (hash) {
      return new URLSearchParams(hash.substring(1));
    }

    return new URLSearchParams();
  }

  saveState(tabId) {
    const state = this.loadState();

    state.set(this.id, tabId);
    window.history.replaceState(null, null, '#' + state.toString());
  }
}

class Tab {
  constructor(container, id, $tab, $pane) {
    this.container = container;
    this.id = id;

    this.$tab = $tab;
    this.$pane = $pane;

    this.$tab.data('rp-tab', this);
    this.$pane.data('rp-tab', this);

    this.bindEvents();
  }

  bindEvents() {
    this.$tab.on('show.bs.tab', event => {
      if (this.$tab.is(event.target)) {
        this.container.onShow(this.id);
        this.handleLoad();
      }
    });
  }

  show() {
    this.$tab.tab('show');
  }

  handleLoad() {
    const url = this.$tab.attr('data-tab-url');
    if (Fetch.isValidUrl(url)) {
      Fetch.getHtml(url)
        .then(response => {
          if (response.ok) {
            this.$tab.removeAttr('data-tab-url');
            this.$pane.html(response.body);

            this.handleReadOnly();

            this.$pane.trigger('rp-partial:loaded', {
              $root: this.$pane
            });

            this.$pane.trigger('rp-tabs:loaded', {
              $tab: this.$tab,
              $root: this.$pane
            });
          }
        })
        .catch(error => Log.error(error));
    }
  }

  handleReadOnly() {
    if (this.$tab.attr('data-readonly')) {
      this.$pane.disabled();
    }
  }
}

function init($root) {
  $root.find('.rp-tabs').each(function() {
    const $element = $(this);
    const tabs = new Tabs($element);

    $element.data('rp-tabs', tabs);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-tabs:loaded', (event, data) => {
    init(data.$root);
  });
});
