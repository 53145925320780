<script setup>
import Dates from 'core/dates';
import I18N from 'core/i18n';
import { computed } from 'vue';
import { DateItem } from './Types';

const props = defineProps({
  item: {
    type: DateItem,
    required: true
  }
});

const text = computed(() => {
  const date = Dates.parse(props.item.date);

  if (date.isToday()) {
    return I18N.t('date_today');
  }

  if (date.isYesterday()) {
    return I18N.t('date_yesterday');
  }

  return Dates.format(date);
});
</script>

<template>
  <div class="rpvue-u-TextAlignCenter rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
    {{ text }}
  </div>
</template>
