import $ from 'jquery';

const INDETERMINATE_SELECTOR = 'input[data-indeterminate="true"]';

$(()=> {
  $(INDETERMINATE_SELECTOR).each(function() {
    $(this).prop('indeterminate', true);
  });

  $(document).on('rp-partial:loaded', (event, data) => {
    data.$root.find(INDETERMINATE_SELECTOR).each(function() {
      $(this).prop('indeterminate', true);
    });
  });

  $(document).on('rp-table:loaded', event => {
    $(event.target).find(INDETERMINATE_SELECTOR).each(function() {
      $(this).prop('indeterminate', true);
    });
  });
});
