import I18N from '../../core/i18n';

export default class Pagination {
  constructor(table) {
    this.table = table;

    this.$pageNumberInfo = table.$element.find('.rp-table-page-number label');
    this.$pageNumberInput = table.$element.find('.rp-table-page-number input');
    this.$pagePrev = table.$element.find('.rp-table-page-prev');
    this.$pageNext = table.$element.find('.rp-table-page-next');
    this.$pageSize = table.$element.find('.rp-table-page-size');
    this.$pageSizeSelect = table.$element.find('.rp-table-page-size select');

    this._totalCount = 0;

    this.pageSize = this.parsePageSize();
    this.pageNumber = this.parsePageNumber();

    this.bindEvents();
  }

  get pageNumber() {
    return this._pageNumber;
  }

  set pageNumber(value) {
    this._pageNumber = value;
    this.$pageNumberInput.val(value);

    this.updatePageNumberInfo();
    this.updatePageButtons();
  }

  get pageSize() {
    return this._pageSize;
  }

  set pageSize(value) {
    this._pageSize = value;
    this.pageNumber = 1;

    this.updatePageSizeWarning();
  }

  get totalCount() {
    return this._totalCount;
  }

  set totalCount(value) {
    this._totalCount = value;

    this.updatePageNumberInfo();
    this.updatePageButtons();
  }

  get pageCount() {
    if (this.totalCount === 0) {
      return 1;
    }

    let pageCount = Math.floor(this.totalCount / this.pageSize);
    if (this.totalCount % this.pageSize !== 0) {
      pageCount += 1;
    }

    return pageCount;
  }

  get offset() {
    return (this.pageNumber - 1) * this.pageSize;
  }

  get limit() {
    return this.pageSize;
  }

  parsePageNumber() {
    const value = Number.parseInt(this.$pageNumberInput.val(), 10);

    if (Number.isNaN(value)) {
      return this.pageNumber;
    }

    return Math.min(Math.max(value, 1), this.pageCount);
  }

  updatePageNumber() {
    const pageNumber = this.parsePageNumber();
    this.$pageNumberInput.val(pageNumber);

    if (pageNumber !== this.pageNumber) {
      this.pageNumber = pageNumber;
      this.table.load();
    }
  }

  parsePageSize() {
    return Number.parseInt(this.$pageSizeSelect.val(), 10);
  }

  updatePageSize() {
    const pageSize = this.parsePageSize();

    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.table.load();
    }
  }

  updatePageNumberInfo() {
    this.$pageNumberInfo.text(I18N.t('table_pagination_page_number',
      this.pageNumber, this.pageCount))
  }

  updatePageButtons() {
    this.$pagePrev.disabled(this.pageNumber <= 1);
    this.$pageNext.disabled(this.pageNumber >= this.pageCount);
  }

  updatePageSizeWarning() {
    if (this.pageSize > 25) {
      this.$pageSize.addClass('warn');
    } else {
      this.$pageSize.removeClass('warn');
    }
  }

  bindEvents() {
    this.$pageNumberInput.on('change', () => this.updatePageNumber());
    this.$pageSizeSelect.on('change', () => this.updatePageSize());

    this.$pagePrev.on('click', () => {
      this.pageNumber -= 1;
      this.table.load();
    });

    this.$pageNext.on('click', () => {
      this.pageNumber += 1;
      this.table.load();
    });
  }
}
