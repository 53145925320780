import $ from 'jquery';
import Fetch from '../core/fetch';
import { createError, delay, randomId } from '../utils/functions';

const KEY_ESCAPE = 'Escape';

export default class Offcanvas {
  constructor(url) {
    this.url = url;
    this.instanceId = randomId();

    this.$document = $(document);
    this.$body = $(document.body);
  }

  open() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.load(content => this.create(content));
    });
  }

  load(callback) {
    Fetch.getHtml(this.url)
      .then(response => {
        if (response.ok) {
          callback(response.body);
        } else {
          this.reject(createError('Failed to load offcanvas content', {
            source: 'Offcanvas',
            response: response
          }));
        }
      })
      .catch(reason => this.reject(reason));
  }

  create(content) {
    this.$offcanvas = $(content);
    this.$body.append(this.$offcanvas);

    // close any open offcanvas
    this.$document.trigger('rp-offcanvas:close');

    this.bindEvents();
    this.triggerLoaded();

    delay(10).then(() => {
      this.$offcanvas.addClass('show');
      this.$offcanvas.focus();
    });
  }

  update(content) {
    this.unbindEventsOffcanvas();

    const $old = this.$offcanvas;
    this.$offcanvas = $(content);

    $old.replaceWith(this.$offcanvas);
    this.$offcanvas.addClass('show');

    this.bindEventsOffcanvas();
    this.triggerLoaded();

    delay(10).then(() => this.$offcanvas.focus());
  }

  close() {
    this.$offcanvas.removeClass('show');

    this.$offcanvas.one('transitionend', () => {
      this.unbindEvents();
      this.$offcanvas.remove();
      this.resolve();
    });
  }

  triggerLoaded() {
    this.$offcanvas.trigger('rp-partial:loaded', {
      $root: this.$offcanvas
    });

    this.$offcanvas.trigger('rp-offcanvas:loaded', {
      $root: this.$offcanvas
    });
  }

  bindEvents() {
    this.$document.on(`rp-offcanvas:close.${this.instanceId}`, () => this.close());
    this.$document.on(`rp-content:reload.${this.instanceId}`, () => this.load(content => this.update(content)));

    this.bindEventsOffcanvas();
  }

  bindEventsOffcanvas() {
    this.$offcanvas.on('click.rp-offcanvas', '.rp-offcanvas-close', () => this.close());
    this.$offcanvas.on('keydown.rp-offcanvas', event => {
      if (event.key === KEY_ESCAPE) {
        this.close();
      }
    });
  }

  unbindEvents() {
    this.$document.off(`rp-offcanvas:close.${this.instanceId}`);
    this.$document.off(`rp-content:reload.${this.instanceId}`);

    this.unbindEventsOffcanvas();
  }

  unbindEventsOffcanvas() {
    this.$offcanvas.off('click.rp-offcanvas');
    this.$offcanvas.off('keydown.rp-offcanvas');
  }

}
