import $ from 'jquery';
import DuplicateCustomerCheck from '../customer/duplicateCustomerCheck';
import RequiredInputGroup from '../../components/requiredInputGroup';

class NewPersonModalForm {
  constructor($modal) {
    new DuplicateCustomerCheck($modal);
    new RequiredInputGroup($modal, 'contact');
  }
}

class NewLegalEntityModalForm {
  constructor($modal) {
    new RequiredInputGroup($modal, 'contact');
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#new-person-modal-form")) {
      new NewPersonModalForm(data.$root);
    }

    if (data.$root.is("#new-legal-entity-modal-form")) {
      new NewLegalEntityModalForm(data.$root);
    }
  });
});