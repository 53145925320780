import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';
import { createError, delay } from 'utils/functions';
import Confirmation from './confirmation';
import ResponseCommand from './responseCommand';
import Validation from './validation';

const SUBMIT_DELAY = 2000;

export default class FormSubmit {
  constructor(event) {
    this.event = event;
    this.$form = $(event.target);
    this.$submit = $(event.target.elements).filter(':submit');
  }

  disable() {
    this.$submit.loading();
  }

  enable() {
    this.$submit.loading(false);
  }

  handle() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      if (this.isValid()) {
        if (this.isDirectSubmit()) {
          this.handleDirectSubmit();
        } else {
          this.handleApiSubmit();
        }
      } else {
        this.handleInvalid();
      }
    });
  }

  handleApiSubmit() {
    this.cancelEvent();
    this.disable();
    this.handleClicked();

    const url = this.$form.attr('action');
    const data = new FormData(this.$form.get(0));

    this.removeClicked();

    Fetch.post(url, data)
      .then(response => {
        if (response.ok) {
          this.resolve(response);
        } else {
          if (response.body['validations']) {
            Validation.setErrors(this.$form, response.body['validations']);

            this.reject(createError('Form is not valid', {
              source: 'FormSubmit',
              response: response,
              invalid: true
            }));
          } else {
            this.reject(createError('Submit request failed', {
              source: 'FormSubmit',
              response: response
            }));
          }
        }
      })
      .catch(reason => this.reject(reason))
      .finally(() => this.enable());
  }

  handleDirectSubmit() {
    this.disable();
    this.handleClicked();

    delay(SUBMIT_DELAY).then(() => {
      this.enable();
      this.removeClicked();
      this.resolve({
        ok: false,
        body: {}
      });
    });
  }

  handleInvalid() {
    this.cancelEvent();
    this.removeClicked();

    this.reject(createError('Form is not valid', {
      source: 'FormSubmit',
      invalid: true
    }));
  }

  handleClicked() {
    const $button = this.$submit.filter('[data-clicked]');
    const name = $button.attr('name');
    const value = $button.attr('value');

    if (name && value) {
      this.$clicked = $(`<input type="hidden" name="${name}" value="${value}">`);
      this.$form.append(this.$clicked);
    }
  }

  cancelEvent() {
    this.event.preventDefault();
    this.event.stopPropagation();
  }

  removeClicked() {
    this.$submit.removeAttr('data-clicked');

    if (this.$clicked) {
      this.$clicked.remove();
      this.$clicked = null;
    }
  }

  isValid() {
    return this.$form.is('.validated') && Validation.validate(this.$form);
  }

  isDirectSubmit() {
    return this.$form.is('.direct-submit');
  }
}

$(() => {
  $(document).on('submit', 'form.inline-form', event => {
    new FormSubmit(event).handle()
      .then(response => new ResponseCommand(response).handle())
      .catch(error => {
        if (error.invalid) {
          $(event.target).trigger('rp-submit:invalid', {
            response: error.response
          });
        } else {
          Log.error(error);
        }
      });
  });

  $(document).on('click', 'form.inline-form :submit', event => {
    event.preventDefault();

    const $button = $(event.currentTarget);
    Confirmation.create($button).confirm().then(() => {
      $button.attr('data-clicked', 'true');
      $button.form().trigger('submit');
    });
  });
});
