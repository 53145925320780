import Log from 'core/log';
import Router from 'core/router';
import Avatar from '../../avatars';
import Popover from '../../popover';
import ResponseCommand from '../../responseCommand';

const SELECTOR_HEADER_COLOR = '.rp-task-popover-header-color';

class TaskCalendarPopover {
  constructor($element, observable, state) {
    this.observable = observable;
    this.state = state;

    this.$element = $element;
    this.url = this.getUrl($element);

    this.bindEvents();
    this.init();
  }

  getUrl($element) {
    // for overriding
  }

  bindEvents() {
    this.$element.on('rp-popover:loaded.task-calendar-popover', (event, data) => {
      this.observable.trigger('popover-loaded');

      this.onLoaded(data.$root);
    });

    this.$element.on('rp-popover:closed.task-calendar-popover', () => {
      this.observable.trigger('popover-closed');
      this.$element.removeClass('active');

      this.onClosed();
    });
  }

  onLoaded($content) {
    // for overriding
  }

  onClosed() {
    // for overriding
  }

  init() {
    this.$element.addClass('active');

    new Popover(this.$element, this.url).open()
      .then(response => new ResponseCommand(response, this.$element).handle())
      .catch(error => Log.error(error));
  }

  dispose() {
    this.$element.off('rp-partial:loaded.task-calendar-popover');
    this.$element.off('rp-partial:disposed.task-calendar-popover');
  }
}

export class TaskCalendarCreatePopover extends TaskCalendarPopover {
  constructor($element, observable, state) {
    super($element, observable, state);
  }

  getUrl($element) {
    return Router.get('TASK_CALENDAR_POPOVER_CREATE') + '?Deadline=' + $element.attr('data-event-time-slot');
  }

  onClosed() {
    this.$element.remove();
  }
}

export class TaskCalendarUpdatePopover extends TaskCalendarPopover {
  constructor($element, observable, state) {
    super($element, observable, state);
  }

  getUrl($element) {
    return Router.get('TASK_CALENDAR_POPOVER_UPDATE', {
      id: $element.attr('data-event-id')
    });
  }

  onLoaded($content) {
    $content.find(SELECTOR_HEADER_COLOR)
      .css('background', new Avatar($content.find('#UserId').val()).getPrimaryColor());

    $content.one('focus select2:open select2:clear', ':input', () => {
      $content.one('input', ':input', () => {
        $content.addClass('modified');
      });
    });
  }

}
