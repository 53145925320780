import $ from 'jquery';

class TaskModal {
  constructor(modal) {
    this.typeField = modal.find('#Type');
    this.salesmanField = modal.find('#UserId');

    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    this.typeField.on('change', event => {
      if (event.target.value === '1') {
        // CrmTaskType.CUSTOMER_MEETING requires salesman
        self.salesmanField.required(true);
      }
      else {
        self.salesmanField.required(false);
      }
    });
  }
}

$(()=> {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#task-modal-form")) {
      new TaskModal(data.$root);
    }
  });
});
