import Fetch from 'core/fetch';
import Log from 'core/log';
import $ from 'jquery';

const NEXT_STEP_ACTION_ATTR = 'data-bulk-change-next-step-action';
const FINAL_STEP_ATTR = 'data-bulk-change-final-step';
const FORM_ACTION_ATTR = 'data-bulk-change-form-action';
const DIRECT_FORM_ATTR = 'data-bulk-change-form-direct';

class BulkChangeStep {
  constructor($step) {
    this.$step = $step;
    this.$nextStepAction = this.$step.find('[' + NEXT_STEP_ACTION_ATTR + ']');
    this.nextStepActionParam = this.$nextStepAction?.attr('data-name');

    this.handleFinalStep();
    this.bindEvents();
  }

  bindEvents() {
    if (!this.$nextStepAction.exists()) {
      return;
    }

    const action = this.$nextStepAction.attr(NEXT_STEP_ACTION_ATTR);

    this.$nextStepAction.on('change', event => {
      if (Fetch.isValidUrl(action)) {
        this.$step.nextAll('.rp-bulk-change-step').remove();
        this.hideFooter(true);

        const url = new URL(action, window.location.origin);
        if (this.nextStepActionParam) {
          url.searchParams.append(this.nextStepActionParam, event.target.value);
        }
        Fetch.getHtml(url.toString())
          .then(response => {
            if (response.ok) {
              const $nextStep = $(response.body);
              $nextStep.insertAfter(this.$step);

              $nextStep.trigger('rp-partial:loaded', {
                $root: $nextStep
              });
            }
          })
          .catch(error => Log.error(error));
      }
    });
  }

  hideFooter(hide) {
    this.$step.closest('.rp-bulk-change').find('.inline-form-footer').hidden(hide);
  }

  handleFinalStep() {
    if (!this.$step.is('[' + FINAL_STEP_ATTR + ']')) {
      this.hideFooter(true);
      return;
    }

    const $form = this.$step.closest('.rp-bulk-change');

    const formAction = this.$step.attr(FORM_ACTION_ATTR);
    if (formAction) {
      $form.attr('action', formAction);
    }

    if (this.$step.is('[' + DIRECT_FORM_ATTR + ']')) {
      $form.addClass('direct-submit');
    } else {
      $form.removeClass('direct-submit');
    }

    this.hideFooter(false);
  }

}

function init($root) {
  $root.find('.rp-bulk-change-step').each(function() {
    new BulkChangeStep($(this));
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    if (data.$root?.is('.rp-bulk-change-step')) {
      new BulkChangeStep(data.$root);
    }
  });
});
