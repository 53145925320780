import InteractiveElement, { EVENT_ACTIVATE, EVENT_DEACTIVATE } from '../interactiveElement';
import { formatAttrSelector } from '../../utils/functions';

export default class InteractiveRow {
  constructor(table) {
    this.$element = table.$element;
    this.$table = table.$table;

    new InteractiveElement(this.$element);

    this.bindEvents();
  }

  get activeRowId() {
    return this._activeRowId;
  }

  set activeRowId(value) {
    this._activeRowId = value;
    this.updateState();
  }

  bindEvents() {
    this.$element.on(EVENT_ACTIVATE, (event, data) => {
      const rowId = data.$element.attr('data-record-id');

      if (this.activeRowId === rowId) {
        event.preventDefault();
        return;
      }

      this.activeRowId = rowId;
    });

    this.$element.on(EVENT_DEACTIVATE, (event, data) => {
      const rowId = data.$element.attr('data-record-id');

      if (this.activeRowId === rowId) {
        this.activeRowId = null;
      }
    });
  }

  updateState() {
    this.$table.find('.rp-row.active').removeClass('active');

    if (this.activeRowId) {
      this.$table.find('.rp-row' + formatAttrSelector('data-record-id', this.activeRowId)).addClass('active');
    }
  }
}
