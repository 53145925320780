import dayjs from 'dayjs';
import Dates from '../../core/dates';

const MONDAY = 1;
const SUNDAY = 7;

const FORMAT_MONTH_YEAR = 'MMM YYYY';
const FORMAT_WEEKDAY_NAME = 'ddd';
const FORMAT_WEEKDAY_DATE = 'DD';
const FORMAT_TIME_SLOT = 'YYYY-MM-DD HH:mm';

const TIME_UPDATE_DELAY = 1000 * 60;

export default class TaskCalendarState {
  constructor(observable) {
    this.observable = observable;

    this._now = dayjs();
    this._week = this._now.day(MONDAY);

    this._salespeople = [];
    this._events = [];
    this._overdueEvents = [];

    setInterval(() => this._now = dayjs(), TIME_UPDATE_DELAY); // TODO event
  }

  getFilter() {
    return {
      'filter_deadline_min': Dates.format(this._week.day(MONDAY)),
      'filter_deadline_max': Dates.format(this._week.day(SUNDAY)),
      'filter_salesman': this._salespeople.join(',')
    };
  }

  getDate() {
    return Dates.format(this._week);
  }

  setDate(value) {
    const date = Dates.parse(value);
    if (date) {
      this._week = date.day(MONDAY);
      this.observable.trigger('week-change', this);
    }
  }

  getMonthYear() {
    return this._week.format(FORMAT_MONTH_YEAR);
  }

  getTimeSlot(weekday, hour, minute) {
    return this._week.day(weekday).set('hour', hour).set('minute', minute).format(FORMAT_TIME_SLOT);
  }

  getWeekdayKey(weekday) {
    return Dates.format(this._week.day(weekday));
  }

  getWeekdayName(weekday) {
    return this._week.day(weekday).format(FORMAT_WEEKDAY_NAME);
  }

  getWeekdayDate(weekday) {
    return this._week.day(weekday).format(FORMAT_WEEKDAY_DATE);
  }

  isWeekdayToday(weekday) {
    return this._week.day(weekday).isSame(this._now, 'day');
  }

  prevWeek() {
    this._week = this._week.add(-1, 'week');
    this.observable.trigger('week-change', this);
  }

  nextWeek() {
    this._week = this._week.add(1, 'week');
    this.observable.trigger('week-change', this);
  }

  currentWeek() {
    this._week = this._now.day(MONDAY);
    this.observable.trigger('week-change', this);
  }

  getSalespeople() {
    return [...this._salespeople];
  }

  isSalesmanSelected(value) {
    return this._salespeople.includes(value);
  }

  toggleSalesman(value) {
    if (this.isSalesmanSelected(value)) {
      this._salespeople = this._salespeople.filter(salesman => salesman !== value);
    } else {
      this._salespeople.push(value);
    }

    this.observable.trigger('salespeople-change', this);
  }

  getRegularEvents() {
    return this._events.filter(event => !event.timeless);
  }

  getTimelessEvents() {
    return this._events.filter(event => event.timeless);
  }

  getEventsMap(events) {
    const eventsMap = {};

    events.forEach(event => {
      const key = event.dateKey;

      eventsMap[key] = eventsMap[key] || [];
      eventsMap[key].push(event);
    });

    return eventsMap;
  }

  getRegularEventsMap() {
    return this.getEventsMap(this.getRegularEvents());
  }

  getTimelessEventsMap() {
    return this.getEventsMap(this.getTimelessEvents());
  }

  setEvents(value) {
    this._events = [...value];
    this.observable.trigger('events-change', this);
  }

  getOverdueEvents() {
    return [...this._overdueEvents];
  }

  setOverdueEvents(value) {
    this._overdueEvents = [...value];
    this.observable.trigger('overdue-events-change', this);
  }
}
