import $ from 'jquery';
import noUiSlider from 'nouislider';
import Numbers from '../core/numbers';

const SELECTOR = '[data-auto-init="slider"]';

function initSlider($element) {
  const $slider = $element.find('.slider');
  const $min = $element.find('.slider-min');
  const $max = $element.find('.slider-max');

  const rangeMin = Numbers.parse($slider.attr('data-range-min'));
  const rangeMax = Numbers.parse($slider.attr('data-range-max'));
  const step = Numbers.parse($slider.attr('data-step'));

  const valueMin = Numbers.parse($slider.attr('data-value-min'));
  const valueMax = Numbers.parse($slider.attr('data-value-max'));

  const slider = noUiSlider.create($slider.get(0), {
    connect: true,
    range: {
      min: [rangeMin],
      max: [rangeMax]
    },
    step: step,
    start: [valueMin, valueMax],
  });

  slider.on('update', (values, handle, unencoded) => {
    const value = Math.round(unencoded[handle]);

    if (handle) {
      $max.val(Numbers.format(value));
    } else {
      $min.val(Numbers.format(value));
    }
  });

  $min.add($max).on('change', () => {
    slider.set([Numbers.parse($min.val()), Numbers.parse($max.val())]);
  });
}

$(()=> {
  $(SELECTOR).each(function() {
    initSlider($(this));
  });

  $(document).on('rp-partial:loaded', (event, data) => {
    data.$root.find(SELECTOR).each(function() {
      initSlider($(this));
    });
  });

  $(document).on('rp-table:loaded', event => {
    $(event.target).find(SELECTOR).each(function() {
      initSlider($(this));
    });
  });
});
