import Fetch from 'core/fetch';
import Log from 'core/log';
import Router from 'core/router';

export default class State {
  constructor(table, tableId) {
    this.table = table;
    this.tableId = tableId;
  }

  get state() {
    const state = new FormData();
    state.set('table', this.tableId);

    if (this.table.pagination) {
      state.set('page_number', this.table.pagination.pageNumber);
      state.set('page_size', this.table.pagination.pageSize);
    }

    if (this.table.sorting) {
      state.set('table_sorting', this.table.sorting.toString());
    }

    return state;
  }

  save() {
    Fetch.post(Router.get('API_SAVE_TABLE_STATE'), this.state)
      .catch(error => Log.error(error));
  }
}
