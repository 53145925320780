import Translation from 'select2/src/js/select2/translation';

import BulgarianTranslation from 'select2/src/js/select2/i18n/bg';
import CzechTranslation from 'select2/src/js/select2/i18n/cs';
import GermanTranslation from 'select2/src/js/select2/i18n/de';
import EnglishTranslation from 'select2/src/js/select2/i18n/en';
import FrenchTranslation from 'select2/src/js/select2/i18n/fr';
import HungarianTranslation from 'select2/src/js/select2/i18n/hu';
import PolishTranslation from 'select2/src/js/select2/i18n/pl';
import RomanianTranslation from 'select2/src/js/select2/i18n/ro';
import RussianTranslation from 'select2/src/js/select2/i18n/ru';
import SlovakTranslation from 'select2/src/js/select2/i18n/sk';
import SlovenianTranslation from 'select2/src/js/select2/i18n/sl';
import UkrainianTranslation from 'select2/src/js/select2/i18n/uk';

Translation._cache['bg'] = BulgarianTranslation;
Translation._cache['cs'] = CzechTranslation;
Translation._cache['de'] = GermanTranslation;
Translation._cache['en'] = EnglishTranslation;
Translation._cache['fr'] = FrenchTranslation;
Translation._cache['hu'] = HungarianTranslation;
Translation._cache['pl'] = PolishTranslation;
Translation._cache['ro'] = RomanianTranslation;
Translation._cache['ru'] = RussianTranslation;
Translation._cache['sk'] = SlovakTranslation;
Translation._cache['sl'] = SlovenianTranslation;
Translation._cache['uk'] = UkrainianTranslation;
