<script setup>
import { ref } from 'vue';
import ActivityStreamEmailDraftBody from './ActivityStreamEmailDraftBody.vue';
import ActivityStreamEmailDraftFooter from './ActivityStreamEmailDraftFooter.vue';
import ActivityStreamEmailDraftFormatting from './ActivityStreamEmailDraftFormatting.vue';
import ActivityStreamEmailDraftHeader from './ActivityStreamEmailDraftHeader.vue';
import ActivityStreamEmailDraftRecipients from './ActivityStreamEmailDraftRecipients.vue';
import ActivityStreamEmailDraftSubject from './ActivityStreamEmailDraftSubject.vue';
import { EmailDraft } from './Types';

defineProps({
  draft: EmailDraft
});

const expanded = ref(false);

</script>

<template>
  <div class="rpvue-ActivityStreamEmailDraft" :class="{ 'is-expanded': expanded }">
    <div class="rpvue-ActivityStreamEmailDraft-header rpvue-u-p-6">
      <ActivityStreamEmailDraftHeader :expanded="expanded" @toggle-expanded="expanded = !expanded"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraft-recipients rpvue-u-px-6 rpvue-u-pb-6">
      <ActivityStreamEmailDraftRecipients :draft="draft"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraft-subject rpvue-u-px-6 rpvue-u-pb-6">
      <ActivityStreamEmailDraftSubject v-model="draft.subject"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraft-body rpvue-u-px-6">
      <ActivityStreamEmailDraftBody v-model="draft.content_text"/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraft-formatting">
      <ActivityStreamEmailDraftFormatting/>
    </div>
    <div class="rpvue-ActivityStreamEmailDraft-footer rpvue-u-p-6">
      <ActivityStreamEmailDraftFooter :draft="draft"/>
    </div>
  </div>
</template>
