export default class LucronPaymentMethods {
  constructor($form) {
    this.$hasEscrow = $form.find('#has-escrow');
    this.$hasMortgage = $form.find('#has-mortgage');

    this.$notaryEscrowDeadline = $form.find('#notary-escrow-deadline');
    this.$bankEscrowDeadline = $form.find('#bank-escrow-deadline');
    this.$mortgageDeadline = $form.find('#mortgage-deadline');

    this.bindEvent();
  }

  bindEvent() {
    this.$hasEscrow.on('change', () => {
      this.toggleField(this.$notaryEscrowDeadline, !this.$hasEscrow.is(':checked'));
      this.toggleField(this.$bankEscrowDeadline, !this.$hasEscrow.is(':checked'));
    }).trigger('change');

    this.$hasMortgage.on('change', () => {
      this.toggleField(this.$mortgageDeadline, !this.$hasMortgage.is(':checked'));
    }).trigger('change');
  }

  toggleField($field, disabled) {
    $field.disabled(disabled);
    $field.closest('.form-group').hidden(disabled);
  }

}