import Fetch from 'core/fetch';
import Log from 'core/log';
import { computed, ref, watch } from 'vue';
import Alerts from '../../../../components/alerts';
import { EmailItem, EventItem, InteractionItem, TaskItem } from './Types';

const STATE_OK = 'ok';
const STATE_LOADING = 'loading';
const STATE_ERROR = 'error';

// TODO rework to use Pinia while moving code to FE lib/app
export function useItems(configRef) {
  const itemsUrl = ref(null);
  const nextItemsUrl = ref(null);

  const items = ref([]);
  const state = ref(STATE_LOADING);

  const hasMore = computed(() => state.value === STATE_OK && nextItemsUrl.value);
  const loadMore = () => {
    if (hasMore.value) {
      itemsUrl.value = nextItemsUrl.value;
      nextItemsUrl.value = null;
    }
  };

  const isLoading = computed(() => state.value === STATE_LOADING);
  const isError = computed(() => state.value === STATE_ERROR);

  watch(configRef, (config) => {
    if (config.itemsAction?.url) {
      itemsUrl.value = config.itemsAction?.url;
      nextItemsUrl.value = null;

      items.value = [];
      state.value = STATE_OK;
    }
  });

  watch(itemsUrl, async (url) => {
    if (!Fetch.isValidUrl(url)) {
      return;
    }

    try {
      state.value = STATE_LOADING;

      const response = await Fetch.getJson(url);
      const status = response.body?.status;

      if (response.ok && status?.code === 'ok') {
        nextItemsUrl.value = response.body?.pagination?.next_chunk?.url;

        response.body.resources.forEach(item => {
          switch (item['type']) {
            case 'email':
              items.value.push(new EmailItem(item));
              break;
            case 'task':
              items.value.push(new TaskItem(item));
              break;
            case 'interaction':
              items.value.push(new InteractionItem(item));
              break;
            case 'event':
              items.value.push(new EventItem(item));
              break;
            default:
              Log.error('Unknown item type: ' + item['type']);
          }
        });

        state.value = STATE_OK;
      } else {
        if (status?.message) {
          Alerts.error(status?.message);
        } else {
          Log.error('Unexpected response status code ' + status?.code, {
            source: 'ActivityStream',
            url: url,
            responseBody: response.body
          });
        }

        state.value = STATE_ERROR;
      }
    } catch (e) {
      Log.error(e);
      state.value = STATE_ERROR;
    }
  });

  return { items, loadMore, isLoading, isError };
}
