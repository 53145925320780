<script setup>
import { Button, ButtonSize, DropdownAlign, DropdownRow, SplitButton, Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { computed, inject } from 'vue';
import { Config } from './Types';
import { useClickActions } from '../../useClickActions';
import { createAction } from './utils';

const props = defineProps({
  config: {
    type: Config,
    required: true
  }
});

const createDraft = inject('createDraft');

const { clickAction, disabled } = useClickActions();

const actionsData = {
  createEmailAction: {
    label: 'email_draft_create',
    clickHandler: (url) => createDraft(url)
  },
  createTaskAction: {
    label: 'crm_task_create',
    clickHandler: (url) => clickAction('modal', url)
  },
  createInteractionAction: {
    label: 'customer_interaction_create',
    clickHandler: (url) => clickAction('modal', url)
  }
};

const actions = computed(() => {
  const ret = [];

  for (const [name, data] of Object.entries(actionsData)) {
    if (props.config[name]) {
      ret.push(createAction(props.config[name], data));
    }
  }

  return ret;
});

const firstAction = computed(() => actions.value[0]);
const remainingActions = computed(() => actions.value.slice(1));

</script>

<template>
  <div class="rpvue-ActivityStreamHeader">
    <div class="rpvue-ActivityStreamHeader-title">
      <span class="rpvue-u-TypoTitle rpvue-u-TypoTitle--medium">
        {{ I18N.t('customer_tab_activities') }}
      </span>
    </div>
    <div class="rpvue-ActivityStreamHeader-actions" v-if="actions.length > 0">
      <SplitButton v-if="remainingActions.length > 0"
                   :align="DropdownAlign.END"
                   :size="ButtonSize.SM"
                   :disabled="disabled"
                   :buttonProps="{ disabled: firstAction.disabled }"
                   @click="firstAction.onClick">
        <template v-slot:default>
          {{ firstAction.label }}
        </template>
        <template v-slot:tooltip v-if="firstAction.reason">
          <Tooltip>{{ firstAction.reason }}</Tooltip>
        </template>
        <template v-slot:dropdown="{ close }">
          <DropdownRow v-for="action in remainingActions"
                       :disabled="action.disabled || disabled"
                       @click="action.onClick();close()">
            <template v-slot:default>
              {{ action.label }}
            </template>
            <template v-slot:tooltip v-if="action.reason">
              <Tooltip>{{ action.reason }}</Tooltip>
            </template>
          </DropdownRow>
        </template>
      </SplitButton>

      <Button v-else :size="ButtonSize.SM"
              :disabled="firstAction.disabled || disabled"
              @click="firstAction.onClick">
        <template v-slot:default>
          {{ firstAction.label }}
        </template>
        <template v-slot:tooltip v-if="firstAction.reason">
          <Tooltip>{{ firstAction.reason }}</Tooltip>
        </template>
      </Button>
    </div>
  </div>
</template>
