import Numbers from '../../core/numbers';
import { LocalStorage } from '../../core/storage';
import { digest } from '../../utils/functions';

const COLUMN_ID = 'data-column-id';

export default class ResizableColumns {
  constructor(table) {
    this.table = table;

    this.$element = table.$element;
    this.$document = $(document);

    this.$thead = table.$table.find('thead');
    this.$dataColumns = this.$thead.find('th.rp-column-data');
    this.$fixedColumns = this.$thead.find('th.rp-column-fixed');
    this.$bar = this.$element.find('.rp-column-resize-bar');

    this.storage = new LocalStorage(table.id + '-resizable-columns');

    this.bindEvents();
    this.loadColumnsWidth();
  }

  bindEvents() {
    // wait for table to load so that columns width adjusts to data
    this.$element.one('rp-table:loaded', () => this.normalizeColumnsWidth());

    this.$thead.on('mousedown', '.rp-column-resize-handle', downEvent => {
      downEvent.preventDefault();
      downEvent.stopPropagation();

      const $thisColumn = $(downEvent.target).closest('th');
      const $nextColumn = $thisColumn.next('th.rp-column-data');
      if (!$nextColumn.exists()) {
        return;
      }

      const elementOffset = this.$element.offset();

      const startWidth = $thisColumn.outerWidth();
      const minWidth = this.getColumnMinWidth($thisColumn);
      const maxWidth = startWidth + $nextColumn.outerWidth() - this.getColumnMinWidth($nextColumn);

      const minPageX = () => downEvent.pageX - (startWidth - minWidth);
      const maxPageX = () => downEvent.pageX + (maxWidth - startWidth);

      const onMove = moveEvent => {
        const pageX = Numbers.clamp(moveEvent.pageX, minPageX(), maxPageX());

        this.$bar.css('left', (pageX - elementOffset.left) + 'px');
      };

      const onUp = upEvent => {
        this.$document.off('mousemove', onMove);
        this.$document.off('mouseup', onUp);

        this.$bar.hidden();

        const pixelToPercentRatio = this.getPixelToPercentRatio();

        const widthChange = upEvent.pageX - downEvent.pageX;
        const updatedWidth = Numbers.clamp(startWidth + widthChange, minWidth, maxWidth);
        const nextColumnWidth = $nextColumn.outerWidth() + (startWidth - updatedWidth);

        $thisColumn.css('width', (updatedWidth * pixelToPercentRatio) + '%');
        $nextColumn.css('width', (nextColumnWidth * pixelToPercentRatio) + '%');

        this.normalizeColumnsWidth();
        this.saveColumnsWidth();
      };

      this.$bar.hidden(false).css({
        left: (downEvent.pageX - elementOffset.left) + 'px'
      });

      this.$document.on('mousemove', onMove);
      this.$document.on('mouseup', onUp);
    });
  }

  normalizeColumnsWidth() {
    const pixelToPercentRatio = this.getPixelToPercentRatio();
    const widths = {};

    this.$dataColumns.each(function() {
      const $column = $(this);
      const columnId = $column.attr(COLUMN_ID);

      widths[columnId] = $column.outerWidth() * pixelToPercentRatio;
    });

    this.$dataColumns.each(function() {
      const $column = $(this);
      const columnId = $column.attr(COLUMN_ID);

      $column.css('width', widths[columnId] + '%');
    });

    if (this.table.stickyHeader) {
      this.table.stickyHeader.resize();
    }
  }

  getPixelToPercentRatio() {
    const totalWidth = this.$thead.outerWidth();

    const dataColumnsWidth = this.getColumnsWidth(this.$dataColumns);
    const fixedColumnsWidth = this.getColumnsWidth(this.$fixedColumns);

    const fixedColumnsWidthPercent = fixedColumnsWidth / totalWidth * 100;
    const dataColumnsWidthPercent = 100 - fixedColumnsWidthPercent;

    return dataColumnsWidthPercent / dataColumnsWidth;
  }

  getColumnsWidth($columns) {
    let totalWidth = 0;

    $columns.each(function() {
      totalWidth += $(this).outerWidth();
    });

    return totalWidth;
  }

  getColumnMinWidth($column) {
    const column = $column.get(0);
    const width = column.style.width;

    column.style.width = '1%';
    const minWidth = $column.outerWidth();
    column.style.width = width;

    return minWidth;
  }

  getStorageKey() {
    const columns = [];

    this.$dataColumns.each(function() {
      columns.push($(this).attr(COLUMN_ID));
    });

    return digest(columns.sort().join(','));
  }

  loadColumnsWidth() {
    this.getStorageKey().then(key => {
      const json = this.storage.getJson(key);

      if (json) {
        const totalWidth = this.getColumnsWidth(this.$dataColumns);

        this.$dataColumns.each(function() {
          const $column = $(this);
          const columnId = $column.attr(COLUMN_ID);
          const widthPercent = json[columnId];

          $column.css('width', widthPercent * totalWidth / 100);
        });

        this.normalizeColumnsWidth();
      }
    });
  }

  saveColumnsWidth() {
    const totalWidth = this.getColumnsWidth(this.$dataColumns);
    const json = {};

    this.$dataColumns.each(function() {
      const $column = $(this);
      const columnId = $column.attr(COLUMN_ID);
      const width = $column.outerWidth();

      json[columnId] = width / totalWidth * 100;
    });

    this.getStorageKey().then(key => this.storage.setJson(key, json));
  }
}
