import $ from 'jquery';

export default class Selection {
  constructor(table) {
    this.table = table;
    this.$table = table.$table;
    this.$all = table.$table.find('.rp-row-select-all');

    this._selection = [];

    this.bindEvents();
  }

  get selection() {
    return this._selection;
  }

  set selection(value) {
    this._selection = value;

    this.updateState();
    this.table.actions.update();
  }

  clear() {
    this.selection = [];
  }

  toString() {
    return this.selection.join();
  }

  updateState() {
    const numberOfSelectableRows = this.getNumberOfSelectableRows();
    this.$all.disabled(numberOfSelectableRows === 0);

    this.$table.find('.rp-row.selected').removeClass('selected');
    this.$table.find('.rp-row-select-row').prop('checked', false);

    this.selection.forEach(record => {
      this.$table.find('.rp-row[data-record-id=' + record + ']')
        .addClass('selected').find('.rp-row-select-row').prop('checked', true);
    });

    if (this.selection.length > 0) {
      this.$all.prop('checked', this.selection.length === numberOfSelectableRows);
    } else {
      this.$all.prop('checked', false);
    }

    this.table.$element.trigger('rp-table:selection', {
      selection: this.selection
    });
  }

  bindEvents() {
    this.$table.on('change', '.rp-row-select-row', event => {
      const $checkbox = $(event.currentTarget);
      const record = $checkbox.closest('.rp-row').attr('data-record-id');

      const selection = this.selection;

      const index = selection.indexOf(record);
      if (index !== -1) {
        selection.splice(index, 1);
      }
      if ($checkbox.is(':checked')) {
        selection.push(record);
      }

      this.selection = selection;
    });

    this.$all.on('change', () => {
      const selection = [];

      if (this.$all.is(':checked')) {
        this.$table.find('.rp-row[data-record-id]').each(function() {
          const disabled = $(this).find('.rp-row-select input').is(':disabled');
          if (!disabled) {
            selection.push($(this).attr('data-record-id'));
          }
        });
      }

      this.selection = selection;
    });
  }

  getNumberOfSelectableRows() {
    return this.$table.find('.rp-row:not(.template) .rp-row-select input:not(:disabled)').length;
  }

}
