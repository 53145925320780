import $ from 'jquery';

const SELECTOR = '.wrapped-iframe';

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $iframe = $(this);

    $iframe.on('load', () => {
      const location = this.contentWindow.location;
      if (location.pathname.startsWith('/web/')) {
        window.location.href = location.href;
      }
    });
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
