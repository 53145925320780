<script setup>
import { Avatar } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { computed } from 'vue';
import ActivityStreamPerson from './ActivityStreamPerson.vue';
import { EmailItem } from './Types';

const props = defineProps({
  item: {
    type: EmailItem,
    required: true
  }
});

const from = computed(() => props.item.emailFrom[0]);
const to = computed(() => ([...props.item.emailTo, ...props.item.emailCc]));

</script>

<template>
  <div class="rpvue-ActivityStreamEmailAddresses">
    <div class="rpvue-ActivityStreamEmailAddresses-avatar">
      <Avatar :name="from.name ?? from.address" :icon="from.icon"/>
    </div>
    <div class="rpvue-ActivityStreamEmailAddresses-container">
      <div class="rpvue-ActivityStreamEmailAddresses-row rpvue-u-TextColorNeutral">
        <div class="rpvue-ActivityStreamEmailAddresses-person" v-for="(address, index) in item.emailFrom" :key="address.address">
          <ActivityStreamPerson :name="address.name" :email="address.address" :icon="address.icon" :avatar="index > 0"/>
        </div>
      </div>
      <div class="rpvue-ActivityStreamEmailAddresses-row rpvue-u-TextColorNeutralAlt">
        <span class="rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">{{ I18N.t('email_message_param_to') }}:</span>
        <div class="rpvue-ActivityStreamEmailAddresses-person" v-for="address in to" :key="address.address">
          <ActivityStreamPerson :name="address.name" :email="address.address" :icon="address.icon"/>
        </div>
      </div>
    </div>
  </div>
</template>
