<script setup>
import { Icon, QuadroContainer, Tooltip, TooltipPlacement, TooltipSize } from '@realpadsoftware/realpad-crm-frontend-lib';
import Dates from 'core/dates';
import { computed } from 'vue';
import ActivityStreamEventSections from './ActivityStreamEventSections.vue';
import ActivityStreamPerson from './ActivityStreamPerson.vue';
import { EventItem } from './Types';

const props = defineProps({
  item: {
    type: EventItem,
    required: true
  }
});

const date = computed(() => Dates.format(props.item?.timestamp, Dates.FORMAT_DATE));

</script>

<template>
  <div class="rpvue-u-py-4 rpvue-u-px-6">
    <QuadroContainer top-left-large-font>
      <template v-slot:topLeft>
        <span>{{ item.eventType }}</span>
        <Icon name="assignment" v-if="item.eventText">
          <template v-slot:tooltip>
            <Tooltip :placement="TooltipPlacement.RIGHT" :size="TooltipSize.LARGE">{{ item.eventText }}</Tooltip>
          </template>
        </Icon>
      </template>
      <template v-slot:topRight>
        <span class="rpvue-u-TextColorNeutralAlt">{{ date }}</span>
      </template>
      <template v-slot:bottomLeft>
        <ActivityStreamEventSections :item="item"/>
      </template>
      <template v-slot:bottomRight v-if="item.salesmanName">
        <ActivityStreamPerson :name="item.salesmanName" icon="support_agent" class="rpvue-u-TextColorNeutralAlt"/>
      </template>
    </QuadroContainer>
  </div>
</template>
