import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import $ from 'jquery';
import InteractiveElement, { EVENT_ACTIVATE, EVENT_DEACTIVATE } from './interactiveElement';

function createLoader() {
  return `
    <div class="rp-interactive-list-loader">
      <span class="spinner-border" role="status"></span>
    </div>
  `;
}

function createError() {
  return `
    <div class="rp-interactive-list-error">
      <span>${I18N.t('ajax_error')}</span>
    </div>
  `;
}

class InteractiveList {
  constructor($element) {
    this.$element = $element;

    this.id = $element.attr('id');
    this.url = $element.attr('data-list-url');

    new InteractiveElement($element);

    this.bindEvents();
    this.load();
  }

  bindEvents() {
    $(document).on('rp-content:reload', () => this.load());

    this.$element.on(EVENT_ACTIVATE, (event, data) => data.$element.addClass('active'));
    this.$element.on(EVENT_DEACTIVATE, (event, data) => data.$element.removeClass('active'));
  }

  load() {
    this.setLoading();

    Fetch.getHtml(this.url).then(response => {
      if (response.ok) {
        this.setContent(response.body);

        this.$element.trigger('rp-partial:loaded', {
          $root: this.$element
        });
      } else {
        this.setError();
      }
    }).catch(error => {
      this.setError();
      Log.error(error);
    });
  }

  setContent(content) {
    this.$element.empty();
    this.$element.append(content);
  }

  setLoading() {
    this.$element.empty();
    this.$element.append(createLoader());
  }

  setError() {
    this.$element.empty();
    this.$element.append(createError());
  }
}

function init($root) {
  $root.find('.rp-interactive-list').each(function() {
    const $element = $(this);
    const interactiveList = new InteractiveList($element);

    $element.data('rp-interactive-list', interactiveList);
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
