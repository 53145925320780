import $ from 'jquery';
import 'select2/src/js/jquery.select2';
import './ajaxInitSupport';
import './helperButtons';
import './lockedItemSupport';
import './searchFocusFix';
import './selection';
import './tagsInsertFix';
import './toggleEventFix';
import './translations';

const SELECTOR = 'select[data-auto-init="select2"]';

const AJAX_OPTIONS = {
  type: 'POST',
  url() {
    return this.attr('data-select-url');
  },
  data(params) {
    if (params.init) {
      return {
        init: params.init,
        selected: params.selected
      }
    }
    return {
      fulltext: params.term,
      page: params.page
    };
  },
  dataType: 'json',
  delay: 500
};

$.fn.select2.defaults.set('theme', 'realpad');
$.fn.select2.defaults.set('width', '');

function initSelect2($select) {
  let options = {};

  if ($select.is('[multiple]')) {
    options.closeOnSelect = false;

    options.templateSelection = function(item) {
      return $(`<span class="select2-selection__choice__text">${item.text}</span>`);
    };
  }

  if ($select.is('[data-select-url]')) {
    options.ajax = AJAX_OPTIONS;
  }

  if ($select.is('[data-tags]')) {
    const valuePrefix = $select.attr('data-tags-prefix-value');
    const dropdownItemPrefix = $select.attr('data-tags-prefix-dropdown-item');

    options.createTag = function(params) {
      const term = $.trim(params.term);
      if (term === '') {
        return null;
      }

      return {
        id: valuePrefix ? valuePrefix + term : term,
        text: term,
        resultText: dropdownItemPrefix ? dropdownItemPrefix + term : term
      };
    };

    options.templateResult = function(result) {
      return result.resultText || result.text;
    };
  }

  $select.select2(options);
}

$(()=> {
  $(SELECTOR).each(function() {
    initSelect2($(this));
  });

  $(document).on('rp-partial:loaded', (event, data) => {
    data.$root.find(SELECTOR).each(function() {
      initSelect2($(this));
    });
  });

  $(document).on('rp-table:loaded', event => {
    $(event.target).find(SELECTOR).each(function() {
      initSelect2($(this));
    });
  });
});
