import $ from 'jquery';
import { formatAttrSelector } from '../utils/functions';

const PAIRED_INPUT_MASTER_ATTRIBUTE_NAME = 'data-paired-input-master';
const PAIRED_INPUT_MASTER_VALUE_ATTRIBUTE_NAME = 'data-paired-input-master-value';
const PAIRED_INPUT_DEPENDENT_ATTRIBUTE_NAME = 'data-paired-input-dependent';

/**
 * Show dependent fields when specific value is selected in master field.
 */
class PairedInput {

  constructor($master, masterValue, $dependents) {
    this.$master = $master;
    this.masterValue = this.resolveMasterValue(masterValue);
    this.$dependents = $dependents;
    this.$dependentsGroup = this.$dependents.closest('.form-group');
    this.isCheckboxType = this.$master.is(':checkbox,:radio');

    this.bindEvents();

    if (this.isCheckboxType) {
      this.update(this.$master.is(':checked'));
    } else {
      this.update(this.$master.val());
    }
  }

  bindEvents() {
    this.$master.on('change rp-paired-input:change', event => {
      this.isCheckboxType
        ? this.update(event.target.checked)
        : this.update($(event.target).val());
    });
  }

  update(value) {
    // because of issue with select2 this.$master.is(':hidden') is not checked
    const shown = !this.isMasterDisabled() && value === this.masterValue;

    this.$dependentsGroup.hidden(!shown);
    this.$dependentsGroup.disabled(!shown); // disable inputs when hiding them, so they aren’t submitted
    this.$dependents.trigger('rp-paired-input:change');
  }

  isMasterDisabled() {
    return this.$master.is(':disabled')
      || this.$master.is('.disabled')
      || this.$master.is(formatAttrSelector('data-disabled', 'true'));
  }

  resolveMasterValue(masterValue) {
    if (masterValue === 'true') {
      return true;
    }

    if (masterValue === 'false') {
      return false;
    }

    return masterValue;
  }
}

function init($root) {
  $root.find('[' + PAIRED_INPUT_MASTER_ATTRIBUTE_NAME + ']').each(function() {
    const group = $(this).attr(PAIRED_INPUT_MASTER_ATTRIBUTE_NAME);

    const masterValue = $(this).attr(PAIRED_INPUT_MASTER_VALUE_ATTRIBUTE_NAME);
    const $dependants = $root.find(formatAttrSelector(PAIRED_INPUT_DEPENDENT_ATTRIBUTE_NAME, group));

    if (masterValue && $dependants.exists()) {
      new PairedInput($(this), masterValue, $dependants);
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
