import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { copyToClipboard } from 'utils/functions';
import Alerts from '../../components/alerts';

const OFFER_COPIED_ALERT_TIMEOUT = 3000;

const ATTRIBUTE_CUSTOMER_ID = 'customer-id';
const ATTRIBUTE_EXCLUDE = 'exclude';

class GenerateCustomerOfferForm {
  constructor($customerOfferForm) {
    this.customerId = $customerOfferForm.attr(ATTRIBUTE_CUSTOMER_ID);
    this.exclude = $customerOfferForm.attr(ATTRIBUTE_EXCLUDE) || '';
    this.$template = $customerOfferForm.find('#offer-template');
    this.$copyOffer = $customerOfferForm.find('#copy-to-clipboard');

    this.offerContent = '';

    this.bindEvents();
  }

  bindEvents() {
    this.$template.on('change', () => {
      this.offerContent = '';
      this.$copyOffer.disabled(false);
    });

    this.$copyOffer.on('click', () => {
      if (this.offerContent) {
        this.copyAndSetAlert();
      } else {
        Fetch.post(Router.get('API_CUSTOMER_OFFER_GENERATE', { 'customerId': this.customerId }), {
          'templateId': this.$template.val(),
          'exclude': this.exclude
        }).then(response => {
          if (response.ok && response.body.content) {
            this.offerContent = response.body.content;
            if (this.offerContent) {
              this.copyAndSetAlert();
            }
          }
        }).catch(error => Log.error(error));
      }
    });
  }

  copyAndSetAlert() {
    copyToClipboard(this.offerContent);
    Alerts.success(I18N.t('customer_offer_copy_to_clipboard_success'), OFFER_COPIED_ALERT_TIMEOUT);
  }
}

$(() => {
  const $customerOfferForm = $('#customer-offer-form');
  if ($customerOfferForm.exists()) {
    new GenerateCustomerOfferForm($customerOfferForm);
  }
});
