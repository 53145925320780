import SelectAdapter from 'select2/src/js/select2/data/select';
import { formatAttrSelector } from '../../utils/functions';

const original = SelectAdapter.prototype.bind;

SelectAdapter.prototype.bind = function(container) {
  original.apply(this, arguments);

  container.on('select', params => {
    const $option = container.$element.find(formatAttrSelector('value', params.data.id));
    if ($option.is('[data-select2-tag]')) {
      if (!container.options.get('closeOnSelect')) {
        if (container.selection.$search) {
          container.selection.$search.val('');
        }
        if (container.dropdown.$search) {
          container.dropdown.$search.val('');
        }
      }
    }
  });
};
