export default class Search {
  constructor(table) {
    this.table = table;

    this.$search = table.$element.find('.rp-table-search input');
    this.value = this.$search.val();

    this.bindEvents();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;

    this.$search.val(value);
    this.table.actions.update();
  }

  clear() {
    this.value = '';
  }

  bindEvents() {
    this.$search.on('change', () => {
      this.value = this.$search.val();
    });

    this.$search.on('keydown', event => {
      if (event.keyCode === 13) {
        event.preventDefault();

        this.value = this.$search.val();
        this.table.load(true);
      }
    });
  }
}
