import Fetch from 'core/fetch';
import Log from 'core/log';
import { ref } from 'vue';
import Alerts from '../../../../components/alerts';
import { Config } from './Types';

// TODO rework to use Pinia while moving code to FE lib/app
export function useConfig() {
  const config = ref(new Config());

  const loadConfig = async (configUrl) => {
    try {
      const response = await Fetch.getJson(configUrl);
      const status = response.body?.status;

      if (response.ok && status?.code === 'ok') {
        config.value = new Config(response.body?.resource);
      } else {
        if (status?.message) {
          Alerts.error(status?.message);
        } else {
          Log.error('Unexpected response status code ' + status?.code, {
            source: 'ActivityStream',
            url: configUrl,
            responseBody: response.body
          });
        }
      }
    } catch (e) {
      Log.error(e);
    }
  };

  return { config, loadConfig };
}
