<script setup>
import { TabStrip } from '@realpadsoftware/realpad-crm-frontend-lib';
import { ref } from 'vue';
import ActivityStreamAlerts from './ActivityStreamAlerts.vue';
import ActivityStreamListItems from './ActivityStreamListItems.vue';
import ActivityStreamMessage from './ActivityStreamMessage.vue';
import { Config } from './Types';
import { useFilteredItems } from './useFilteredItems';
import { useItems } from './useItems';
import { useLazyLoading } from './useLazyLoading';
import { useTabs } from './useTabs';

const props = defineProps({
  config: {
    type: Config,
    required: true
  }
});

const marker = ref(null);

const { tabs, activeTabId } = useTabs(() => props.config);
const { items, loadMore, isLoading, isError } = useItems(() => props.config);
const { filteredItems, isEmpty } = useFilteredItems(items, activeTabId);

useLazyLoading(marker, activeTabId, isLoading, loadMore);

</script>

<template>
  <div class="rpvue-ActivityStreamList">
    <div class="rpvue-ActivityStreamList-alerts">
      <ActivityStreamAlerts :alerts="config.alerts"/>
    </div>
    <div class="rpvue-ActivityStreamList-tabs">
      <TabStrip :tabs="tabs" v-model:active-tab-id="activeTabId"/>
    </div>
    <div class="rpvue-ActivityStreamList-items">
      <ActivityStreamListItems :items="filteredItems"/>
    </div>
    <div class="rpvue-ActivityStreamList-marker" ref="marker"></div>
    <div class="rpvue-ActivityStreamList-message" :class="{ 'is-empty': isEmpty }">
      <ActivityStreamMessage :loading="isLoading" :error="isError" :empty="isEmpty"/>
    </div>
  </div>
</template>
