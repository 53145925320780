import Log from 'core/log';
import { createError } from 'utils/functions';
import Alerts from '../components/alerts';

const DEFAULT_OPTIONS = {
  cache: 'no-store',
  mode: 'same-origin',
  credentials: 'same-origin',
  referrerPolicy: 'same-origin'
};

const ACCEPT_JSON = 'application/json';
const ACCEPT_HTML = 'text/html, application/json;q=0.8';
const CONTENT_TYPE_JSON = 'application/json;charset=UTF-8';

export default class Fetch {
  static getJson(url, options = {}) {
    if (!this.isValidUrl(url)) {
      return this.handleInvalidUrl(url);
    }

    return fetch(url, {
      method: 'GET',
      headers: {
        ...options.headers,
        'Accept': ACCEPT_JSON
      },
      signal: options.signal,
      ...DEFAULT_OPTIONS
    }).then(response => {
      return this.handleResponse(url, response, response.json());
    }).catch(error => {
      return this.handleError(url, error);
    });
  }

  static getHtml(url, options = {}) {
    if (!this.isValidUrl(url)) {
      return this.handleInvalidUrl(url);
    }

    return fetch(url, {
      method: 'GET',
      headers: {
        ...options.headers,
        'Accept': ACCEPT_HTML
      },
      signal: options.signal,
      ...DEFAULT_OPTIONS
    }).then(response => {
      return this.handleResponse(url, response, response.ok ? response.text() : response.json());
    }).catch(error => {
      return this.handleError(url, error);
    });
  }

  static post(url, body = null, options = {}) {
    if (!this.isValidUrl(url)) {
      return this.handleInvalidUrl(url);
    }

    return fetch(url, {
      method: 'POST',
      body: this.toFormData(body),
      headers: {
        ...options.headers,
        'Accept': ACCEPT_JSON
      },
      signal: options.signal,
      ...DEFAULT_OPTIONS
    }).then(response => {
      return this.handleResponse(url, response, response.json());
    }).catch(error => {
      return this.handleError(url, error);
    });
  }

  static postJson(url, body = null, options = {}) {
    if (!this.isValidUrl(url)) {
      return this.handleInvalidUrl(url);
    }

    return fetch(url, {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
      headers: {
        ...options.headers,
        'Content-Type': CONTENT_TYPE_JSON,
        'Accept': ACCEPT_JSON
      },
      signal: options.signal,
      ...DEFAULT_OPTIONS
    }).then(response => {
      return this.handleResponse(url, response, response.json());
    }).catch(error => {
      return this.handleError(url, error);
    });
  }

  static delete(url, options = {}) {
    if (!this.isValidUrl(url)) {
      return this.handleInvalidUrl(url);
    }

    return fetch(url, {
      method: 'DELETE',
      headers: {
        ...options.headers,
        'Accept': ACCEPT_JSON
      },
      signal: options.signal,
      ...DEFAULT_OPTIONS
    }).then(response => {
      return this.handleResponse(url, response, response.json());
    }).catch(error => {
      return this.handleError(url, error);
    });
  }

  static handleResponse(url, response, responseBody) {
    return new Promise((resolve, reject) => {
      responseBody.then(body => {
        Alerts.handleResponse(body);

        resolve({
          ok: response.ok,
          status: response.status,
          body: body
        });
      }).catch(reason => {
        reject(createError(reason, {
          source: 'Fetch',
          url: url,
          status: response.status
        }));
      });
    });
  }

  static handleError(url, error) {
    return Promise.reject(createError(error, {
      source: 'Fetch',
      url: url
    }));
  }

  static handleInvalidUrl(url) {
    return Promise.reject(createError('URL is not valid', {
      source: 'Fetch',
      url: url
    }));
  }

  static isValidUrl(url) {
    if (url) {
      return url.startsWith('/') || url.startsWith(window.location.origin);
    }

    return false;
  }

  static toFormData(params) {
    if (params == null) {
      return null;
    }

    if (params instanceof FormData) {
      return params;
    }

    const data = new FormData();

    for (const [name, value] of Object.entries(params)) {
      if (typeof value !== 'string') {
        Log.error(`Attempt to send non-string param '${name}' -> '${value}' to backend`);
      }
      data.set(name, String(value));
    }

    return data;
  }
}
