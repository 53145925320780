import $ from 'jquery';

$(() => {
  $(document).on('show.bs.popover', event => {
    const $target = $(event.target);

    $target.find('rp-tooltip').prop('disabled', true);

    $target.one('hide.bs.popover', () => {
      $target.find('rp-tooltip').prop('disabled', false);
    });
  });

  $(document).on('show.bs.dropdown', event => {
    const $target = $(event.relatedTarget);

    $target.find('rp-tooltip').prop('disabled', true);

    $(event.target).one('hide.bs.dropdown', () => {
      $target.find('rp-tooltip').prop('disabled', false);
    });
  });
});
