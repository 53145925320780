import Utils from 'select2/src/js/select2/utils';

const original = Utils.Observable.prototype.trigger;

Utils.Observable.prototype.trigger = function(event, params) {
  if (event === 'toggle') {
    if ($(params.originalEvent?.target).is('.select2-selection__choice__remove')) {
      // ignore toggle event from multi-select choice remove button
      return;
    }
  }

  original.apply(this, arguments);
};
