import Dates from 'core/dates';
import { computed, toValue } from 'vue';
import { DateItem } from './Types';
import { TAB_ID_ALL } from './useTabs';

export function useFilteredItems(itemsRef, activeTabIdRef) {
  const filteredItems = computed(() => {
    const items = toValue(itemsRef);
    const activeTabId = toValue(activeTabIdRef);

    let lastDate = null;
    const filtered = [];

    for (let item of items) {
      if (activeTabId !== TAB_ID_ALL && activeTabId !== item.type) {
        continue;
      }

      const date = Dates.format(item.timestamp);
      if (date !== lastDate) {
        lastDate = date;
        filtered.push(new DateItem(date));
      }

      filtered.push(item);
    }

    return filtered;
  });

  const isEmpty = computed(() => filteredItems.value.length <= 0);

  return { filteredItems, isEmpty };
}
