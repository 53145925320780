import $ from 'jquery';

const STRINGS_SELECTOR = '#rp-strings';

export default class I18N {
  static loaded = false;
  static strings = new Map();

  static getStrings() {
    if (this.loaded) {
      return this.strings;
    }

    const json = $(STRINGS_SELECTOR).remove().text();
    if (json.length > 0) {
      for (const [key, value] of Object.entries(JSON.parse(json))) {
        this.strings.set(key, value);
      }
    }

    this.loaded = true;
    return this.strings;
  }

  static t(key, ...args) {
    const strings = this.getStrings();

    if (!strings.has(key)) {
      return key;
    }

    return strings.get(key).replace(/%s/g, () => {
      const arg = args.shift();
      if (arg === undefined) {
        return '%s';
      }
      return arg;
    });
  }
}
