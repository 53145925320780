import $ from 'jquery';
import Util from 'bootstrap/js/src/util';
import { LocalStorage, SessionStorage } from 'core/storage';
import ResponseCommand from './responseCommand';

const SELECTOR_CONTAINER = '#rp-alerts';
const SELECTOR_JSON = '#rp-alerts-json';

const ALERTS_CLOSED_PREFIX = 'rp-alert';
const ALERTS_PENDING_KEY = 'rp-alerts-pending';

const DEFAULT_TIMEOUT = 10000;
const SHOW_DELAY = 100;

const ALERTS_ORDER = [
  'error',
  'warning',
  'success',
  'info'
];

const ALERT_ICONS = {
  error: 'cancel',
  warning: 'error',
  success: 'check_circle',
  info: 'info'
};

export function createAlert(level, text, icon = true, closable = false) {
  const iconHtml = icon
    ? `<div class="rp-alert-icon">
         <rp-icon name="${ALERT_ICONS[level]}" class="icon"></rp-icon>
       </div>`
    : '';

  const closeHtml = closable
    ? `<div class="rp-alert-close">
         <button type="button" class="rp-icon-btn rp-icon-btn-standard rp-icon-btn-sm">
           <rp-icon name="close" class="icon rp-icon-btn-icon"></rp-icon>
         </button>
       </div>`
    : '';

  return `
      <div class="rp-alert rp-alert-${level}">
        <div class="rp-alert-container">
          ${iconHtml}
          <div class="rp-alert-content">
            <span class="rp-alert-text">${text}</span>
           </div>
          ${closeHtml}
        </div>
      </div>
    `;
}

class AlertsPrivate {
  static instance = null;

  #closedAlerts = new LocalStorage(ALERTS_CLOSED_PREFIX);
  #pendingAlerts = new SessionStorage();

  static getInstance() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new AlertsPrivate();
    return this.instance;
  }

  constructor() {
    this.$alerts = $(SELECTOR_CONTAINER);

    this.bindEvents();
  }

  bindEvents() {
    this.$alerts.on('rp-alert:close', event => {
      const $target = $(event.target);
      const targetId = $target.attr('data-alert-id');

      if (targetId) {
        this.#closedAlerts.set(targetId, true);
      }
    });
  }

  load() {
    let alerts = [];

    alerts = alerts.concat(this.loadFromJson());
    alerts = alerts.concat(this.loadFromStorage());
    alerts = alerts.sort((a, b) => this.compareAlerts(a, b));

    this.renderAll(alerts);
  }

  loadFromJson() {
    return this.parseAlerts($(SELECTOR_JSON).remove().text());
  }

  loadFromStorage() {
    try {
      return this.parseAlerts(this.#pendingAlerts.get(ALERTS_PENDING_KEY));
    } finally {
      this.#pendingAlerts.remove(ALERTS_PENDING_KEY);
    }
  }

  saveToStorage(alerts) {
    if (Array.isArray(alerts)) {
      this.#pendingAlerts.setJson(ALERTS_PENDING_KEY, alerts);
    }
  }

  parseAlerts(json) {
    if (json) {
      const alerts = JSON.parse(json);
      if (Array.isArray(alerts)) {
        return alerts;
      }
    }

    return [];
  }

  compareAlerts(a, b) {
    return ALERTS_ORDER.indexOf(a.level) - ALERTS_ORDER.indexOf(b.level);
  }

  renderAll(alerts) {
    if (Array.isArray(alerts)) {
      for (const alert of alerts) {
        this.render(alert);
      }
    }
  }

  render(alert) {
    const { id, level, text, html, timeout = DEFAULT_TIMEOUT } = alert;
    if (id) {
      const closedAlert = this.#closedAlerts.get(id);
      if (closedAlert) {
        return;
      }
    }

    const $alert = $(html ?? createAlert(level, text, true, true));

    if (id) {
      $alert.attr('data-alert-id', id);
    }
    $alert.addClass('fade');

    this.$alerts.append($alert);

    setTimeout(() => $alert.addClass('show'), SHOW_DELAY);
    if (timeout > 0) {
      setTimeout(() => $alert.trigger('rp-alert:close'), timeout + SHOW_DELAY);
    }
  }

  closeAll() {
    this.$alerts.find('.alert').alert('close');
  }
}

export default class Alerts {
  static error(text, timeout) {
    AlertsPrivate.getInstance().render({ level: 'error', text, timeout});
  }

  static warning(text, timeout) {
    AlertsPrivate.getInstance().render({ level: 'warning', text, timeout});
  }

  static success(text, timeout) {
    AlertsPrivate.getInstance().render({ level: 'success', text, timeout});
  }

  static info(text, timeout) {
    AlertsPrivate.getInstance().render({ level: 'info', text, timeout});
  }

  static store(level, text, timeout) {
    AlertsPrivate.getInstance().saveToStorage([{ level, text, timeout }]);
  }

  static closeAll() {
    AlertsPrivate.getInstance().closeAll();
  }

  static handleResponse(response) {
    if (response instanceof Object) {
      const alerts = response['alerts'];
      const command = response['command'];

      if (ResponseCommand.isNavigationCommand(command)) {
        AlertsPrivate.getInstance().saveToStorage(alerts);
      } else {
        AlertsPrivate.getInstance().renderAll(alerts);
      }
    }
  }
}

$(() => {
  AlertsPrivate.getInstance().load();

  $(document).on('click', '.rp-alert-close .rp-icon-btn', event => {
    event.preventDefault();
    $(event.target).closest('.rp-alert').trigger('rp-alert:close');
  });

  $(document).on('rp-alert:close', event => {
    const $alert = $(event.target);
    const transitionDuration = Util.getTransitionDurationFromElement($alert);

    $alert.removeClass('show')
      .one(Util.TRANSITION_END, () => $alert.remove())
      .emulateTransitionEnd(transitionDuration);
  });
});
