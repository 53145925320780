import $ from 'jquery';

const DOCUMENT_TYPE_ADDENDUM = '98';
const DOCUMENT_TYPE_OTHER = '99';

class CreateDealDocumentModal {
  constructor($modal) {
    this.$type = $modal.find('#Type');
    this.$otherType = $modal.find('#OtherType');

    this.bindEvents();
  }

  bindEvents() {
    this.$type.on('change', () => {
      const isOther = this.isOtherType(this.$type.val());

      this.$otherType.closest('.form-group').hidden(!isOther);
      this.$otherType.required(isOther);

      if (!isOther) {
        this.$otherType.val('').trigger('change');
      }
    }).trigger('change');
  }

  isOtherType(type) {
    return type === DOCUMENT_TYPE_ADDENDUM || type === DOCUMENT_TYPE_OTHER;
  }
}

$(()=> {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#create-deal-document-modal')) {
      new CreateDealDocumentModal(data.$root);
    }
  });
});
