import $ from 'jquery';
import { formatAttrSelector } from '../utils/functions';

const SELECTOR_CARD = '.rp-qc-card-input';
const SELECTOR_INPUT = '.rp-qc-input';

const ICON_CIRCLE = 'circle';
const ICON_CHECK_CIRCLE = 'check_circle';

class QuadroContainerCardInput {
  constructor($card, $input) {
    this.$card = $card;
    this.$input = $input;

    this.$icon = $card.find('rp-icon.rp-qc-toggle-icon');

    this.bindEvents();
  }

  bindEvents() {
    this.$card.on('click', event => {
      // ignore clicks on links
      if ($(event.target).closest('a').exists()) {
        return;
      }

      event.preventDefault();

      if (this.$input.is(':disabled')) {
        this.$card.shake();
      } else {
        this.handleClick();
      }
    });

    this.$input.on('change', () => {
      const checked = this.$input.is(':checked');

      this.$card.toggleClass('checked', checked);
      this.$icon.attr('name', checked ? ICON_CHECK_CIRCLE : ICON_CIRCLE);
    });
  }

  handleClick() {
    throw new Error('This method should be implemented');
  }
}

class QuadroContainerCardCheckbox extends QuadroContainerCardInput {
  constructor($card, $input) {
    super($card, $input);
  }

  handleClick() {
    this.$input.prop('checked', !this.$input.is(':checked')).trigger('change');
  }
}

class QuadroContainerCardRadio extends QuadroContainerCardInput {
  constructor($card, $input) {
    super($card, $input);

    this.$radios = this.findRadios();
  }

  handleClick() {
    this.$input.prop('checked', true).trigger('change');
    this.$radios.trigger('change');
  }

  findRadios() {
    const name = this.$input.attr('name');

    if (name) {
      return this.$input.form()
        .find(SELECTOR_INPUT)
        .filter(formatAttrSelector('name', name))
        .not(this.$input);
    }

    return $();
  }
}

function init($root) {
  $root.find(SELECTOR_CARD).each(function() {
    const $card = $(this);
    const $input = $card.find(SELECTOR_INPUT);

    switch ($input.attr('type')) {
      case 'checkbox':
        $card.data('rp-qc-card', new QuadroContainerCardCheckbox($card, $input));
        break;
      case 'radio':
        $card.data('rp-qc-card', new QuadroContainerCardRadio($card, $input));
        break;
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
