<script setup>
import { computed } from 'vue';
import { createAlert } from '../../../../components/alerts';
import { ConfigAlerts } from './Types';

const props = defineProps({
  alerts: {
    type: ConfigAlerts,
    required: true
  }
});

const warnings = computed(() => props.alerts.warnings.map(warning => createAlert('warning', warning)));
const infos = computed(() => props.alerts.infos.map(info => createAlert('info', info)));

</script>

<template>
  <div class="rpvue-ActivityStreamAlerts">
    <div class="rpvue-ActivityStreamAlerts-alert" v-for="warning in warnings" v-html="warning"></div>
    <div class="rpvue-ActivityStreamAlerts-alert" v-for="info in infos" v-html="info"></div>
  </div>
</template>
