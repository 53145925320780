<script setup>
import { HoverActions, IconButton, IconButtonSize } from '@realpadsoftware/realpad-crm-frontend-lib';
import { onMounted, ref } from 'vue';
import ActivityStreamEmailAttachments from './ActivityStreamEmailAttachments.vue';
import { EmailItem } from './Types';

defineProps({
  item: {
    type: EmailItem,
    required: true
  }
});

const element = ref(null);
const expandable = ref(false);
const expanded = ref(false);

onMounted(() => {
  if (element.value.scrollHeight > element.value.clientHeight) {
    expandable.value = true;
  }
});
</script>

<template>
  <div class="rpvue-ActivityStreamEmailContent">
    <div class="rpvue-ActivityStreamEmailContent-wrapper">
      <div class="rpvue-u-TextColorNeutralAlt rpvue-u-TypoLabel rpvue-u-TypoLabel--medium">
        {{ item.emailSubject }}
      </div>
      <div class="rpvue-ActivityStreamEmailContent-content rpvue-u-TextColorNeutral"
           :class="{ 'is-expanded': expanded }"
           ref="element">
        <div class="rpvue-ActivityStreamEmailContent-html rpvue-u-TypoLabel rpvue-u-TypoLabel--large"
             v-if="item.emailContentHtml"
             v-html="item.emailContentHtml">
        </div>
        <div class="rpvue-ActivityStreamEmailContent-text rpvue-u-TypoLabel rpvue-u-TypoLabel--large"
             v-else-if="item.emailContentText">
          {{ item.emailContentText }}
        </div>
        <div class="rpvue-ActivityStreamEmailContent-overlay" v-if="expandable && !expanded"></div>
      </div>
      <div class="rpvue-ActivityStreamEmailContent-attachments">
        <ActivityStreamEmailAttachments :item="item"/>
      </div>
    </div>
    <div class="rpvue-ActivityStreamEmailContent-sticky" :class="{ 'is-expanded': expanded }" v-if="expandable">
      <div class="rpvue-ActivityStreamEmailContent-toggle">
        <HoverActions>
          <div class="rpvue-HoverActions-item">
            <IconButton :icon="expanded ? 'unfold_less' : 'unfold_more'"
                        :size="IconButtonSize.SM"
                        @click="expanded = !expanded"/>
          </div>
        </HoverActions>
      </div>
    </div>
  </div>
</template>
