import $ from 'jquery';
import RequiredInputGroup, { ATTR_REQUIRED_GROUP } from './requiredInputGroup';

const SELECTOR_GROUP = '.rp-radio-btn-group';
const SELECTOR_BUTTON = '.rp-radio-btn';
const SELECTOR_INPUT = '.rp-radio-btn-input';

class RadioButtonGroup {
  constructor($element) {
    this.$element = $element;
    this.$inputs = $element.find(SELECTOR_INPUT);

    this.bindEvents();
    this.init();
  }

  init() {
    const requiredGroup = this.$inputs.attr(ATTR_REQUIRED_GROUP);
    if (requiredGroup) {
      new RequiredInputGroup(this.$element, requiredGroup);
    }
  }

  bindEvents() {
    const self = this;

    this.$inputs.each(function() {
      const $input = $(this);
      const observer = new MutationObserver(() => self.updateDisabled($input));

      observer.observe(this, {
        attributes: true,
        attributeFilter: ['disabled']
      });
    });
  }

  updateDisabled($input) {
    $input.closest(SELECTOR_BUTTON).toggleClass('disabled', $input.is(':disabled'));

    let disabled = true;
    this.$inputs.each(function() {
      disabled &&= $(this).is(':disabled');
    });

    this.$element.toggleClass('disabled', disabled);
  }
}

function init($root) {
  $root.find(SELECTOR_GROUP).each(function() {
    const $element = $(this);
    const radioButtonGroup = new RadioButtonGroup($element);

    $element.data('rp-radio-button-group', radioButtonGroup);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });
});
