import $ from 'jquery';
import Alerts from '../../components/alerts';
import Validation from '../../components/validation';

export default class DuplicateCustomerCheck {
  constructor($form) {
    this.$form = $form;
    this.$fields = this.$form.find('.rp-duplicate-check');
    this.$ignoreCheck = this.$form.find('.rp-ignore-duplicate-customers');
    this.$ignoreCheck.hidden(true);

    this.bindEvents();
  }

  bindEvents() {
    this.$ignoreCheck.on('change', event => {
      if (event.target.checked) {
        this.clearFieldErrors();
        Alerts.closeAll();
      }
    });

    this.$form.on('rp-submit:invalid', (event, data) => {
      const additionalData = data?.response?.body?.additional_data;
      if (additionalData) {
        if (additionalData.ignore === true) {
          this.$ignoreCheck.hidden(false);
        }
      }
    });

    this.$form.on('hide.bs.modal', () => {
      Alerts.closeAll();
    });

    this.$fields.on('change', () => {
      // changing one of the checked fields might result in unique customer
      // clearing errors to allow to resubmit
      this.clearFieldErrors();
    });
  }

  clearFieldErrors() {
    this.$fields.each(function () {
      Validation.setError(this, null);
      Validation.validate(this);
    });
  }
}
