import $ from 'jquery';

class InputExpand {
  constructor($button) {
    this.$button = $button;
    this.$input = $button.closest('.input-group').find('input[type=text]');
  }

  handle() {
    if (this.$input.exists()) {
      this.convertInput();
      this.removeButton();
    }
  }

  convertInput() {
    this.$textarea = $('<textarea>');
    this.$textarea.text(this.$input.val());
    this.$textarea.attr('rows', 3);

    for (const attr of this.$input.get(0).attributes) {
      if (attr.name === 'type' || attr.name === 'value') {
        continue;
      }
      this.$textarea.attr(attr.name, attr.value);
    }

    this.$input.replaceWith(this.$textarea);
  }

  removeButton() {
    this.$button.remove();
  }
}

$(() => {
  $(document).on('click', 'button[data-input-expand]:not(:disabled)', event => {
    event.preventDefault();

    new InputExpand($(event.currentTarget)).handle();
  });
});
