import Log from 'core/log';
import { formatAttrSelector, redirect } from 'utils/functions';
import Modal from './modal';
import Offcanvas from './offcanvas';
import ResponseCommand from './responseCommand';

export const ATTR_INTERACTIVE_ELEMENT = 'data-interactive-element';
export const ATTR_INTERACTIVE_ELEMENT_URL = 'data-interactive-element-url';

export const EVENT_ACTIVATE = 'rp-interactive-element:activate';
export const EVENT_DEACTIVATE = 'rp-interactive-element:deactivate';

const SELECTOR_IGNORE = 'a,.btn,.rp-icon-btn,:input,.custom-control,.select2';

const TYPE_REDIRECT = 'redirect';
const TYPE_MODAL = 'modal';
const TYPE_OFFCANVAS = 'offcanvas';

export default class InteractiveElement {
  constructor($container) {
    this.$container = $container;

    this.bindEvents();
  }

  bindEvents() {
    const interactiveElementSelector = formatAttrSelector(ATTR_INTERACTIVE_ELEMENT);
    if (this.$container.is(interactiveElementSelector)) {
      this.$container.on('click', event => this.handleClick(event));
    } else {
      this.$container.on('click', interactiveElementSelector, event => this.handleClick(event));
    }
  }

  handleClick(event) {
    // ignore links, buttons, inputs etc
    if ($(event.target).closest(SELECTOR_IGNORE).exists()) {
      return;
    }

    const $element = $(event.currentTarget);
    const type = $element.attr(ATTR_INTERACTIVE_ELEMENT);
    const url = $element.attr(ATTR_INTERACTIVE_ELEMENT_URL);

    if (this.triggerActivate($element)) {
      // activation was prevented
      return;
    }

    switch (type) {
      case TYPE_REDIRECT:
        redirect(url);
        break;
      case TYPE_MODAL:
        this.handleModal($element, url);
        break;
      case TYPE_OFFCANVAS:
        this.handleOffcanvas($element, url);
        break;
      default:
        Log.error('Unknown interactive item type: ' + type);
        break;
    }
  }

  triggerActivate($element) {
    const event = $.Event(EVENT_ACTIVATE);

    this.$container.trigger(event, { $element });

    return event.isDefaultPrevented();
  }

  triggerDeactivate($element) {
    this.$container.trigger(EVENT_DEACTIVATE, { $element });
  }

  handleModal($element, url) {
    new Modal(url).open()
      .then(response => new ResponseCommand(response, $element).handle())
      .catch(error => Log.error(error))
      .finally(() => this.triggerDeactivate($element));
  }

  handleOffcanvas($element, url) {
    new Offcanvas(url).open()
      .catch(error => Log.error(error))
      .finally(() => this.triggerDeactivate($element));
  }
}
