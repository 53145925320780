import $ from 'jquery';
import Numbers from '../../core/numbers';
import { formatAttrSelector } from '../../utils/functions';

const ADDITIONAL_CONTACT_BUTTON_ATTRIBUTE_NAME = 'data-additional-contact-button';
const ADDITIONAL_CONTACT_CONTENT_ATTRIBUTE_NAME = 'data-additional-contact-content';

class AdditionalContact {
  constructor($form, $button, group) {
    this.$form = $form;
    this.$button = $button;
    this.group = group;
    this.$count = $('#' + group + '_count');
    this.$content = $form.find(formatAttrSelector(ADDITIONAL_CONTACT_CONTENT_ATTRIBUTE_NAME, group));

    this.bindEvents();
  }

  bindEvents() {
    if (this.$content.exists()) {
      this.$button.on('click', () => this.addAdditionalContactFields());
    }
  }

  addAdditionalContactFields() {
    const $newContent = this.$content.clone();
    const count = Numbers.parse(this.$count.val());
    $newContent.find(':input').each(function() {
      $(this).attr('name', $(this).attr('name') + count);
      $(this).disabled(false);
    });
    this.$count.val(count + 1);

    $newContent.insertBefore(this.$content);
    $newContent.removeAttr('hidden');
  }
}

function init($root) {
  $root.find('[' + ADDITIONAL_CONTACT_BUTTON_ATTRIBUTE_NAME + ']').each(function() {
    const group = $(this).attr(ADDITIONAL_CONTACT_BUTTON_ATTRIBUTE_NAME);
    if (group) {
      new AdditionalContact($root, $(this), group);
    }
  });
}

$(() => {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});