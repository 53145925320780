import $ from 'jquery';

class DealPaymentIncomingModal {
  constructor($modal) {
    this.$prescribed = $modal.find('#Prescribed');
    this.$vatRate = $modal.find('#VATRate');
    this.$amount = $modal.find('#Amount');

    this.rfId = this.$prescribed.attr('data-rf-id');
    this.defaultVatRate = this.$vatRate.attr('data-vat-rate-default');
    this.rfVatRate = this.$vatRate.attr('data-vat-rate-rf');

    this.bindEvents();
  }

  bindEvents() {
    this.$prescribed.on('change', () => {
      if (this.rfId) {
        const isReservationFee = this.$prescribed.val() === this.rfId;

        if (isReservationFee) {
          this.$vatRate.val(this.rfVatRate);
        } else {
          this.$vatRate.val(this.defaultVatRate);
        }

        this.$vatRate.trigger('change');
      }

      const prescribedAmount = this.$prescribed.find('option:selected').attr('data-amount');

      // we set Amount field only if it's empty
      if (prescribedAmount && !this.$amount.val()) {
        this.$amount.val(prescribedAmount).trigger('change').highlight();
      }
    });
  }
}

$(()=> {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#deal-payment-incoming-modal')) {
      new DealPaymentIncomingModal(data.$root);
    }
  });
});
